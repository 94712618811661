import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";

import { FONT_STYLE_SELECT, orderTypeOptions, storeOptions } from "pages/Common/constants";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";

const ViewOrderInformation = ({ show, hideModal, handleSnack, orderRecord, setOrderRecord }) => {
  const [viewData, setViewData] = useState(true);
  const [orderInfoObj, setOrderInfoObj] = useState({
    accountName: orderRecord?.accountName || "",
    dealerStore: orderRecord?.dealerStore || "",
    orderType: orderRecord?.orderType || "",
    orderedBy: orderRecord?.orderedBy || "",
    emailAddress: orderRecord?.emailAddress || "",
    phoneNumber: orderRecord?.phoneNumber || "",
  });

  // input text change
  const hadleInputTextChange = (e) => {
    const { name, value } = e.target;
    setOrderInfoObj({ ...orderInfoObj, [name]: value });
  };

  // order information select option change
  const hadleSelectValueChange = (e, keyName) => {
    setOrderInfoObj({ ...orderInfoObj, [keyName]: e });
  };

  // save the address details
  const handleSaveChanges = () => {
    setOrderRecord({ ...orderRecord, ...orderInfoObj });
    hideModal();
  };

  // cancel the change address details
  const handleCancelChanges = () => {
    hideModal();
  };

  return (
    <Modal show={show} size="lg" centered>
      <Modal.Body>
        <h5>Order Information</h5>
        <div className="card border px-3 mb-0">
          {viewData ? (
            <div className="row mt-3">
              <ReadOnlyField label="ACCOUNT" value={orderInfoObj?.accountName} className="col-md-4 col-sm-4" />
              <ReadOnlyField label="STORE" value={orderInfoObj?.dealerStore?.label || "NA"} className="col-md-4 col-sm-4" />
              <ReadOnlyField label="ORDER TYPE" value={orderInfoObj?.orderType?.label || "NA"} className="col-md-4 col-sm-4" />
              <ReadOnlyField label="ORDER BY" value={orderInfoObj?.orderedBy} className="col-md-4 col-sm-4" />
              <ReadOnlyField label="EMAIL" value={orderInfoObj?.emailAddress} className="col-md-4 col-sm-4" />
              <ReadOnlyField label="CONTACT" value={orderInfoObj?.phoneNumber} className="col-md-4 col-sm-4" />
            </div>
          ) : (
            <div className="row input-fields mt-3">
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">ACCOUNT</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    value={orderInfoObj.accountName}
                    name="accountName"
                    placeholder="Account"
                    onChange={hadleInputTextChange}
                  />
                </div>
                {/*  input text change */}
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">STORE</label>
                  <Select
                    onChange={(e) => hadleSelectValueChange(e, "dealerStore")}
                    options={storeOptions}
                    placeholder="Select..."
                    styles={FONT_STYLE_SELECT}
                  />
                </div>
                {/*  input text change */}
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">ORDER TYPE</label>
                  <Select
                    onChange={(e) => hadleSelectValueChange(e, "orderType")}
                    options={orderTypeOptions}
                    placeholder="Select..."
                    styles={FONT_STYLE_SELECT}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">ORDER BY</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    value={orderInfoObj.orderedBy}
                    name="orderedBy"
                    placeholder="Order By"
                    onChange={hadleInputTextChange}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">EMAIL</label>
                  <input
                    type="email"
                    className="form-control border-radius-10 text-primary"
                    value={orderInfoObj.emailAddress}
                    name="emailAddress"
                    placeholder="Email Address"
                    onChange={hadleInputTextChange}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CONTACT</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    value={orderInfoObj.phoneNumber}
                    name="phoneNumber"
                    placeholder="Contact Number"
                    onChange={hadleInputTextChange}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="row mt-4">
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <button className="btn border-primary text-primary w-100" onClick={handleCancelChanges}>
              Cancel
            </button>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <button className="btn bg-primary text-white w-100" onClick={handleSaveChanges}>
              Save
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewOrderInformation;
