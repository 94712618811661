import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";

import DeleteIcon from "@mui/icons-material/Delete";
import PaymentIcon from "@mui/icons-material/Payment";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DescriptionIcon from "@mui/icons-material/Description";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, LinearProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { PART_MASTER_URL_COMMERCE_SVC, PARTS_GET_PARTS_CART_GET } from "services/CONSTANTS";
import { callGetApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import LoadingProgress from "pages/Repair/components/Loader";
import Moment from "react-moment";
import ConstructionOrderInfoCard from "./ConstructionOrderInfoCard";
import ConstructionOrderShippingDtlsCard from "./ConstructionOrderShippingDtlsCard";
import ConstructionOrderBillingDtlsCard from "./ConstructionOrderBillingDtlsCard";
import {
  billingTypesOptions,
  deliveryPriorityOptions,
  GRID_STYLE,
  paymentTermsOptions,
  shippingCondition,
  storeOptions,
} from "pages/Common/constants";
import ViewOrderShippingDetails from "./ViewOrderShippingDetails";
import ViewOrderInformation from "./ViewOrderInformation";
import ViewOrderBillingDetails from "./ViewOrderBillingDetails";
import { getQuoteCommonConfig } from "services";

const ProgressBar = ({ progress, styles }) => {
  return (
    <LinearProgress
      variant="determinate"
      value={progress}
      style={{
        height: 10,
        borderRadius: 5,
        backgroundColor: "#e0e0e0", // Background color,
      }}
      sx={{
        "& .MuiLinearProgress-bar": {
          borderRadius: 5,
          backgroundColor: "#872ff7", // Purple color
          ...styles,
        },
      }}
    />
  );
};

const ConstructionOrderProgressModal = ({
  show,
  hideModal,
  handleSnack,
  orderId = null,
  cartId = null,
  rowData = null,
  currencyFormatter,
  openShippingDtlsModal,
  setOpenShippingDtlsModal,
  openBillingDtlsModal,
  setOpenBillingDtlsModal,
  openOrderInfoModal,
  setOpenOrderInfoModal,
  setShowHideOrderModal,
}) => {
  const [orderedParts, setOrderedParts] = useState(null);
  const [loading, setLoading] = useState(false);

  const [orderProgressExpanded, setOrderProgressExpanded] = useState("");

  const [orderRecord, setOrderRecord] = useState({
    accountName: "",
    dealerStore: "",
    orderType: "",
    orderedBy: "",
    emailAddress: "",
    phoneNumber: "",
    paymentTerms: "",
    currency: "",
    billingType: "",
    billingFrequency: "",
    billingAddress: "",
    deliveryType: "",
    deliveryPriority: "",
    leadTime: "",
    shippingCondition: "",
    shippingPoint: "",
    pickUpInst: "",
    shippingAddress: "",
    partialShipment: false,
    machineBreakdown: false,
    needInstallationHelp: false,
    serialNumber: "",
    model: "",
  });

  const [selectAddressType, setSelectAddressType] = useState("");
  const [billingFrequencyOptions, setBillingFrequencyOptions] = useState([]);

  const [quoteCurrencyOptions, setQuoteCurrencyOptions] = useState([]);
  const [billingTypeOptions, setBillingTypeOptions] = useState([...billingTypesOptions]);

  const [labourCharge, setLabourCharge] = useState(0);

  // useEffect(() => {
  //   if (cartId) {
  //     getOrderedCartParts(cartId);
  //   }
  // }, [cartId]);

  useEffect(() => {
    setOrderedParts(rowData);
    if (rowData) {
      // order type
      const _orderType = storeOptions.find((item) => item.value === rowData?.orderType);

      // payment terms
      const _paymentTerms = paymentTermsOptions.find((item) => item.value === rowData?.paymentTerms);

      // billing Type
      const _billingType = billingTypesOptions.find((item) => item.value === rowData?.billingType);

      // billing Type
      const _billingFrequency = billingFrequencyOptions.find((item) => item.value === rowData?.billingFrequency);

      // billing Type
      const _deliveryType = [
        { label: "Standard", value: "STANDARD" },
        { label: "Express", value: "EXPRESS" },
      ].find((item) => item.value === rowData?.deliveryType);

      // billing Type
      const _deliveryPriority = deliveryPriorityOptions.find((item) => item.value === rowData?.deliveryPriority);

      // billing Type
      const _shippingCondition = shippingCondition.find((item) => item.value === rowData?.shippingCondition);

      setOrderRecord({
        accountName: rowData?.customerName || "",
        dealerStore: "",
        orderType: _orderType,
        orderedBy: rowData?.orderedBy || "",
        emailAddress: rowData?.emailAddress || "",
        phoneNumber: rowData?.phoneNumber || "",
        paymentTerms: _paymentTerms || "",
        currency: rowData?.currency ? { label: rowData?.currency, value: rowData?.currency } : { label: "INR", value: "INR" },
        billingType: _billingType || "",
        billingFrequency: _billingFrequency || { label: "One time", value: "ONE_TIME" },
        billingAddress: rowData?.billingAddress || "",
        deliveryType: _deliveryType || { label: "Standard", value: "STANDARD" },
        deliveryPriority: _deliveryPriority || "",
        leadTime: rowData?.leadTime || "",
        shippingCondition: _shippingCondition || "",
        shippingPoint: "",
        pickUpInst: rowData?.pickUpInst || "",
        shippingAddress: rowData?.shippingAddress || "",
        partialShipment: rowData?.partialShipment || false,
        machineBreakdown: rowData?.machineBreakdown || false,
        needInstallationHelp: rowData?.needInstallationHelp || false,
        serialNumber: rowData?.serialNumber || "",
        model: rowData?.model || "",
      });
    }
    if (rowData && rowData?.partsQty && Object.keys(rowData?.partsQty).length > 0) {
      // getOrderedParts(rowData?.partsQty);
    }
  }, [rowData]);

  useEffect(() => {
    // quote-Currency
    getQuoteCommonConfig("currency")
      .then((res) => {
        const options = [];
        res.length !== 0 &&
          res.map((d) => {
            if (d !== "EMPTY") {
              options.push({ value: d, label: d });
            }
          });
        // const options = res.map((d) => ({
        //   value: d,
        //   label: d,
        // }));
        setQuoteCurrencyOptions(options);
      })
      .catch((err) => {
        alert(err);
      });

    // Billing-Frequency
    getQuoteCommonConfig("billing-frequency")
      .then((res) => {
        const options = [];
        res.length !== 0 &&
          res.map((d) => {
            if (d.key !== "EMPTY") {
              options.push({ value: d.key, label: d.value });
            }
          });
        setBillingFrequencyOptions(options);
      })
      .catch((err) => {
        alert(err);
      });
  }, []);

  // get ordered parts
  const getOrderedParts = (partsQty = null) => {
    if (partsQty && Object.keys(partsQty).length > 0) {
      setLoading(true);
      const rUrl =
        `${PART_MASTER_URL_COMMERCE_SVC}by-ids?` +
        Object.keys(partsQty)
          .map((key) => `ids=${key}`)
          .join("&");
      callGetApi(
        rUrl,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            const partsData = responseData.map((data) => {
              return { ...data, quantity: rowData["partsQty"][data?.partsId] };
            });
            setOrderedParts({ parts: [...partsData] });
            setLoading(false);
          } else {
            setOrderedParts(null);
            setLoading(false);
          }
        },
        (error) => {
          setOrderedParts(null);
          setLoading(false);
        }
      );
    }
  };

  // get ordered cart parts
  const getOrderedCartParts = (cartId) => {
    setLoading(true);
    const rUrl = `${PARTS_GET_PARTS_CART_GET}?parts_cart_id=${cartId}`;
    callGetApi(
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setOrderedParts(responseData);
          setLoading(false);
        } else {
          setOrderedParts(null);
          setLoading(false);
        }
      },
      (error) => {
        setOrderedParts(null);
        setLoading(false);
      }
    );
  };

  // show order info card
  const handleShowHideOrderInfoModal = () => {
    setOpenOrderInfoModal(!openOrderInfoModal);
    // setShowHideOrderModal((pre) => !pre);
  };

  // show Shipping Details card
  const handleShowHideShippingDetailsModal = () => {
    setOpenShippingDtlsModal(!openShippingDtlsModal);
    // setShowHideOrderModal((pre) => !pre);
  };

  // show Billing Details card
  const handleShowHideBillingDetailsModal = () => {
    setOpenBillingDtlsModal(!openBillingDtlsModal);
    // setShowHideOrderModal((pre) => !pre);
  };

  // order progress expend
  const handleExpendOrderProgress = (panel) => (event, isExpanded) => {
    setOrderProgressExpanded(isExpanded ? panel : false);
  };

  // order progress expend on Track order click
  const handleTrackOrder = (panel) => {
    if (!orderProgressExpanded) {
      setOrderProgressExpanded(panel);
    } else {
      setOrderProgressExpanded("");
    }
  };

  const orderColumns1 = [
    {
      field: "partsId",
      headerName: "ID",
      flex: 1,
      renderCell: (params) => params.id,
    },
    {
      field: "partNumber",
      headerName: "Part Number",
      flex: 1,
      renderCell: ({ row }) => row?.partsQty?.partNumber,
    },
    {
      field: "partDescription",
      headerName: "Part Description",
      flex: 1,
      renderCell: ({ row }) => row?.partsQty?.partDescription,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      renderCell: ({ row }) => row?.partsQty?.quantity,
    },
    {
      field: "listPrice",
      headerName: "Price",
      flex: 1,
      renderCell: ({ row }) => currencyFormatter.format(row?.partsQty?.listPrice),
    },
    {
      field: "tax",
      headerName: "Tax",
      flex: 1,
      renderCell: ({ row }) => currencyFormatter.format((row?.partsQty?.listPrice * 10) / 100),
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      flex: 1,
      renderCell: ({ row }) => (
        <div className="font-weight-500 font-size-12">
          {currencyFormatter.format((row?.partsQty?.listPrice + (row?.partsQty?.listPrice * 10) / 100) * row?.partsQty?.quantity)}
        </div>
      ),
    },
  ];

  const orderColumns = [
    {
      field: "partsId",
      headerName: "ID",
      flex: 1,
      // renderCell: (params) => params.id,
    },
    { field: "partNumber", headerName: "Part Number", flex: 1 },
    { field: "partDescription", headerName: "Part Description", flex: 1 },
    { field: "quantity", headerName: "Quantity", flex: 1 },
    {
      field: "listPrice",
      headerName: "Price",
      flex: 1,
      renderCell: ({ row }) => currencyFormatter.format(row.listPrice),
    },
    {
      field: "tax",
      headerName: "Tax",
      flex: 1,
      renderCell: ({ row }) => currencyFormatter.format((row.listPrice * 10) / 100),
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      flex: 1,
      renderCell: ({ row }) => (
        <div className="font-weight-500 font-size-12">{currencyFormatter.format((row.listPrice + (row.listPrice * 10) / 100) * row?.quantity)}</div>
      ),
    },
  ];

  console.log("orderedParts ::: ", orderedParts);

  return (
    <div>
      {show && (
        <Modal show={show} onHide={hideModal} size="xl">
          <Modal.Body style={{ backgroundColor: "#f6f6f6" }}>
            {loading ? (
              <LoadingProgress />
            ) : (
              <>
                <div className="d-flex justify-content-between my-2 mt-2 ">
                  <div>
                    <h3 className="text-light">#{rowData?.partOrderNumber || "NA"}</h3>
                    <h5 className="text-muted">
                      Order History / Order Details / {orderedParts && orderedParts?.cartNumber} -{" "}
                      <Moment format="DD-MM-YYYY hh:mm A">{rowData?.createdAt}</Moment>
                    </h5>
                  </div>
                  <div>
                    <button className="btn btn-danger mr-2">
                      <DeleteIcon /> Delete Order
                    </button>
                    <button className="btn btn-info mr-2 " onClick={() => handleTrackOrder("progressExpended")}>
                      <TrackChangesIcon /> Track Order
                    </button>
                    <button className="btn bg-primary text-white mr-2 ">
                      <DriveFileRenameOutlineIcon /> Edit Order
                    </button>
                    <button></button>
                  </div>
                </div>
                <div className="card border px-3 py-2">
                  <h4 className="mb-1">Progress</h4>
                  <h6 className="text-muted">Current Order Status</h6>
                  <div className="d-flex">
                    <div className="card border p-3 m-2" style={{ width: "20%" }}>
                      <div>
                        <AllInboxIcon />
                      </div>
                      <h5 className="mt-2 mb-3">Order Confirming</h5>
                      <ProgressBar progress={100} />
                    </div>
                    <div className="card border p-3 m-2" style={{ width: "20%" }}>
                      <div>
                        <PaymentIcon />
                      </div>
                      <h5 className="mt-2 mb-3">Payment Pending</h5>
                      <ProgressBar progress={100} />
                    </div>
                    <div className="card border p-3 m-2" style={{ width: "20%" }}>
                      <div>
                        <AccountTreeIcon />
                      </div>
                      <h5 className="mt-2 mb-3">Processing</h5>
                      <ProgressBar progress={50} />
                    </div>
                    <div className="card border p-3 m-2" style={{ width: "20%" }}>
                      <div>
                        <LocalShippingIcon />
                      </div>
                      <h5 className="mt-2 mb-3">Shipping</h5>
                      <ProgressBar progress={0} />
                    </div>
                    <div className="card border p-3 m-2" style={{ width: "20%" }}>
                      <div>
                        <CheckCircleRoundedIcon />
                      </div>
                      <h5 className="mt-2 mb-3">Delivered</h5>
                      <ProgressBar progress={0} />
                    </div>
                  </div>
                </div>

                <Divider sx={{ my: 0 }} />
                <Accordion
                  sx={{ borderRadius: 1, marginY: 3, backgroundColor: "#00000000" }}
                  expanded={orderProgressExpanded === "progressExpended"}
                  onChange={handleExpendOrderProgress("progressExpended")}
                >
                  <AccordionSummary sx={{ border: "none" }} expandIcon={<ExpandMoreIcon sx={{ fontSize: "35px" }} />}>
                    <h4 className="mb-1">View Progress</h4>
                  </AccordionSummary>
                  <AccordionDetails sx={{ border: "none" }}>
                    <Grid container spacing={2} sx={{ minHeight: "200px", maxHeight: "200px" }}>
                      <Grid item xs={6} sx={{ marginBottom: 6 }}>
                        <div className="card border px-3 py-2 mb-0" style={{ backgroundColor: "#F5F5F5", minHeight: "160px", maxHeight: "160px" }}>
                          <div
                            className="d-flex justify-content-center align-items-center bg-white rounded-circle"
                            style={{ height: "40px", width: "40px" }}
                          >
                            <LocalShippingOutlinedIcon sx={{ fontSize: "40px" }} />
                          </div>
                          <h5 className="text-dark">Be patient, package on deliver!</h5>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center text-dark border border-radius-10 bg-white px-2 py-2">
                              <LocalShippingOutlinedIcon sx={{ fontSize: "32px" }} />
                              <span className="ml-1 font-size-13">Malang, Indonesia</span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center gap-1">
                              <span className="d-flex align-items-center">
                                <span className="bg-dark rounded-circle" style={{ width: "5px", height: "5px" }}></span>
                                <span style={{ width: "6px" }}>-</span>
                              </span>
                              <span style={{ width: "6px" }}>-</span>
                              <span style={{ width: "6px" }}>-</span>
                              <span style={{ width: "6px" }}>-</span>
                              <span style={{ width: "6px" }}>-</span>
                              <span style={{ width: "6px" }}>-</span>
                              <span style={{ width: "6px" }}>-</span>
                              <span style={{ width: "6px" }}>-</span>
                              <span style={{ width: "6px" }}>-</span>
                              <span style={{ width: "6px" }}>-</span>
                              <span style={{ width: "6px" }}>-</span>
                              <span style={{ width: "6px" }}>-</span>
                              <span style={{ width: "6px" }}>{">"}</span>
                            </div>
                            <div className="d-flex align-items-center text-dark border border-radius-10 bg-white px-2 py-2">
                              <LocationOnOutlinedIcon sx={{ fontSize: "32px" }} />
                              <span className="ml-1 font-size-13">Emir's House, Indonesia</span>
                            </div>
                          </div>
                          <div className="mt-3">
                            <ProgressBar progress={80} styles={{ backgroundColor: "#F85A10" }} />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={3} sx={{ marginBottom: 0 }}>
                        <di mb-0v className="card border px-3 py-2" style={{ backgroundColor: "#F5F5F5", minHeight: "160px", maxHeight: "160px" }}>
                          <div
                            className="d-flex justify-content-center align-items-center bg-white rounded-circle"
                            style={{ height: "40px", width: "40px" }}
                          >
                            <LocalShippingOutlinedIcon sx={{ fontSize: "40px" }} />
                          </div>
                          <div className="position-absolute" style={{ bottom: 0 }}>
                            <span>Estimated Arrival</span>
                            <h5>
                              <Moment format="DD MMM YYYY">{rowData?.createdAt}</Moment>
                            </h5>
                          </div>
                        </di>
                      </Grid>
                      <Grid item xs={3} sx={{ marginBottom: 0 }}>
                        <div className="card border px-3 py-2 mb-0" style={{ backgroundColor: "#F5F5F5", minHeight: "160px", maxHeight: "160px" }}>
                          <div
                            className="d-flex justify-content-center align-items-center bg-white rounded-circle"
                            style={{ height: "40px", width: "40px" }}
                          >
                            <TimerOutlinedIcon sx={{ fontSize: "40px" }} />
                          </div>
                          <div className="position-absolute" style={{ bottom: 0 }}>
                            <span>Delivered in</span>
                            <h5>5 Days</h5>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <div className="card border px-3 py-3">
                          <span className="font-size-13">Timeline</span>
                          <div className="row mt-2">
                            <div className="col-md-3 col-sm-3">
                              <h5 className="mb-0">
                                <Moment format="DD MMM YYYY">{rowData?.createdAt}</Moment>
                              </h5>
                            </div>
                            <div className="col-md-9 col-sm-9">
                              <h5 className="mb-0">Your package is packed by the courier</h5>
                            </div>
                            <div className="col-md-3 col-sm-3">
                              <h5>
                                <Moment format="hh:mm A">{rowData?.createdAt}</Moment>
                              </h5>
                            </div>
                            <div className="col-md-9 col-sm-9">
                              <span>Malang, East Java, Indonesia</span>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-3 col-sm-3">
                              <span className="mb-0">
                                <Moment format="DD MMM YYYY">{rowData?.createdAt}</Moment>
                              </span>
                            </div>
                            <div className="col-md-9 col-sm-9">
                              <span className="mb-0">Shipment has been created</span>
                            </div>
                            <div className="col-md-3 col-sm-3">
                              <span>
                                <Moment format="hh:mm A">{rowData?.createdAt}</Moment>
                              </span>
                            </div>
                            <div className="col-md-9 col-sm-9">
                              <span>Malang, Indonesia</span>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-3 col-sm-3">
                              <span className="mb-0">
                                <Moment format="DD MMM YYYY">{rowData?.createdAt}</Moment>
                              </span>
                            </div>
                            <div className="col-md-9 col-sm-9">
                              <span className="mb-0">Order Placed</span>
                            </div>
                            <div className="col-md-3 col-sm-3">
                              <span>
                                <Moment format="hh:mm A">{rowData?.createdAt}</Moment>
                              </span>
                            </div>
                            <div className="col-md-9 col-sm-9">
                              <span>Malang, Indonesia</span>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="card border px-3 py-3">
                          <span className="font-size-13">Shipment</span>
                          <div className="d-flex align-items-center mt-2">
                            <div
                              className="d-flex justify-content-center align-items-center bg-white border rounded-circle"
                              style={{ height: "40px", width: "40px" }}
                            >
                              <LocalShippingOutlinedIcon sx={{ fontSize: "40px" }} />
                            </div>
                            <div className="ml-3">
                              <h5 className="mb-0">Doordash Indonesia</h5>
                              <span>Surabaya, Lorkidul, East Java, Indonesia</span>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-6 col-sm-6">
                              <p className="mb-0 font-weight-400">Recipient</p>
                              <span className="text-dark font-weight-500">Emir</span>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <p className="mb-0 font-weight-400">Delivery Address</p>
                              <span className="text-dark font-weight-500">Malang, East Java, Indonesia</span>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-4 col-sm-4">
                              <p className="mb-0 font-weight-400">Tracking No.</p>
                              <span className="d-flex justify-content-between border border-radius-10 px-2 py-1 mt-1">
                                <span className="text-black font-weight-500">98765409</span>
                                <span className="cursor text-dark font-weight-500">
                                  <ContentCopyOutlinedIcon />
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <div className="card border px-3 py-2">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h4 className="mb-0">Customer</h4>
                      <h6 className="text-muted">Information Details</h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-sm-4">
                      <ConstructionOrderInfoCard
                        orderRecord={orderRecord}
                        showIcon={true}
                        className="px-2 py-2"
                        handleShowHideOrderInfoModal={handleShowHideOrderInfoModal}
                      />
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <ConstructionOrderShippingDtlsCard
                        orderRecord={orderRecord}
                        handleShowHideShippingDetailsModal={handleShowHideShippingDetailsModal}
                        showIcon={true}
                        className="px-2 py-2"
                        style={{ height: "17.4rem" }}
                      />
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <ConstructionOrderBillingDtlsCard
                        orderRecord={orderRecord}
                        handleShowHideBillingDetailsModal={handleShowHideBillingDetailsModal}
                        showIcon={true}
                        className="px-2 py-2"
                        style={{ height: "17.4rem" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 col-sm-8">
                    <div className="card border p-2">
                      <div className="d-flex justify-content-between align-items-baseline">
                        <div>
                          <h4 className="mb-1">Product</h4>
                          <h6 className="text-muted">Your Shipment</h6>
                        </div>
                        <div>
                          <DescriptionIcon /> Download CSV
                        </div>
                      </div>
                      <Divider className="mb-2" />
                      <div className="order-summary-items">
                        <DataGrid
                          // rows={(orderedParts && orderedParts["parts"]) || []}
                          rows={(orderedParts && orderedParts["partsQty"]) || []}
                          // rows={orderedParts}

                          columns={orderColumns}
                          disableSelectionOnClick
                          // getRowId={(row) => row.partsOrderId}
                          getRowId={(row) => row.partsId}
                          sx={GRID_STYLE}
                          hideFooter={true}
                          autoHeight
                          pagination={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="card border p-2 mb-3">
                      <div className="d-flex justify-content-between">
                        <div>
                          <h4 className="mb-0">Payment</h4>
                          <h6 className="text-muted">Final Payment Amount</h6>
                        </div>
                        <div>
                          <SimCardDownloadIcon /> Download Invoice
                        </div>
                      </div>
                      <div className="hr my-0"></div>
                      <div className="py-2">
                        <div className="d-flex justify-content-between py-2">
                          <p className="mb-0">Sub Total</p>
                          <h6 className="mb-0">
                            {/* <b>{currencyFormatter.format(orderedParts?.cartPrice || 0)}</b> */}
                            <b>
                              {currencyFormatter.format(
                                (orderedParts &&
                                  orderedParts["partsQty"] &&
                                  orderedParts["partsQty"].length > 0 &&
                                  orderedParts["partsQty"].reduce((sum, partItem) => {
                                    return sum + (partItem.listPrice + (partItem.listPrice * 10) / 100) * partItem?.quantity;
                                  }, 0)) ||
                                  0
                              )}
                            </b>
                          </h6>
                        </div>
                        <div className="hr my-0"></div>
                        <div className="d-flex justify-content-between py-2">
                          <p className="mb-0">Tax (10%)</p>
                          <h6 className="mb-0">
                            {/* <b>{currencyFormatter.format(rowData?.tax)}</b> */}
                            <b>
                              {currencyFormatter.format(
                                (((orderedParts &&
                                  orderedParts["partsQty"] &&
                                  orderedParts["partsQty"].length > 0 &&
                                  orderedParts["partsQty"].reduce((sum, partItem) => {
                                    return sum + (partItem.listPrice + (partItem.listPrice * 10) / 100) * partItem?.quantity;
                                  }, 0)) ||
                                  0) *
                                  10) /
                                  100
                              )}
                            </b>
                          </h6>
                        </div>
                        <div className="hr my-0"></div>
                        <div className="d-flex justify-content-between py-2">
                          <div className="d-flex align-items-center mb-0">
                            <p className="mb-0">Discount</p>
                            <p className="border border-radius-10 px-2 py-1 ml-1 mb-0">2% | REP</p>
                          </div>
                          <h6 className="mb-0">
                            {/* <b>- {currencyFormatter.format(rowData?.discount)}</b> */}
                            <b>
                              -{" "}
                              {currencyFormatter.format(
                                (((orderedParts &&
                                  orderedParts["partsQty"] &&
                                  orderedParts["partsQty"].length > 0 &&
                                  orderedParts["partsQty"].reduce((sum, partItem) => {
                                    return sum + (partItem.listPrice + (partItem.listPrice * 10) / 100) * partItem?.quantity;
                                  }, 0)) ||
                                  0) *
                                  2) /
                                  100
                              )}
                            </b>
                          </h6>
                        </div>
                        <div className="hr my-0"></div>
                        <div className="d-flex justify-content-between py-2">
                          <p className="mb-0">Shipping Cost</p>
                          <h6 className="mb-0">
                            <b>{currencyFormatter.format(500)}</b>
                          </h6>
                        </div>
                        <div className="hr my-0"></div>
                        <div className="d-flex justify-content-between py-2">
                          <h6 className="mb-0">
                            <b>Total</b>
                          </h6>
                          <h6 className="mb-0">
                            {/* <b>{currencyFormatter.format(rowData?.totalPrice)}</b> */}
                            <b>
                              {currencyFormatter.format(
                                ((orderedParts &&
                                  orderedParts["partsQty"] &&
                                  orderedParts["partsQty"].length > 0 &&
                                  orderedParts["partsQty"].reduce((sum, partItem) => {
                                    return sum + (partItem.listPrice + (partItem.listPrice * 10) / 100) * partItem?.quantity;
                                  }, 0)) ||
                                  0) +
                                  (((orderedParts &&
                                    orderedParts["partsQty"] &&
                                    orderedParts["partsQty"].length > 0 &&
                                    orderedParts["partsQty"].reduce((sum, partItem) => {
                                      return sum + (partItem.listPrice + (partItem.listPrice * 10) / 100) * partItem?.quantity;
                                    }, 0)) ||
                                    0) *
                                    10) /
                                    100 -
                                  (((orderedParts &&
                                    orderedParts["partsQty"] &&
                                    orderedParts["partsQty"].length > 0 &&
                                    orderedParts["partsQty"].reduce((sum, partItem) => {
                                      return sum + (partItem.listPrice + (partItem.listPrice * 10) / 100) * partItem?.quantity;
                                    }, 0)) ||
                                    0) *
                                    2) /
                                    100 +
                                  500
                              )}
                            </b>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="row p-2">
                      <button className="btn bg-primary text-white w-100" onClick={hideModal}>
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>
      )}

      {/* order shipping details modal */}
      {openShippingDtlsModal && (
        <ViewOrderShippingDetails
          show={openShippingDtlsModal}
          hideModal={handleShowHideShippingDetailsModal}
          handleSnack={handleSnack}
          orderRecord={orderRecord}
          setOrderRecord={setOrderRecord}
          setLabourCharge={setLabourCharge}
        />
      )}

      {/* order information details modal */}
      {openOrderInfoModal && (
        <ViewOrderInformation
          show={openOrderInfoModal}
          hideModal={handleShowHideOrderInfoModal}
          handleSnack={handleSnack}
          orderRecord={orderRecord}
          setOrderRecord={setOrderRecord}
        />
      )}

      {/* order billing details modal */}
      {openBillingDtlsModal && (
        <ViewOrderBillingDetails
          show={openBillingDtlsModal}
          hideModal={handleShowHideBillingDetailsModal}
          handleSnack={handleSnack}
          orderRecord={orderRecord}
          setOrderRecord={setOrderRecord}
          selectAddressType={selectAddressType}
          setSelectAddressType={setSelectAddressType}
          currencyOptions={quoteCurrencyOptions}
          billingTypeOptions={billingTypeOptions}
          billingFrequencyOptions={billingFrequencyOptions}
        />
      )}
    </div>
  );
};

export default ConstructionOrderProgressModal;
