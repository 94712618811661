import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import { Modal } from "react-bootstrap";
import { callPostApi, callPutApi } from "services/ApiCaller";
import {
  SUPPLIER_CLAIM_MASTER_URL,
  SUPPLIER_ITEM_DETAILS_URL,
} from "services/CONSTANTS";
import { API_SUCCESS } from "services/ResponseCode";

const SearchTableOpenModal = ({
  show,
  hideModal,
  masterData = [],
  supplierRecord,
  handleUpdateSupplierClaim,
  setSupplierItems,
  supplierItems,
}) => {
  const [selectedMasterData, setSelectedMasterData] = useState([]);
  const [relatedRecord, setRelatedRecord] = useState([]);

  // Select parts to add
  const onRowsSelectionHandler = (ids) => {
    setSelectedMasterData([]);
    const selectedRowsData = ids.map((id) =>
      masterData.find((row) => row.id === id)
    );
    // console.log(selectedRowsData);
    setSelectedMasterData(selectedRowsData);
  };

  const handleCreateNewPart = (data) => {
    const rUrl = SUPPLIER_ITEM_DETAILS_URL;

    return new Promise((resolve) => {
      callPostApi(
        null,
        rUrl,
        data,
        (response) => {
          if (response.status === API_SUCCESS) {
            resolve({
              success: true,
              data: { ...response.data },
            });
          } else {
            resolve({ success: false });
          }
        },
        (error) => {
          resolve({ success: false });
        }
      );
    });
  };

  // Add the selected parts from search result to partlist
  const addSelectedPartsToPartList = async () => {
    if (selectedMasterData.length !== 0) {
      const rowsData = [];
      const _supplierItems = [...supplierItems];
      for (const row of selectedMasterData) {
        try {
          let obj = {
            // supplierItemId: 0,
            itemNumber: "",
            materialNumber: row?.partNumber,
            materialDescription: row?.partDescription || "",
            quantity: 1,
            unitPrice: row?.listPrice,
            supplierReturnPartStatus: "EMPTY",
            partCauseDamage: true,
          };

          // Push the object to the rowsData array
          const result = await handleCreateNewPart(obj);
          if (result.success) {
            rowsData.push(result.data);
            _supplierItems.push(result.data);
          }
        } catch (error) {
          console.error("Error adding part:", error);
        }
      }
      setSupplierItems([..._supplierItems]);
      const items = _supplierItems.map((item) => item.supplierItemId);
      handleUpdateSupplierClaim({ supplierItemDTOIds: items });

      hideModal();
    }
  };

  const columnsPartListSearch = [
    { headerName: "Group Number", field: "groupNumber", flex: 1, width: 70 },
    { headerName: "Type", field: "partType", flex: 1, width: 130 },
    { headerName: "Part Number", field: "partNumber", flex: 1, width: 130 },
    {
      headerName: "Description",
      field: "partDescription",
      flex: 1,
      width: 130,
    },
    // { headerName: "Currency", field: "currency", flex: 1, width: 130 },
    { headerName: "Unit Price", field: "listPrice", flex: 1, width: 130 },
    { headerName: "Status", field: "status", flex: 1, width: 130 },
  ];

  return (
    <div>
      <Modal
        show={show}
        onHide={hideModal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="modal-header-border">
          <Modal.Title>Search Results</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 bg-white">
          <div className="card w-100 p-2">
            <div
              className=""
              style={{
                height: 400,
                width: "100%",
                backgroundColor: "#fff",
              }}
            >
              <DataGrid
                sx={{
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#872ff7",
                    color: "#fff",
                  },
                }}
                rows={masterData}
                columns={columnsPartListSearch}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
              />
            </div>
          </div>
          <div className="m-2 text-right">
            <button
              className="btn text-white bg-primary mr-2"
              onClick={hideModal}
            >
              Cancel
            </button>
            <button
              className="btn text-white bg-primary"
              onClick={addSelectedPartsToPartList}
            >
              + Add Selected
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SearchTableOpenModal;
