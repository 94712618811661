import React, { useCallback, useState } from "react";

import CachedIcon from "@mui/icons-material/Cached";
import SearchIcon from "@mui/icons-material/Search";

import $ from "jquery";
import Select from "react-select";

import { inverntoryPartSearchOptions } from "../warehouseConstents";
import { Tooltip } from "@mui/material";
import { isEmpty } from "pages/Common/textUtilities";
import { sparePartSearch } from "services/searchServices";
import { FILL_DATA_PROPERLY_ERROR_MESSAGE, INPUT_SEARCH_API_ERROR_MESSAGE, INPUT_SEARCH_ERROR_MESSAGE } from "pages/Common/constants";
import { SEARCH_SPAREPART } from "services/CONSTANTS";

const searchObj = {
  id: 0,
  fieldName: "",
  operator: "",
  inputSearch: "",
  selectedOption: "",
  dropdownOptions: [],
};

const InventoryPartSearch = ({
  setPartsRecords,
  setPartsTotalRows,
  setPartsLoading,
  setRemoveFilter,
  handleSnack,
  setExpandedPartRowId,
  setPartStockWareHouses,
  setWareHouserPartsLoading,
}) => {
  const [searchParts, setSearchParts] = useState([]);

  // search by fields Options filter
  const searchOptions = useCallback(() => {
    return inverntoryPartSearchOptions.filter((item) => {
      return !searchParts.some((partItem) => item.value === partItem?.fieldName?.value);
    });
  }, [searchParts.length, inverntoryPartSearchOptions]);

  // select Seach By Field Name
  const handleSecltSeachType = (e) => {
    let tempArray = [...searchParts];
    let obj = {
      id: 0,
      fieldName: e,
      operator: "",
      inputSearch: "",
      selectedOption: "",
      dropdownOptions: [],
    };
    tempArray.push(obj);
    setSearchParts(tempArray);
  };

  // Reset the Seach filter
  const handleResetSearchFilter = () => {
    setSearchParts([]);
    setRemoveFilter((pre) => pre + 1);
  };

  // inpiut search for dropdown
  const handlePartsInputSearch = async (e, id) => {
    try {
      const { value } = e.target;
      let _searchParts = [...searchParts];
      let obj = _searchParts[id];
      let dropdownResult = [];
      if (!isEmpty(obj.fieldName.value)) {
        if (e.target.value.length > 0) {
          let searchString = obj.fieldName.value + "~" + e.target.value;
          await sparePartSearch(searchString)
            .then((res) => {
              obj.dropdownOptions = res;
              obj.inputSearch = value;
              obj.selectedOption = "";
              _searchParts[id] = obj;
              setSearchParts([..._searchParts]);
              $(`.scrollbar-${id}`).css("display", "block");
            })
            .catch((err) => {
              handleSnack("error", INPUT_SEARCH_API_ERROR_MESSAGE);
            });
        } else {
          obj.inputSearch = e.target.value;
          obj.selectedOption = "";
          obj.dropdownOptions = [];
          setSearchParts([..._searchParts]);
        }
      } else {
        handleSnack("info", INPUT_SEARCH_ERROR_MESSAGE);
      }

      obj.inputSearch = value;
      obj.selectedOption = "";
    } catch (error) {
      return;
    }
  };

  // select the value from the dropdown list
  const handleSelectDropdownItem = (currentItem, id) => {
    let _searchParts = [...searchParts];
    let obj = _searchParts[id];
    obj.inputSearch = currentItem[obj.fieldName?.value];
    obj.selectedOption = currentItem[obj.fieldName?.value];
    obj.dropdownOptions = [];
    _searchParts[id] = obj;
    setSearchParts([..._searchParts]);
    $(`.scrollbar-${id}`).css("display", "none");
  };

  // search parts on click on search button
  const handleSearchParts = async () => {
    try {
      if (searchParts.length === 0) {
        handleSnack("info", "Please choose the Search by Filed first.");
        return;
      }
      let searchUrl = "";
      for (let i = 0; i < searchParts.length; i++) {
        if (isEmpty(searchParts[i].fieldName?.value) || isEmpty(searchParts[i]?.dropdownOptions)) {
          handleSnack("info", FILL_DATA_PROPERLY_ERROR_MESSAGE);
          return;
        } else if (searchParts[i]?.inputSearch.length > 0 && searchParts[i]?.dropdownOptions.length > 0) {
          handleSnack("info", `Please select value from the dropdown list ${searchParts[i]?.fieldName?.value}`);
          return;
        }
        searchUrl = searchUrl + `${searchParts[i]?.fieldName?.value}:${searchParts[i]?.inputSearch} AND `;
      }

      if (searchUrl.endsWith(" AND ")) {
        searchUrl = searchUrl.slice(0, -3).trim();
      }

      setPartsLoading(true);
      await sparePartSearch(searchUrl)
        .then((res) => {
          if (res && res.length > 0) {
            setPartsTotalRows(res.length);
            setPartsRecords(res);
            setPartsLoading(false);
            setExpandedPartRowId(null);
            setPartStockWareHouses([]);
            setWareHouserPartsLoading(false);
          } else {
            setPartsTotalRows(0);
            setPartsRecords([]);
            setPartsLoading(false);
            setExpandedPartRowId(null);
            setPartStockWareHouses([]);
            setWareHouserPartsLoading(false);
          }
        })
        .catch((err) => {
          setPartsTotalRows(0);
          setPartsRecords([]);
          setPartsLoading(false);
          setExpandedPartRowId(null);
          setPartStockWareHouses([]);
          setWareHouserPartsLoading(false);
        });
    } catch (error) {}
  };

  return (
    <div className="row mb-3">
      <div className="col-md-12 col-sm-12">
        <div className="d-flex align-items-center w-100">
          <div className="d-flex align-items-center bg-primary border-radius-10 w-100 p-2">
            <div className="d-flex mr-2" style={{ whiteSpace: "pre" }}>
              <h5 className="mr-2 text-white mb-0">
                <span>Search</span>
              </h5>
            </div>
            <div className="d-flex align-items-center w-100 mr-4">
              {searchParts.map((obj, i) => (
                <div className="row align-items-center m-0">
                  <div
                    className={`customselectPortfolio d-flex align-items-center mr-3 my-2 border-radius-6`}
                    style={{ position: "relative", zIndex: 20 - i }}
                    key={"query" + i}
                  >
                    <div className="text-center p-2">
                      <p className="my-0">{obj?.fieldName?.label}</p>
                    </div>
                    <div className="customselectsearch">
                      <input
                        className="custom-input-sleact pr-1"
                        type="text"
                        placeholder={`Search ${obj?.fieldName?.label}`}
                        id={"inputSearch-" + i}
                        value={obj.inputSearch}
                        autoComplete="off"
                        onChange={(e) => handlePartsInputSearch(e, i)}
                      />
                      {/* {searchParts.length - 1 === i && (
                      <div className="btn bg-primary text-white cursor">
                        <span className="mr-2">
                          <SearchIcon />
                        </span>
                        SEARCH
                      </div>
                    )} */}
                      {
                        <ul className={`list-group customselectsearch-list scrollbar scrollbar-${i} style`} id="style">
                          {obj.inputSearch.length !== 0 && obj.dropdownOptions.length === 0 && <li className="list-group-item">No Result found</li>}
                          {obj.dropdownOptions.map((currentItem, j) => (
                            <li className="list-group-item" key={j} onClick={() => handleSelectDropdownItem(currentItem, i)}>
                              {currentItem[obj.fieldName?.value]}
                            </li>
                          ))}
                        </ul>
                      }
                    </div>
                  </div>
                </div>
              ))}
              {searchParts.length < 4 && (
                <div className="mr-2">
                  <Select
                    placeholder="Search By"
                    options={searchOptions()}
                    onChange={(e) => handleSecltSeachType(e)}
                    value="Search By"
                    // value={obj.fieldName}
                    // // isOptionDisabled={(option) =>
                    // //   handleCheckDisableOptions(option)
                    // // }
                  />
                </div>
              )}
              <div className="btn border text-white cursor" onClick={handleSearchParts}>
                <span className="mr-2">
                  <SearchIcon />
                </span>
                SEARCH
              </div>
              <div onClick={handleResetSearchFilter}>
                <a className="btn-sm text-white border mx-2 cursor" style={{ border: "1px solid #872FF7" }}>
                  <Tooltip title="Reset">
                    <CachedIcon />
                  </Tooltip>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryPartSearch;
