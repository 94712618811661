import React, { useEffect, useState } from "react";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import Moment from "react-moment";

import { callGetApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { STOCK_TRANSFER_ORDER_MASTER_URL } from "services/CONSTANTS";

import AddUpdateSTO from "./AddUpdateSTO";
import { GRID_STYLE } from "pages/Common/constants";
import CustomizedSnackbar from "pages/Common/CustomSnackBar";

const STOMaster = () => {
  const [pageNo, setPageNo] = useState(1);
  const [perPageSize, setPerPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);

  const [stoRecords, setStoRecords] = useState([]);
  const [stoRecordId, setStoRecordId] = useState(null);

  const [openSTOModal, setOpenSTOModal] = useState(false);

  const [applyFilter, setApplyFilter] = useState("all");

  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  useEffect(() => {
    getStoRecords(pageNo, perPageSize);
  }, [pageNo, perPageSize]);

  // get STO records
  const getStoRecords = async (pageNo, perPageSize) => {
    // setPageNo(pageNo);
    // setPerPageSize(perPageSize);
    setLoading(true);
    const rUrl = `${STOCK_TRANSFER_ORDER_MASTER_URL}?pageNumber=${pageNo > 0 ? pageNo - 1 : 0}&pageSize=${perPageSize}`;
    callGetApi(
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          //   console.log("responseData :::: ", responseData)
          setStoRecords(responseData);
          setLoading(false);
        } else {
          setStoRecords([]);
          setLoading(false);
        }
      },
      (error) => {
        setStoRecords([]);
        setLoading(false);
      }
    );
  };

  // view the New STO Details
  const handleEditSTODetails = (row) => {
    setStoRecordId(row.stoId);
    setOpenSTOModal(true);
  };

  // create the New STO Details
  const handleCreateSTO = () => {
    setStoRecordId(null);
    setOpenSTOModal(true);
  };

  const columns = [
    {
      field: "stoNumber",
      headerName: "STO Number",
      flex: 1,
      width: 100,
      // renderCell: ({ row }) => (row?.returnNumber ? "SO" + row["returnNumber"].substring(2) : "-"),
    },
    {
      field: "orderNumber",
      headerName: "Order Number",
      flex: 1,
      width: 100,
    },
    {
      field: "referenceId",
      headerName: "Reference Id",
      flex: 1,
      width: 100,
    },
    {
      field: "senderStorageLocation",
      headerName: "Sender Location",
      flex: 1,
      width: 100,
    },
    {
      field: "receiverAddress",
      headerName: "Receiver Location",
      flex: 1,
      width: 100,
    },
    {
      field: "trackingNumber",
      headerName: "Tracking Number",
      flex: 1,
      width: 100,
    },
    {
      field: "shippedOn",
      headerName: "Shipped On",
      flex: 1,
      width: 100,
      renderCell: ({ row }) => (row?.shippedOn ? <Moment format="DD/MM/YYYY">{row?.shippedOn}</Moment> : "-"),
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor" onClick={() => handleEditSTODetails(row)}>
                <Tooltip title="Edit">
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div>
        <div className="content-body bg-white" style={{ minHeight: "884px" }}>
          <div className="container-fluid mt-3">
            <div className="d-flex justify-content-between align-items-baseline mt-4">
              <div>
                <h4 className="font-weight-600 mb-0">Stock Transfer Order (STO)</h4>
                <div className="d-flex align-items-center mt-3">
                  <p className={`cursor ${applyFilter === "all" ? "active-filter-warehouse" : ""}`} onClick={() => setApplyFilter("all")}>
                    All STOs
                  </p>
                  <p className={`mx-3 cursor  ${applyFilter === "today" ? "active-filter-warehouse" : ""}`} onClick={() => setApplyFilter("today")}>
                    Today's STOs
                  </p>
                  <p className={`cursor  ${applyFilter === "lastWeek" ? "active-filter-warehouse" : ""}`} onClick={() => setApplyFilter("lastWeek")}>
                    Last Week's STOs
                  </p>
                </div>
              </div>
              <div>
                <button className="btn bg-primary text-white" onClick={handleCreateSTO}>
                  Create STO
                </button>
              </div>
            </div>
            <div className="mb-4 mt-2">
              <DataGrid
                loading={loading}
                sx={GRID_STYLE}
                getRowId={(row) => row.stoId}
                // page={pageNo + 1}
                autoHeight
                // pageSize={perPageSize}
                // onPageChange={(newPage) => getStoRecords(newPage, perPageSize)}
                // onPageSizeChange={(newPageSize) => getStoRecords(pageNo, newPageSize)}
                rows={stoRecords}
                columns={columns}
                rowsPerPageOptions={[10, 20, 50]}
                // paginationMode="server"
                // rowCount={totalRows}
              />
            </div>
          </div>
        </div>
      </div>

      {openSTOModal && <AddUpdateSTO show={openSTOModal} hideModal={() => setOpenSTOModal(false)} handleSnack={handleSnack} recordId={stoRecordId} />}
    </>
  );
};

export default STOMaster;
