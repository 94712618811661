import React, { useState } from "react";

import { Modal } from "react-bootstrap";

import SearchBox from "pages/Common/SearchBox";
import { machineSearch } from "services/searchServices";
import { callPostApi, callPutApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { SPARE_PARTS_MASTER_URL_DATA_SVC } from "services/CONSTANTS";
import { partsMasterRequestObj } from "../masterConstents";

const PartAddUpdateModal = ({ show, hideModal, handleSnack, title = "Part" }) => {
  const [recordObj, setRecordObj] = useState(partsMasterRequestObj);
  const [searchCustResults, setSearchCustResults] = useState([]);
  const [noOptionsCust, setNoOptionsCust] = useState(false);
  const [searchModelResults, setSearchModelResults] = useState([]);
  const [noOptionsModel, setNoOptionsModel] = useState(false);
  const [searchSerialResults, setSearchSerialResults] = useState([]);
  const [noOptionsSerial, setNoOptionsSerial] = useState(false);

  const [machineData, setMachineData] = useState({
    make: "",
    model: "",
    serialNo: "",
    smu: "",
    fleetNo: "",
    equipmentNumber: "",
    family: "",
  });

  const [customerData, setCustomerData] = useState({
    customerID: "",
    customerName: "",
    contactEmail: "",
    contactPhone: "",
  });

  // change the input value
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setRecordObj({ ...recordObj, [name]: value });
  };

  // Machine search based on model and serial number
  const handleMachineSearch = async (searchMachinefieldName, searchText) => {
    let searchQueryMachine = "";
    setSearchModelResults([]);
    setSearchSerialResults([]);

    if (searchMachinefieldName === "model") {
      machineData.model = searchText;
      searchQueryMachine = searchText ? searchMachinefieldName + "~" + searchText : "";
    } else if (searchMachinefieldName === "serialNo") {
      machineData.serialNo = searchText;
      searchQueryMachine = searchText
        ? machineData.model
          ? `model:${machineData.model} %26%26 makerSerialNumber~` + searchText
          : "makerSerialNumber~" + searchText
        : "";
    }
    if (searchQueryMachine) {
      await machineSearch(searchQueryMachine)
        .then((result) => {
          if (result) {
            if (searchMachinefieldName === "model") {
              if (result && result.length > 0) {
                setSearchModelResults(result);
                setNoOptionsModel(false);
              } else {
                setNoOptionsModel(true);
              }
            } else if (searchMachinefieldName === "serialNo") {
              if (result && result.length > 0) {
                setSearchSerialResults(result);
                setNoOptionsSerial(false);
              } else {
                setNoOptionsSerial(true);
              }
            }
          }
        })
        .catch((e) => {
          handleSnack("error", "Error occurred while searching the machine!");
        });
    } else {
      searchMachinefieldName === "model" ? setSearchModelResults([]) : setSearchSerialResults([]);
    }
  };

  // Select machine from the search result
  const handleModelSelect = (type, currentItem) => {
    if (type === "model") {
      setMachineData({ ...machineData, model: currentItem.model, manufacturer: currentItem.maker });
      setSearchModelResults([]);
    } else if (type === "makerSerialNumber") {
      setMachineData({
        ...machineData,
        manufacturer: currentItem.maker,
        model: currentItem.model,
        serialNo: currentItem.makerSerialNumber,
        smu: currentItem.sensorId,
        fleetNo: currentItem.stockNumber,
        // warrantyId: currentItem.warrantyId,
        equipmentNumber: currentItem.equipmentNumber,
      });

      //   if (currentItem?.replacedEquipmentNumber) {
      //     getSelectEquReplacementCode(currentItem?.replacedEquipmentNumber);
      //   }

      setCustomerData({ ...customerData, customerID: currentItem.currentClient, customerName: currentItem.customer });
      setSearchSerialResults([]);
    }
  };

  // click on submit button
  const handleSubmit = () => {
    const rUrl = SPARE_PARTS_MASTER_URL_DATA_SVC;
    const rObj = { ...recordObj };

    if (recordObj?.id) {
      callPutApi(null, `${rUrl}/${recordObj?.id}`, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          handleSnack("success", "Part updated successfully!");
          hideModal();
        } else {
          handleSnack("error", "Error occurred while updating the part!");
        }
      });
    } else {
      callPostApi(null, rUrl, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setRecordObj({ ...recordObj, id: responseData?.id });
          handleSnack("success", "Part is created successfully");
          hideModal();
        } else {
          handleSnack("error", "Error occurred while creating the part!");
        }
      });
    }
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        <Modal.Body>
          <h4>Add {title}</h4>
          <div className="card border px-3">
            <div className="row input-fields mt-3">
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">DESCRIPTION</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="partDescription"
                    placeholder="Description"
                    value={recordObj?.partDescription}
                    onChange={handleInputTextChange}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">TYPE</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="partType"
                    placeholder="Type"
                    value={recordObj?.partType}
                    onChange={handleInputTextChange}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">MANUFACTURER</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    id="manufacturer"
                    placeholder="Manufacturer"
                    value={machineData?.manufacturer}
                    disabled
                    // onChange={handleInputTextChange}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">MODEL</label>
                  <SearchBox
                    value={machineData.model}
                    onChange={(e) => handleMachineSearch("model", e.target.value)}
                    type="model"
                    result={searchModelResults}
                    onSelect={handleModelSelect}
                    noOptions={noOptionsModel}
                    placeholder="Model"
                  />
                  <div className="css-w8dmq8">*Mandatory</div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">GROUP NUMBER</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="groupNumber"
                    placeholder="Group Number"
                    value={recordObj?.groupNumber}
                    onChange={handleInputTextChange}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">{title === "part" ? "PARTS" : "MATERIAL"} GROUP</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="partsGroup"
                    placeholder="Parts Group"
                    value={recordObj?.partsGroup}
                    onChange={handleInputTextChange}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">BEC CODE</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    id="becCode"
                    placeholder="BEC Code"
                    value={recordObj?.becCode}
                    onChange={handleInputTextChange}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">BEC CODE DESCRIPTION</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    id="becCodeDescription"
                    placeholder="BEC Code Description"
                    value={recordObj?.becCodeDescription}
                    onChange={handleInputTextChange}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">SERIAL NUMBER (IF ANY)</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="serialNumber"
                    placeholder="Serial Number"
                    value={recordObj?.serialNumber}
                    onChange={handleInputTextChange}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">STATUS</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="status"
                    placeholder="Status"
                    value={recordObj?.status}
                    onChange={handleInputTextChange}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <h5>ERP Details</h5>
          <div className="card border px-3">
            <div className="row input-fields mt-3">
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">MATERIAL GROUP</label>
                  <input type="text" className="form-control border-radius-10 text-primary" id="materialGroup" placeholder="Material Group" />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">MATERIAL NUMBER</label>
                  <input type="text" className="form-control border-radius-10 text-primary" id="materialNumber" placeholder="Material Number" />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">OLD MATERIAL NUMBER</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    id="oldMaterialNumber"
                    placeholder="Old Material Number"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">AVERAGE COST</label>
                  <input type="text" className="form-control border-radius-10 text-primary" id="averageCost" placeholder="Average Cost" />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">AVAILABILITY</label>
                  <input type="text" className="form-control border-radius-10 text-primary" id="availability" placeholder="availability" />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">TOTAL NUMBER AVAILABLE</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    id="totalNumberAvailable"
                    placeholder="Total Number Available"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">STATUS</label>
                  <input type="text" className="form-control border-radius-10 text-primary" id="status" placeholder="Status" />
                </div>
              </div>
            </div>
          </div> */}
          <div className="row ">
            <div className="col-md-6 col-sm-6">
              <button className="btn border-primary text-primary w-100" onClick={hideModal}>
                Cancel
              </button>
            </div>
            <div className="col-md-6 col-sm-6">
              <button className="btn bg-primary text-white w-100" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PartAddUpdateModal;
