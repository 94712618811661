export const addressDTO = {
  id: 0,
  addressId: 0,
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  fullAddress: "",
  district: "",
  regionOrState: "",
  country: "",
  createdBy: "",
  updatedBy: "",
};

export const equipmentMasterRequestObj = {
  id: 0,
  equipmentNumber: "",
  replacedEquipmentNumber: "",
  description: "",
  status: "",
  stockNumber: "",
  currentClient: 0,
  maker: "",
  makerSerialNumber: "",
  technicalIdendificationNumber: "",
  motorBrand: "",
  engineModel: "",
  motorSerialNumber: "",
  typeOfApplication: "",
  unitOfMeasurement: "",
  mainWork: "",
  modelPrefix: "",
  model: "",
  brand: "",
  market: "",
  productLine: "",
  productSegment: "",
  productGroup: "",
  customer: "",
  owner: "",
  geocode: "",
  sensorId: "",
  usageType: "",
  usageDescription: "",
  serviceLetter: "",
  warrantyType: "",
  serviceLetterId: "",
  installationDate: new Date(),
  endOfLife: 0,
  endOfLifeUnit: "",
  plannedUsage: "",
  unit: "",
  operator: "",
  contact: "",
  warrantyAvailability: "",
  yearOfManufacture: "",
  lastOwner: "",
  fleetOwner: "",
  movedInOrOutFlag: false,
  previousLocation: false,
  newLocation: false,
  movedInDate: new Date(),
  addressDTO: { ...addressDTO },
  customerId: "",
  installer: "",
  purchaseDate: new Date(),
  placeOfPurchase: "",
  usedFor: "",
  usageCondition: "",
  warrantyStatus: "",
  warrantyId: 0,
  createdBy: "",
  updatedBy: "",
  masterComponentIds: [],
  equipmentImage: "",
};

export const partsMasterRequestObj = {
  partNumber: "",
  replacedByPartNumber: "",
  replacesPartNumber: "",
  manufacturer: "",
  model: "",
  groupNumber: "",
  partDescription: "",
  partsGroup: "",
  modelGroupDescription: "",
  partGroupDescription: "",
  yearOfManufacture: "",
  salesUnit: "",
  listPrice: 0,
  costPrice: 0,
  currency: "",
  partType: "",
  usageArea: "",
  status: "",
  partComplexity: "",
  partUsage: "",
  demand: "",
  application: "",
  erpMaterialNumber: "",
  legacyMaterial: "",
  alternativePart: "",
  materialGroup: "",
  materialGroupDescription: "",
  becCode: "",
  becCodeDescription: "",
  warehouseInventories: [],
  totalStock: 0,
  availability: 0,
};

// {
//   warehouseId: 0,
//   sparepartId: 0,
//   availableQuantity: 0,
//   qualityInspectionQuantity: 0,
//   blockedQuantity: 0,
//   onOrderStockQuantity: 0,
//   consignmentStockQuantity: 0,
//   totalQuantity: 0,
// },
