import React from "react";

import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { Tooltip } from "@mui/material";

const ConstructionOrderBillingDtlsCard = ({
  orderRecord,
  handleShowHideBillingDetailsModal,
  showIcon = false,
  className = "px-4 py-2",
  style = null,
}) => {
  return (
    <div className="card border " style={{ overflow: "hidden", ...style }}>
      <div className={`d-flex justify-content-between bg-green py-2 ${className} text-white mb-0`}>
        <h6 className="bg-green text-white mb-0">Billing Details</h6>
        {showIcon && (
          // <Tooltip title="View Payment Method">
          <Tooltip title="View Billing Details">
            <ControlPointIcon className="cursor" onClick={handleShowHideBillingDetailsModal} />
          </Tooltip>
        )}
      </div>
      <div className={className}>
        <div className="d-flex justify-content-between py-2">
          <p className="mb-0">Payment Terms</p>
          <h6 className="mb-0">
            <b>{orderRecord?.paymentTerms?.label || "Immediate"}</b>
          </h6>
        </div>
        <div className="hr my-0"></div>
        <div className="d-flex justify-content-between py-2">
          <p className="mb-0">Currency</p>
          <h6 className="mb-0">
            <b>{orderRecord?.currency?.label || "USD"}</b>
          </h6>
        </div>
        <div className="hr my-0"></div>
        <div className="d-flex justify-content-between py-2">
          <p className="mb-0">Billing Type</p>
          <h6 className="mb-0">
            <b>{orderRecord?.billingType?.label || "Pay Fixed Price"}</b>
          </h6>
        </div>
        <div className="hr my-0"></div>
        <div className="d-flex justify-content-between py-2">
          <p className="mb-0">Billing Frequency</p>
          <h6 className="mb-0">
            <b>{orderRecord?.billingFrequency?.label || "One Time"}</b>
          </h6>
        </div>
      </div>
    </div>
  );
};

export default ConstructionOrderBillingDtlsCard;
