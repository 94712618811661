import React, { useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import CachedIcon from "@mui/icons-material/Cached";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

import $ from "jquery";
import SelectFilter from "react-select";
import { Link, useHistory } from "react-router-dom";

import CustomizedSnackbar from "pages/Common/CustomSnackBar";
import { getVendors } from "services/searchServices";
import { getSuppliers } from "services/warrantyServices";
import { callGetApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { SUPPLIER_CLAIM_MASTER_URL } from "services/CONSTANTS";
import { GRID_STYLE } from "pages/Common/constants";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { currencyFormatter } from "pages/Common/utils/currencyFormatter";
import SupplierMasterAddUpdate from "./SupplierMasterAddUpdate";
import { supplierVendorSearchOptions } from "pages/warrantyManagement/warrantyManagementConstants";
import { Tooltip } from "@mui/material";

const SupplierMaster = () => {
  const [supplierRecordsLoading, setSupplierRecordsLoading] = useState(false);
  const [page, setPage] = useState(0); // Current page
  const [pageSize, setPageSize] = useState(10); // Rows per page
  const [supplierRecords, setSupplierRecords] = useState([]);
  const [totalSupplierRecords, setTotalSupplierRecords] = useState(0);

  const [openSupplierCreateModal, setOpenSupplierCreateModal] = useState(false);
  const [recordId, setRecordId] = useState(null);

  const [querySearchSelector, setQuerySearchSelector] = useState([
    {
      id: 0,
      selectCategory: "",
      selectOperator: "",
      inputSearch: "",
      selectOptions: [],
      selectedOption: "",
    },
  ]);

  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  useEffect(() => {
    getSupplierClaims();
  }, []);

  // get the supplier claims records
  const getSupplierClaims = () => {
    setSupplierRecordsLoading(true);
    const rUrl = SUPPLIER_CLAIM_MASTER_URL;
    callGetApi(
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setSupplierRecords(responseData);
          setTotalSupplierRecords(responseData.length);
          setSupplierRecordsLoading(false);
        } else {
          setSupplierRecords([]);
          setTotalSupplierRecords(0);
          setSupplierRecordsLoading(false);
        }
      },
      (error) => {
        setSupplierRecords([]);
        setTotalSupplierRecords(0);
        setSupplierRecordsLoading(false);
      }
    );
  };

  //  handle AND || OR operator
  const handleOperator = (e, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.selectOperator = e;
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
  };

  // select search vendor
  const handleSelectVendorCategory = (e, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.selectCategory = e;
    obj.inputSearch = "";
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
    // setShowSupplierDtls(false);
    // setSearchSupplierDetails(null);
  };

  // evendor quipment input search
  const handleInputSearch = (e, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    let searchString = tempArray[id].selectCategory.value + "~" + e.target.value;
    if (tempArray[id].selectCategory.value && e.target.value) {
      getVendors(searchString)
        .then((res) => {
          obj.selectOptions = res;
          tempArray[id] = obj;
          setQuerySearchSelector([...tempArray]);
          $(`.scrollbar-${id}`).css("display", "block");
        })
        .catch((err) => {
          handleSnack("error", "Error occurred while searching spare parts!");
        });
    } else {
      handleSnack("info", "Please fill search criteria!");
      obj.selectOptions = [];
    }
    obj.inputSearch = e.target.value;
  };

  // vendor input search list click
  const handleSearchListClick = (e, currentItem, obj1, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.inputSearch = currentItem[obj.selectCategory.value];
    obj.selectedOption = currentItem;
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
    $(`.scrollbar-${id}`).css("display", "none");
  };

  // delete vendor search query list
  const handleDeleteQuerySearch = () => {
    setQuerySearchSelector([
      {
        id: 0,
        selectCategory: "",
        selectOperator: "",
        inputSearch: "",
        selectOptions: [],
        selectedOption: "",
      },
    ]);
  };

  // click on search
  const handleQuerySearchClick = async () => {
    $(".scrollbar").css("display", "none");
    var searchStr = "";
    querySearchSelector.map(function (item, i) {
      if (i === 0 && item.selectCategory.value && item.inputSearch) {
        searchStr = item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      } else if (item.selectCategory.value && item.inputSearch && item.selectOperator.value) {
        searchStr = searchStr + " " + item.selectOperator.value + " " + item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      }
      return searchStr;
    });

    try {
      if (searchStr) {
        await getVendors(`${searchStr}`)
          .then((res) => {
            // setPage(0);
            // setTotalSupplierRecords(res.length);
            // setSupplierRecords(res);
          })
          .catch((err) => {
            // setPage(0);
            // setTotalSupplierRecords(0);
            // setSupplierRecords([]);
          });
      } else {
        handleSnack("info", "Please fill the search criteria!");
      }
    } catch (err) {
      handleSnack("error", "Error occurred while fetching spare parts!");
    }
  };

  // open Supplier Create Modal
  const handleOpenSupplierCreateModal = (row) => {
    setOpenSupplierCreateModal(true);
    setRecordId(row?.supplierClaimId);
  };

  const columns = [
    {
      field: "supplierId",
      headerName: "Supplier Id",
      flex: 1,
    },
    {
      field: "supplierName",
      headerName: "Supplier Name",
      flex: 1,
    },
    {
      field: "model",
      headerName: "Model",
      flex: 1,
    },
    {
      field: "componentCode",
      headerName: "Component Code",
      flex: 1,
    },
    {
      field: "equipmentNumber",
      headerName: "Equipment Number",
      flex: 1,
    },
    {
      field: "supplierDecisionStatus",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "requestedNetAmount",
      headerName: "Request Amount",
      flex: 1,
      renderCell: ({ row }) => currencyFormatter.format(row?.requestedNetAmount || 0),
    },
    {
      field: "approvedNetAmount",
      headerName: "Approved Amount",
      flex: 1,
      renderCell: ({ row }) => currencyFormatter.format(row?.approvedNetAmount || 0),
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor" onClick={() => handleOpenSupplierCreateModal(row)}>
                <Tooltip title="Edit">
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  // reset table and search data
  const handleResetTable = () => {
    setQuerySearchSelector([
      {
        id: 0,
        selectCategory: "",
        selectOperator: "",
        inputSearch: "",
        selectOptions: [],
        selectedOption: "",
      },
    ]);
    getSupplierClaims();
  };

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-baseline mt-3 mb-3">
            <h4 className="font-weight-600 mb-0 ">Supplier Master</h4>
            <button className="btn bg-primary text-white" onClick={() => setOpenSupplierCreateModal(true)}>
              + Create New
            </button>
          </div>
          <div className="bg-primary px-3 mb-3 border-radius-6">
            <div className="row align-items-center">
              <div className="col-12 mx-2">
                <div className="d-flex align-items-center w-100">
                  <div className="d-flex align-items-center bg-primary w-100">
                    <div className="d-flex mr-3 py-3" style={{ whiteSpace: "pre" }}>
                      <h5 className="mr-2 mb-0 text-white">
                        <span>Search</span>
                      </h5>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mr-4">
                      <div className="row align-items-center m-0">
                        {querySearchSelector.map((obj, i) => (
                          <div
                            className={`customselectPortfolio d-flex align-items-center mr-3 my-2 border-radius-6`}
                            style={{ position: "relative", zIndex: 20 - i }}
                            key={"query" + i}
                          >
                            {i > 0 && (
                              <SelectFilter
                                isClearable={true}
                                defaultValue={{ label: "And", value: "AND" }}
                                options={[
                                  { label: "And", value: "AND", id: i },
                                  { label: "OR", value: "OR", id: i },
                                ]}
                                placeholder="And/Or"
                                onChange={(e) => handleOperator(e, i)}
                                value={obj.selectOperator}
                              />
                            )}

                            <div>
                              <SelectFilter
                                // isClearable={true}
                                options={supplierVendorSearchOptions}
                                // options={[
                                //   { label: "Supplier Id", value: "supplierId" },
                                //   { label: "Supplier Name", value: "supplierName" },
                                // ]}
                                onChange={(e) => handleSelectVendorCategory(e, i)}
                                value={obj.selectCategory}
                              />
                            </div>
                            <div className="customselectsearch customize">
                              <input
                                className="custom-input-sleact pr-1"
                                style={{ position: "relative" }}
                                type="text"
                                placeholder="Search string"
                                value={obj.inputSearch}
                                onChange={(e) => handleInputSearch(e, i)}
                                id={"inputSearch-" + i}
                                autoComplete="off"
                              />
                              {querySearchSelector.length - 1 === i && (
                                <div className="btn bg-primary text-white" onClick={() => handleQuerySearchClick()}>
                                  <span className="mr-2">
                                    <SearchIcon />
                                  </span>
                                  SEARCH
                                </div>
                              )}
                              {obj.selectOptions && obj.selectOptions.length > 0 && (
                                <ul className={`list-group customselectsearch-list scrollbar-repair-autocomplete scrollbar-${i} style`} id="style">
                                  {obj.selectOptions.map((currentItem, j) => (
                                    <li className="list-group-item" key={j} onClick={(e) => handleSearchListClick(e, currentItem, obj, i)}>
                                      {`${currentItem?.customerId} - ${currentItem?.fullName}`}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          </div>
                        ))}
                        <div onClick={handleResetTable}>
                          <a className="btn-sm text-white border mr-2 cursor" style={{ border: "1px solid #872FF7" }}>
                            <Tooltip title="Reset">
                              <CachedIcon />
                            </Tooltip>
                          </a>
                        </div>
                        <div>
                          <Link to="#" className="btn-sm text-black border mr-2" style={{ border: "1px solid #872FF7" }}>
                            <span style={{ color: "white" }}>+</span>
                          </Link>
                        </div>

                        <div onClick={handleDeleteQuerySearch}>
                          <Link to="#" className="btn-sm border mr-2">
                            <i className="fa fa-trash fa-lg" style={{ color: "white" }}></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <DataGrid
            loading={supplierRecordsLoading}
            rows={supplierRecords}
            columns={columns}
            sx={GRID_STYLE}
            autoHeight
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
            rowsPerPageOptions={[5, 10, 20, 50]}
            rowCount={totalSupplierRecords}
            page={page}
            pageSize={pageSize}
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            getRowId={(row) => row.supplierClaimId}
          />
        </div>
      </div>

      {openSupplierCreateModal && (
        <SupplierMasterAddUpdate
          show={openSupplierCreateModal}
          hideModal={() => {
            setOpenSupplierCreateModal(false);
            setRecordId(null);
          }}
          recordId={recordId}
          handleSnack={handleSnack}
        />
      )}
    </>
  );
};

export default SupplierMaster;
