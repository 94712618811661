import React, { useState } from "react";

import penIcon from "../../../assets/images/pen.png";
import deleteIcon from "../../../assets/icons/svg/delete.svg";

import { Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import Select from "react-select";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import { FONT_STYLE_SELECT, GRID_STYLE, LABOR_PRICE_OPTIONS } from "../CONSTANTS";
import { AddLaborToService } from "services/repairBuilderServices";
import WithSparePartAddLaborItemModal from "./WithSparePartAddLaborItemModal";

const unitOfMeasureOptions = [
  { label: "Hours", value: "HOURS" },
  { label: "Days", value: "DAYS" },
];

const WithSpareLaborItemAddUpdate = ({
  show,
  hideModal,
  handleSnack,
  operationData,
  laborCodeList = [],
  chargeCodeList = [],
  laborTypeList = [],
  serviceTypeList = [],
}) => {
  const title = operationData?.componentCode + "-" + operationData?.componentCodeDescription;

  const initialLaborItemData = {
    isEditing: false,
    chargeCode: "",
    laborType: "",
    serviceType: "",
    unitOfMeasure: "",
    estimatedHours: 0,
    unitPrice: 0.0,
    extendedPrice: 0.0,
    totalPrice: 0.0,
    //   currency: serviceEstimateData.currency,
    comment: "",
    travelIncluded: true,
    travelCharge: 0.0,
    inspectionIncluded: true,
    inspectionCharge: 0.0,
  };

  const [laborItemOpen, setLaborItemOpen] = useState(false);
  const [laborViewOnly, setLaborViewOnly] = useState(false);
  const [labourData, setLabourData] = useState({
    jobCode: "",
    jobCodeDescription: "",
    laborCode: "",
    pricingMethod: "",
    ratePerHourOrDay: 0,
    totalPrice: 0.0,
    payer: "",
    adjustedPrice: 0.0,
    flatRateIndicator: false,
    totalHours: 0,
  });

  const [labourItemData, setLabourItemData] = useState(initialLaborItemData);

  // update the Labur estimation header
  const updateLabourEstHeader = () => {
    let data = {
      ...(labourData.id && { id: labourData.id }),
      jobCode: labourData.jobCode,
      jobCodeDescription: labourData.jobCodeDescription,
      laborCode: labourData.laborCode?.value,
      totalHours: labourData.totalHours,
      // payer: labourData.payer,
    };
    // AddLaborToService(serviceEstimateData.id, data)
    //   .then((result) => {
    //     setLabourData({
    //       ...result,
    //       id: result.id,
    //       pricingMethod: LABOR_PRICE_OPTIONS.find((element) => element.value === result.pricingMethod),
    //       totalPrice: result.totalPrice ? result.totalPrice : 0,
    //       laborCode: laborCodeList.find((element) => element.value === result.laborCode),
    //     });
    //     handleSnack("success", "Labour details updated!");
    //     setLaborViewOnly(true);
    //   })
    //   .catch((err) => {
    //     handleSnack("error", "Error occurred while updating labor details!");
    //   });
  };

  // add labor item
  const handleAddItemLabor = () => {
    if (labourData.id) setLaborItemOpen(true);
    else handleSnack("warning", "Please update the lobor details!");
  };

  const handleLaborItemClose = () => {
    setLaborItemOpen(false);
    setLabourItemData(initialLaborItemData);
  };

  const laborColumns = [
    { field: "chargeCode", headerName: "Charge Code", flex: 1, width: 70 },
    { field: "laborType", headerName: "Labor Type", flex: 1, width: 130 },
    { field: "serviceType", headerName: "Service Type", flex: 1, width: 130 },
    {
      field: "unitOfMeasure",
      headerName: "Unit of measure",
      flex: 1,
      width: 130,
    },
    {
      field: "estimatedHours",
      headerName: "Estimated hours",
      flex: 1,
      width: 130,
    },
    { field: "unitPrice", headerName: "Unit Price", flex: 1, width: 130 },
    {
      field: "extendedPrice",
      headerName: "Extended Price",
      flex: 1,
      width: 130,
    },
    { field: "comment", headerName: "Comments", flex: 1, width: 130 },
    { field: "currency", headerName: "Currency", flex: 1, width: 130 },
    { field: "totalPrice", headerName: "Total Price", flex: 1, width: 130 },
    {
      field: "Actions",
      headerName: "Actions",
      type: "actions",
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="Edit">
                  <img className="m-1" src={penIcon} alt="Edit" />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            // onClick={() => openLaborRow(params.row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="Remove">
                  <img className="m-1" src={deleteIcon} alt="Delete" />
                </Tooltip>
              </div>
            }
            label="Delete"
            // onClick={() => handleDeleteLaborItem(params.row.id)}
            color="inherit"
          />,
        ];
      },
      flex: 1,
      width: 130,
    },
  ];

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        <Modal.Header className="modal-header-border">
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 bg-white">
          <div className="px-3">
            <div className="row input-fields">
              <div className="col-md-4 col-sm-4">
                <div class="form-group mt-3">
                  <label className="text-light-dark font-size-12 font-weight-600">JOB CODE</label>
                  <input type="text" disabled class="form-control border-radius-10 text-primary" value={labourData.jobCode} />
                  <div className="css-w8dmq8">*Mandatory</div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div class="form-group mt-3">
                  <label className="text-light-dark font-size-12 font-weight-600">JOB CODE DESCRIPTION</label>
                  <input type="text" disabled class="form-control border-radius-10 text-primary" value={labourData.jobCodeDescription} />
                  <div className="css-w8dmq8">*Mandatory</div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4"></div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group  mt-3">
                  <label className="text-light-dark font-size-12 font-weight-500">LABOR CODE</label>
                  <Select
                    onChange={(e) => setLabourData({ ...labourData, laborCode: e })}
                    options={laborCodeList}
                    value={labourData.laborCode}
                    styles={FONT_STYLE_SELECT}
                  />
                  <div className="css-w8dmq8">*Mandatory</div>
                </div>
              </div>

              <div className="col-md-4 col-sm-4">
                <div class="form-group mt-3">
                  <label className="text-light-dark font-size-12 font-weight-600">TOTAL HOURS (PLANNED/RECOMMENDED)</label>
                  <input
                    type="text"
                    class="form-control border-radius-10 text-primary"
                    value={labourData.totalHours}
                    onChange={(e) => setLabourData({ ...labourData, totalHours: e.target.value })}
                  />
                </div>
              </div>
              {/* <div className="col-md-4 col-sm-4"></div> */}

              <div className="col-md-4 col-sm-4">
                <div class="form-group mt-3">
                  <label className="text-light-dark font-size-12 font-weight-600">NET PRICE</label>
                  <input type="text" disabled class="form-control border-radius-10 text-primary" value={labourData.totalPrice} />
                </div>
              </div>
              <div className="col-md-12">
                <div class="form-group mt-3 mb-0 text-right">
                  <button type="button" className="btn border-primary text-primary mr-2" onClick={hideModal}>
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-light bg-primary text-white"
                    onClick={updateLabourEstHeader}
                    disabled={!(labourData.laborCode && labourData.totalHours)}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
            <hr />
            <div className="mb-4">
              <div className="bg-primary px-3 mb-3 border-radius-6">
                <div className="row align-items-center">
                  <div className="col-11 mx-2">
                    <div className="d-flex align-items-center bg-primary w-100">
                      <div className="d-flex mr-3" style={{ whiteSpace: "pre" }}>
                        <h5 className="mr-2 mb-0 text-white">
                          <span>Labor</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="text-center border-left pl-2 py-3">
                      <Link onClick={handleAddItemLabor} to="#" className="p-1 text-white" data-toggle="modal" data-target="#Datatable">
                        <span className="ml-1">Add Items</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <DataGrid
                sx={GRID_STYLE}
                paginationMode="client"
                // rows={laborItems}
                rows={[]}
                columns={laborColumns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                autoHeight
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {laborItemOpen && (
        <WithSparePartAddLaborItemModal
          laborItemOpen={laborItemOpen}
          handleLaborItemClose={handleLaborItemClose}
          setLabourItemData={setLabourItemData}
          labourItemData={labourItemData}
          unitOfMeasureOptions={unitOfMeasureOptions}
          chargeCodeList={chargeCodeList}
          laborTypeList={laborTypeList}
          serviceTypeList={serviceTypeList}
        />
      )}
      {/* {laborItemOpen && (
        <AddLaborItemModal
          laborItemOpen={laborItemOpen}
          handleLaborItemClose={handleLaborItemClose}
          setLabourItemData={setLabourItemData}
          labourItemData={labourItemData}
          serviceEstimateData={serviceEstimateData}
          unitOfMeasureOptions={unitOfMeasureOptions}
          chargeCodeList={chargeCodeList}
          addLaborItem={addLaborItem}
          laborTypeList={laborTypeList}
          serviceTypeList={serviceTypeList}
        />
      )} */}
    </>
  );
};

export default WithSpareLaborItemAddUpdate;
