import React, { useState } from "react";
import { CheckCircle, ArrowForward } from "@mui/icons-material"; // MUI icons
import "./Stepper.css"; // Make sure to create this CSS file

const steps = [
  { label: "Order booked", completed: true },
  { label: "Shoots scheduled", completed: true },
  { label: "Shoots complete", completed: false },
  { label: "Deliverables ready", completed: false },
  { label: "Project delivered", completed: false },
];

function SparePartsStepper() {
  const [activeStep, setActiveStep] = useState(0);

  const handleClick = (index) => {
    setActiveStep(index);
  };

  return (
    <div className="stepper-container w-100">
      {steps.map((step, index) => (
        <div key={index} className={`step ${index <= activeStep ? "active" : ""}`} onClick={() => handleClick(index)}>
          {step.completed || index <= activeStep ? <CheckCircle className="icon" /> : <span className="step-number">{index + 1}</span>}
          <span className="label">{step.label}</span>
          {index < steps.length - 1 && <ArrowForward className="arrow-icon" />}
        </div>
      ))}
    </div>
  );
}

export default SparePartsStepper;
