import React, { useEffect, useState } from "react";

import { Box, Divider, Grid, Typography } from "@mui/material";

import Select from "react-select";
import Loader from "react-js-loader";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import { useHistory } from "react-router-dom";

import { FONT_STYLE_SELECT } from "pages/Common/constants";
import CustomizedSnackbar from "pages/Common/CustomSnackBar";
import { SUPPLIER_CLAIM_MANAGE, SUPPLIER_CLAIM_PROCESS } from "navigation/CONSTANTS";
import { supplierClaimInProcessRecord, supplierClaimProcessColours, supplierClaimProcessFilterOptions } from "../warrantyManagementConstants";
import { SUPPLIER_CLAIM_MASTER_URL } from "services/CONSTANTS";
import { callGetApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import Moment from "react-moment";
import SupplierMasterAddUpdate from "pages/MasterData/SupplierMaster/SupplierMasterAddUpdate";

const RecordsLoader = () => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <Loader type="box-rectangular" bgColor={"#872ff7"} title={"Loading"} color={"#FFFFFF"} size={50} />
    </div>
  );
};

const SupplierClaimDashboard = () => {
  const history = useHistory();

  const [recentClaimsLoader, setRecentClaimsLoader] = useState(false);
  const [recentSuppliers, setRecentSuppliers] = useState([]);

  const [approvedSuppliersLoading, setApprovedSuppliersLoading] = useState(false);
  const [approvedSuppliers, setApprovedSuppliers] = useState([]);

  const [inProcessSuppliersLoading, setInProcessSuppliersLoading] = useState(false);
  const [supplierClaimsInProcess, setSupplierClaimsInProcess] = useState([]);
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");

  const [openSupplierDtlsModal, setOpenSupplierDtlsModal] = useState(false);
  const [recordId, setRecordId] = useState(null);

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  useEffect(() => {
    getSupplierClaims();
    getApprovedSupplierClaims();
    // getInProcessSuppliers();
  }, []);

  // get the supplier claims records
  const getSupplierClaims = () => {
    setRecentClaimsLoader(true);
    const rUrl = `${SUPPLIER_CLAIM_MASTER_URL}/recent`;
    callGetApi(
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setRecentSuppliers(responseData);
          // setTotalSupplierRecords(responseData.length);
          setRecentClaimsLoader(false);
        } else {
          setRecentSuppliers([]);
          // setTotalSupplierRecords(0);
          setRecentClaimsLoader(false);
        }
      },
      (error) => {
        setRecentSuppliers([]);
        // setTotalSupplierRecords(0);
        setRecentClaimsLoader(false);
      }
    );
  };

  // get the Approved or Rejected supplier claim records
  const getApprovedSupplierClaims = () => {
    setApprovedSuppliersLoading(true);
    const rUrl = `${SUPPLIER_CLAIM_MASTER_URL}/filter?status=APPROVED&duration=6 months`;
    callGetApi(
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setApprovedSuppliers(responseData);
          setApprovedSuppliersLoading(false);
        } else {
          setApprovedSuppliers([]);
          setApprovedSuppliersLoading(false);
        }
      },
      (error) => {
        setApprovedSuppliers([]);
        setApprovedSuppliersLoading(false);
      }
    );
  };

  // get the in Process supplier claim records
  const getInProcessSuppliers = () => {
    setInProcessSuppliersLoading(true);
    // const rUrl = `${SUPPLIER_CLAIM_MASTER_URL}/filter?status=APPROVED&duration=6 months`;
    const rUrl = `${SUPPLIER_CLAIM_MASTER_URL}`;
    callGetApi(
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setSupplierClaimsInProcess(responseData);
          setInProcessSuppliersLoading(false);
        } else {
          setSupplierClaimsInProcess([]);
          setInProcessSuppliersLoading(false);
        }
      },
      (error) => {
        setSupplierClaimsInProcess([]);
        setInProcessSuppliersLoading(false);
      }
    );
  };

  // naviagte manage Supplier claims screen
  const handleManageSupplierClaims = () => {
    history.push(`${SUPPLIER_CLAIM_MANAGE}`);
  };

  // naviagte to Supplier claims in process screen
  const handleSupplierClaimProcess = () => {
    history.push(`${SUPPLIER_CLAIM_PROCESS}`);
  };

  // view the Supplier claims details
  const handleViewSupplerClaimDetails = (row) => {
    setOpenSupplierDtlsModal(true);
    setRecordId(row?.supplierClaimId);
  };

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between mt-4 mb-3">
            <h4 className="font-weight-600 mb-4">Supplier Claim</h4>
            <button className="btn bg-primary text-white" onClick={handleManageSupplierClaims}>
              Manage Supplier Claims
            </button>
          </div>
          <Box sx={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div className="card border py-2" style={{ height: "29.8rem" }}>
                  <Typography className="px-3" sx={{ fontWeight: 500 }}>
                    Recentely Created Supplier Claims
                  </Typography>
                  <p className="px-3">Last 7 days</p>
                  <Divider />
                  <div className="font-size-13">
                    <div style={{ background: "#F2F2F2", fontWeight: 500 }}>
                      <div className="row px-3 py-2 cursor">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">Supplier Claim Number</div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-12">Supplier</div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-12">Created At</div>
                      </div>
                    </div>
                    <Divider />
                    {recentClaimsLoader
                      ? RecordsLoader()
                      : recentSuppliers.map((item, i) => {
                          return (
                            i < 10 && (
                              <>
                                <div className="row px-3 py-2 cursor" key={item?.supplierClaimId} onClick={() => handleViewSupplerClaimDetails(item)}>
                                  <div className="col-lg-6 col-md-6 col-sm-12 col-12">Claim Number- {item?.supplierClaimNumber}</div>
                                  <div className="col-lg-3 col-md-3 col-sm-3 col-12">{item?.supplierName || item?.customerId}</div>
                                  <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                                    <Moment format="DD/MM/YYYY">{item?.repairDate || "12/10/2024"}</Moment>
                                  </div>
                                </div>
                                {i < 9 && <Divider />}
                              </>
                            )
                          );
                        })}
                  </div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="card border py-2" style={{ height: "29.8rem" }}>
                  <Typography className="px-3" sx={{ fontWeight: 500 }}>
                    Approved/Rejected Supplier Claims
                  </Typography>
                  <Typography className="px-3 mb-2" variant="h5">
                    {approvedSuppliers.length}
                  </Typography>
                  <Divider />
                  {approvedSuppliers?.length === 0 ? (
                    <div className="d-block justify-content-center align-items-center mt-5 text-center">
                      <h5>No Claims Available....</h5>
                      <p className="font-size-13">There is no Approved/Rejected Supplier claims </p>
                    </div>
                  ) : (
                    <>
                      {approvedSuppliers.map((item, i) => (
                        <div
                          className={`row px-3 py-2 cursor ${
                            item?.supplierClaimStatus === "APPRIVED"
                              ? " workflow-task"
                              : item?.supplierClaimStatus === "APPRIVED"
                              ? " bg-light-pink"
                              : ""
                          }`}
                          key={item?.supplierClaimId}
                          onClick={() => handleViewSupplerClaimDetails(item)}
                        >
                          <div className="col-lg-6 col-md-6 col-sm-12 col-12">Claim Number- {item?.supplierClaimNumber}</div>
                          <div className="col-lg-3 col-md-3 col-sm-3 col-12">{item?.supplierName || item?.customerId}</div>
                          <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                            <Moment format="DD/MM/YYYY">{item?.repairDate || "12/10/2024"}</Moment>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="card border py-2" style={{ height: "29.8rem" }}>
                  <Typography className="px-3" sx={{ fontWeight: 500 }}>
                    Supplier Claims in Process
                  </Typography>
                  <Typography className="px-3 mb-2" variant="h5">
                    2
                  </Typography>
                  <Divider />
                  <div className=" py-2 px-3">
                    <Select options={supplierClaimProcessFilterOptions} styles={FONT_STYLE_SELECT} />
                  </div>
                  <Divider className="mt-0" />
                  <div className="supplier-claim-process cursor" onClick={handleSupplierClaimProcess}>
                    <PieChart width={450} height={280} style={{ cursor: "pointer" }}>
                      <Pie
                        data={supplierClaimInProcessRecord}
                        // cx={250}
                        cy={100}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        paddingAngle={1}
                        dataKey="value"
                      >
                        {supplierClaimInProcessRecord.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={supplierClaimProcessColours[index % supplierClaimProcessColours.length]}
                            // style={{ cursor: "pointer" }}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend layout="vertical" align="center" />
                    </PieChart>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>

      {openSupplierDtlsModal && (
        <SupplierMasterAddUpdate
          show={openSupplierDtlsModal}
          hideModal={() => {
            setOpenSupplierDtlsModal(false);
            setRecordId(null);
          }}
          recordId={recordId}
          handleSnack={handleSnack}
        />
      )}
    </>
  );
};

export default SupplierClaimDashboard;
