import React, { useCallback, useEffect, useState } from "react";
import "./AIAssistence.css";

import MicIcon from "@mui/icons-material/Mic";
import SendIcon from "@mui/icons-material/Send";
import NorthIcon from "@mui/icons-material/North";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import { Box, Tooltip } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";

import Loader from "react-js-loader";
import { useHistory } from "react-router-dom";

import { customerSearch, machineSearch } from "services/searchServices";
import { currencyFormatter } from "pages/Common/utils/currencyFormatter";
import { CLAIM_MASTER, QUOTE_SPARE_PARTS, REPAIR_KITS, WAREHOUSE_INVENTORY } from "navigation/CONSTANTS";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { getRecentClaims, getRecentWarranties, getRecentWarrantyRequests } from "services/warrantyServices";
import { DataGrid } from "@mui/x-data-grid";
import { GRID_STYLE } from "pages/Common/constants";
import Moment from "react-moment";

const defaultMessage = {
  sender: "bot",
  text: `Hello there!,<br />How can I help you today?`,
  prompt: ["Warranty", "Quote", "Inventory", "Kit", "Collection"],
  productList: [],
  showList: false,
  activeStep: 1,
  timestamp: new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
  contentUI: "",
};

const defaultQuestions = [
  { question: "Please begin by entering the customer's number / name for this claim", answer: "", index: 0 },
  { question: "Please select your product's serial number or enter it manually for which you want to create a claim", answer: "", index: 1 },
  { question: "Enter the Model Number", answer: "", index: 2 },
  { question: "Could you please provide the failure code, if applicable?", answer: "", index: 3 },
  { question: "Please specify the defective component, if there is one", answer: "", index: 4 },
  { question: "What are the causal components, if there are any?", answer: "", index: 5 },
  { question: "Please provide the new parts that are required", answer: "", index: 6 },
  { question: "Please specify the quantity needed for the selected part", answer: "", index: 7 },
  { question: "Are you looking to add another part?", answer: "", index: 8 },
  { question: "Please input the number of hours needed to allocate", answer: "", index: 9 },
  { question: "Enter the stipulated hourly rates for the specified hours", answer: "", index: 10 },
  { question: "Please enter any additional hours, if applicable", answer: "", index: 11 },
  { question: "Kindly share the travel distance in kilometers", answer: "", index: 12 },
  { question: "Please input the fixed travel cost per kilometer", answer: "", index: 13 },
  { question: "Please input the vehicle cost, if applicable", answer: "", index: 14 },
];

const quoteDefaultQuestions = [
  { question: "Please begin by entering the customer's number / name for this quote", answer: "", index: 0 },
  // { question: "Please begin by entering a short description for the quote", answer: "", index: 1 },
  // { question: "Please provide the customer's number / name for the quote.", answer: "", index: 1 },
  { question: "Please select your product's equipment number or enter it manually for which you want to create a quote.", answer: "", index: 1 },
  { question: "Please provide the Price Method", answer: "", index: 2 },
  { question: "How many hours of labour will this require?", answer: "", index: 3 },
  { question: "Please input the price of the part", answer: "", index: 4 },
  { question: "Would you like to add any extra charges, such as travel costs or contingencies?", answer: "", index: 5 },
  { question: "Please input the travel cost", answer: "", index: 6 },
  { question: "Please input the contingencies cost?", answer: "", index: 7 },
];

const questionsTypesObj = {
  warranty: false,
  quote: false,
  inventory: false,
  kit: false,
  collection: false,
};

const AIAssistence = ({
  closeModal,
  handleShowGenerativeAIModal,
  handleShowWarrantySummaryModal,
  setAiRecordObj,
  aiPartsRecord = [],
  setAiPartsRecord,
  setWarrantySummaryTitle,
  handleSnack,
}) => {
  const navigate = useHistory();
  const [warrantyQuestions, setWarrantyQuestions] = useState(defaultQuestions);

  const [quoteQuestions, setQuoteQuestions] = useState(quoteDefaultQuestions);

  const [questionType, setQuestionType] = useState({ ...questionsTypesObj });
  const [showReport, setShowReport] = useState(false);

  const [customerData, setCustomerData] = useState({
    customerNumber: "",
    customerName: "",
  });
  const [machineData, setMachineData] = useState({
    make: "",
    family: "",
  });

  const [questionIndex, setQuestionIndex] = useState(0);

  const [messages, setMessages] = useState([defaultMessage]);
  const [userInput, setUserInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [startQuestions, setStartQuestions] = useState(false);

  const [activeStep, setActiveStep] = useState(1);

  // update the chat bot messages
  const updateChatbotMessage = useCallback(
    (updateMessage) => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          try {
            setMessages([...updateMessage]);
            setIsLoading(false);

            // Scroll to the bottom of the page smoothly
            const targetElement = document.getElementsByClassName("chat-window")[0];
            if (targetElement) {
              targetElement.scrollIntoView({ behavior: "smooth" });

              const inputElement = document.getElementsByClassName("userInput")[0];
              if (inputElement) {
                inputElement.focus();
              }
            }

            resolve(); // Resolve the promise after updating messages and scrolling
          } catch (error) {
            reject(error); // Reject the promise if there’s an error
          }
        }, 1500);
      });
    },
    [activeStep]
  );

  // type message
  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  // send message
  const handleSendMessage = async () => {
    if (isLoading) return;
    if (userInput.trim() === "") return;
    setShowReport(false);
    if (startQuestions) {
      if (questionType?.warranty) {
        if (questionIndex === 1) {
          const timestamp = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
          const newMessages = [
            ...messages,
            {
              sender: "user",
              text: userInput,
              timestamp: timestamp,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 1,
              contentUI: "",
            },
          ];
          let obj = {};
          setMessages(newMessages);
          await customerSearch("customerId:" + userInput).then(async (response) => {
            if (response && response.length > 0) {
              setCustomerData({
                customerNumber: response[0]?.customerId,
                customerName: response[0]?.fullName,
              });
              setMachineData({
                make: "",
                family: "",
              });
              await machineSearch(`currentClient:${userInput}&pageNumber=0&pageSize=20&sortColumn=id&orderBY=ASC`).then(async (equipmentResponse) => {
                setUserInput("");
                if (equipmentResponse && equipmentResponse.length > 0) {
                  obj = {
                    sender: "bot",
                    text: "Please select your product's equipment number or enter it manually for which you want to create a claim.",
                    prompt: [],
                    productList: [...equipmentResponse],
                    showList: questionIndex === 1 ? true : false,
                    activeStep: 4,
                    timestamp: timestamp,
                    contentUI: "",
                  };
                  setTimeout(() => {
                    setIsLoading(true);
                    newMessages.push(obj);
                    updateChatbotMessage(newMessages);
                  }, 500);
                } else {
                  obj = {
                    sender: "bot",
                    text: "Enter a Serial Number manually",
                    prompt: [],
                    productList: [],
                    showList: questionIndex === 1 ? true : false,
                    activeStep: 4,
                    timestamp: timestamp,
                    contentUI: "",
                  };
                  setTimeout(() => {
                    setIsLoading(true);
                    newMessages.push(obj);
                    updateChatbotMessage(newMessages);
                  }, 500);
                }
              });
            } else {
              let obj = {
                sender: "bot",
                text: "Sorry, This Customer number is not exits! <br />Please enter another",
                prompt: [],
                productList: [],
                showList: questionIndex === 1 ? true : false,
                activeStep: 4,
                timestamp: timestamp,
                contentUI: "",
              };
              setUserInput("");
              setTimeout(() => {
                setIsLoading(true);
                newMessages.push(obj);
                updateChatbotMessage(newMessages);
              }, 500);
            }
          });
        } else if (questionIndex === 6 || questionIndex === 7 || questionIndex === 8) {
          let obj = {};
          let _aiPartsRecord = [...aiPartsRecord];
          if (questionIndex === 7) {
            _aiPartsRecord.map((item) =>
              item?.id === _aiPartsRecord.length
                ? { ...item, quantity: questionIndex === 7 ? Number(userInput) : Number(warrantyQuestions[7]?.answer) }
                : item
            );
          } else {
            if (userInput.toLowerCase() === "st60dcb") {
              _aiPartsRecord.push({
                id: aiPartsRecord.length + 1,
                partNumber: questionIndex === 6 ? userInput : warrantyQuestions[6]?.answer,
                description: "Star 60 DC - DC Board",
                quantity: questionIndex === 7 ? Number(userInput) : Number(warrantyQuestions[7]?.answer),
                price: 7500,
                relatedPart: true,
                isLabour: false,
                isMachine: false,
              });
            } else if (userInput.toLowerCase() === "st60mb") {
              _aiPartsRecord.push({
                id: aiPartsRecord.length + 1,
                partNumber: questionIndex === 6 ? userInput : warrantyQuestions[6]?.answer,
                description: "Star 60 Main Board",
                quantity: questionIndex === 7 ? Number(userInput) : Number(warrantyQuestions[7]?.answer),
                price: 26360,
                relatedPart: true,
                isLabour: false,
                isMachine: false,
              });
            } else {
              _aiPartsRecord.push({
                id: aiPartsRecord.length + 1,
                partNumber: questionIndex === 6 ? userInput : warrantyQuestions[6]?.answer,
                description: "test description",
                quantity: questionIndex === 7 ? Number(userInput) : Number(warrantyQuestions[7]?.answer),
                price: 980,
                relatedPart: true,
                isLabour: false,
                isMachine: false,
              });
            }
          }
          setQuestionIndex(questionIndex + 1);
          const filteredArray = _aiPartsRecord.filter((obj) => Object.keys(obj).length > 0 && obj.partNumber);
          setAiPartsRecord(filteredArray);
          // setAiPartsRecord((prev) => [...prev, obj]);

          const timestamp = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

          // ! user message Start ! //
          const newMessages = [
            ...messages,
            {
              sender: "user",
              text: userInput,
              timestamp: timestamp,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 1,
              contentUI: "",
            },
          ];
          setMessages(newMessages);

          // ! user message End ! //

          // ! bot message start ! //
          const _questionsRecord = [...warrantyQuestions];
          _questionsRecord[questionIndex].answer = userInput;
          setWarrantyQuestions(_questionsRecord);

          let _question = warrantyQuestions[questionIndex + 1];

          let botMessage = {
            sender: "bot",
            text: _question?.question,
            prompt: questionIndex === 7 ? ["Yes", "No"] : [],
            productList: [],
            showList: false,
            activeStep: 4,
            timestamp: timestamp,
            contentUI: "",
          };
          setUserInput("");
          setTimeout(() => {
            setIsLoading(true);
            newMessages.push(botMessage);
            updateChatbotMessage(newMessages);
          }, 500);

          // ! bot message End ! //

          setQuestionIndex(questionIndex + 1);
        } else {
          const timestamp = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
          const newMessages = [
            ...messages,
            {
              sender: "user",
              text: userInput,
              timestamp: timestamp,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 1,
              contentUI: "",
            },
          ];
          setMessages(newMessages);

          if (questionIndex === warrantyQuestions.length - 1) {
            let obj = {
              sender: "bot",
              text: `Please wait a sec, generating your claim`,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 4,
              timestamp: timestamp,
              contentUI: "",
            };
            setUserInput("");
            setTimeout(() => {
              setIsLoading(true);
              newMessages.push(obj);
              updateChatbotMessage(newMessages);
            }, 500);

            obj = {
              sender: "bot",
              text: `Kindly hold on for a moment as we redirect you to the claim summary. Please check the information and submit the details.`,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 4,
              timestamp: timestamp,
              contentUI: "",
            };

            setTimeout(() => {
              setIsLoading(true);
              newMessages.push(obj);
              updateChatbotMessage(newMessages);
            }, 400);

            const _failureCode = warrantyQuestions.find((item) => item?.index === 3);
            const _partCausingFailure = warrantyQuestions.find((item) => item?.index === 4);

            setAiRecordObj({
              customerNumber: customerData?.customerNumber || "",
              customerName: customerData?.customerName || "",
              serialNumber: warrantyQuestions[1]?.answer,
              model: warrantyQuestions[2]?.answer,
              make: machineData?.make,
              family: machineData?.family,
              failureCode: _failureCode?.answer,
              partCausingFailure: _partCausingFailure?.answer,
            });

            const _aiPartsRecord = [...aiPartsRecord];
            _aiPartsRecord.push(
              {
                id: aiPartsRecord.length + 1,
                partNumber: "Labour (In hours)",
                description: "",
                quantity: Number(warrantyQuestions[9]?.answer) || 5,
                price: 70,
                relatedPart: false,
                isLabour: true,
                isMachine: false,
              },
              {
                id: aiPartsRecord.length + 2,
                partNumber: "Travel (In km)",
                description: "",
                quantity: Number(warrantyQuestions[12]?.answer) || 20,
                price: 50,
                relatedPart: false,
                isLabour: false,
                isMachine: true,
              }
            );
            setAiPartsRecord(_aiPartsRecord);

            setUserInput("");
            setTimeout(() => {
              closeModal();
              handleShowWarrantySummaryModal();
            }, 2500);
          } else {
            const _questionsRecord = [...warrantyQuestions];
            _questionsRecord[questionIndex].answer = userInput;
            setWarrantyQuestions(_questionsRecord);

            let _question = warrantyQuestions[questionIndex + 1];

            let obj = {
              sender: "bot",
              text: _question?.question,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 4,
              timestamp: timestamp,
              contentUI: "",
            };
            setUserInput("");
            setTimeout(() => {
              setIsLoading(true);
              newMessages.push(obj);
              updateChatbotMessage(newMessages);
            }, 500);
          }

          if (questionIndex < warrantyQuestions.length) {
            setQuestionIndex(questionIndex + 1);
          }
        }
      } else if (questionType?.quote) {
        if (questionIndex === 1) {
          const timestamp = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
          const newMessages = [
            ...messages,
            {
              sender: "user",
              text: userInput,
              timestamp: timestamp,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 1,
              contentUI: "",
            },
          ];
          let obj = {};
          setMessages(newMessages);
          await customerSearch("customerId:" + userInput).then(async (response) => {
            if (response && response.length > 0) {
              setCustomerData({
                customerNumber: response[0]?.customerId,
                customerName: response[0]?.fullName,
              });
              setMachineData({
                make: "",
                family: "",
              });
              await machineSearch(`currentClient:${userInput}&pageNumber=0&pageSize=20&sortColumn=id&orderBY=ASC`).then(async (equipmentResponse) => {
                setUserInput("");
                if (equipmentResponse && equipmentResponse.length > 0) {
                  obj = {
                    sender: "bot",
                    text: "Please select your product's serial number or enter it manually for which you want to create a quote.",
                    prompt: [],
                    productList: [...equipmentResponse],
                    showList: questionIndex === 1 ? true : false,
                    activeStep: 4,
                    timestamp: timestamp,
                    contentUI: "",
                  };
                  setTimeout(() => {
                    setIsLoading(true);
                    newMessages.push(obj);
                    updateChatbotMessage(newMessages);
                  }, 500);
                } else {
                  obj = {
                    sender: "bot",
                    text: "Enter a Equipment Number manually",
                    prompt: [],
                    productList: [],
                    showList: questionIndex === 1 ? true : false,
                    activeStep: 4,
                    timestamp: timestamp,
                    contentUI: "",
                  };
                  setTimeout(() => {
                    setIsLoading(true);
                    newMessages.push(obj);
                    updateChatbotMessage(newMessages);
                  }, 500);
                }
              });
            } else {
              let obj = {
                sender: "bot",
                text: "Sorry, This Customer number is not exits! <br />Please enter another",
                prompt: [],
                productList: [],
                showList: questionIndex === 1 ? true : false,
                activeStep: 4,
                timestamp: timestamp,
                contentUI: "",
              };
              setUserInput("");
              setTimeout(() => {
                setIsLoading(true);
                newMessages.push(obj);
                updateChatbotMessage(newMessages);
              }, 500);
            }
          });
        } else if (questionIndex === 4) {
          const timestamp = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

          // ! user message Start ! //
          const newMessages = [
            ...messages,
            {
              sender: "user",
              text: userInput,
              timestamp: timestamp,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 1,
              contentUI: "",
            },
          ];
          setMessages(newMessages);

          // ! user message End ! //

          // ! bot message start ! //
          const _questionsRecord = [...quoteQuestions];
          _questionsRecord[questionIndex].answer = userInput;
          setQuoteQuestions(_questionsRecord);

          let _question = quoteQuestions[questionIndex + 1];

          let botMessage = {
            sender: "bot",
            text: _question?.question,
            prompt: questionIndex === 4 ? ["Yes", "No"] : [],
            productList: [],
            showList: false,
            activeStep: 4,
            timestamp: timestamp,
            contentUI: "",
          };
          setUserInput("");
          setTimeout(() => {
            setIsLoading(true);
            newMessages.push(botMessage);
            updateChatbotMessage(newMessages);
          }, 500);

          // ! bot message End ! //
        } else {
          const timestamp = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
          const newMessages = [
            ...messages,
            {
              sender: "user",
              text: userInput,
              timestamp: timestamp,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 1,
              contentUI: "",
            },
          ];
          setMessages(newMessages);
          if (questionIndex === quoteQuestions.length - 1) {
            const _questionsRecord = [...quoteQuestions];
            _questionsRecord[questionIndex].answer = userInput;
            setQuoteQuestions(_questionsRecord);
            const ui = (
              <div className="card border p-2">
                <ul className="list-group">
                  <li className="list-group-item d-flex w-100 justify-content-between">
                    <h5>Parts:</h5>
                    <h5>
                      <b>{_questionsRecord[1]?.answer}</b>
                    </h5>
                  </li>
                  <li className="list-group-item d-flex w-100 justify-content-between">
                    <h5>Labour:</h5>
                    <h5>
                      <b>
                        {currencyFormatter.format(
                          (Number(!isNaN(_questionsRecord[3]?.answer) ? _questionsRecord[3]?.answer : 0) || 0) *
                            (Number(!isNaN(_questionsRecord[4]?.answer) ? _questionsRecord[4]?.answer : 0) || 0)
                        )}
                      </b>
                    </h5>
                  </li>
                  <li className="list-group-item d-flex w-100 justify-content-between">
                    <h5>Additional Costs:</h5>
                    <h5>
                      <b>
                        {currencyFormatter.format(
                          (Number(!isNaN(_questionsRecord[6]?.answer) ? _questionsRecord[6]?.answer : 0) || 0) +
                            (Number(!isNaN(_questionsRecord[7]?.answer) ? _questionsRecord[7]?.answer : 0) || 0)
                        )}
                      </b>
                    </h5>
                  </li>
                  <li className="list-group-item d-flex w-100 justify-content-between">
                    <h5>Total:</h5>
                    <h5>
                      <b>
                        {currencyFormatter.format(
                          Number(Number(!isNaN(_questionsRecord[3]?.answer) ? _questionsRecord[3]?.answer : 0) || 0) *
                            (Number(Number(!isNaN(_questionsRecord[4]?.answer) ? _questionsRecord[4]?.answer : 0)) || 0) +
                            Number(Number(!isNaN(_questionsRecord[6]?.answer) ? _questionsRecord[6]?.answer : 0) || 0) +
                            Number(Number(!isNaN(_questionsRecord[7]?.answer) ? _questionsRecord[7]?.answer : 0) || 0)
                        )}
                      </b>
                    </h5>
                  </li>
                </ul>
              </div>
            );
            let obj = {
              sender: `bot`,
              text: `Here’s the complete quote based on your inputs<br />`,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 4,
              timestamp: timestamp,
              contentUI: ui,
            };
            setTimeout(() => {
              setIsLoading(true);
              newMessages.push(obj);
              updateChatbotMessage(newMessages);
            }, 500);
            setUserInput("");
          } else {
            const _questionsRecord = [...quoteQuestions];
            _questionsRecord[questionIndex].answer = userInput;
            setQuoteQuestions(_questionsRecord);

            let _question = quoteQuestions[questionIndex + 1];

            let obj = {
              sender: "bot",
              text: _question?.question,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 4,
              timestamp: timestamp,
              contentUI: "",
            };
            setUserInput("");

            setTimeout(() => {
              setIsLoading(true);
              newMessages.push(obj);
              updateChatbotMessage(newMessages);
            }, 500);
          }

          if (questionIndex < quoteQuestions.length) {
            setQuestionIndex(questionIndex + 1);
          }
        }
      }
    } else {
      const timestamp = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
      // const newMessages = [
      //   ...messages,
      //   {
      //     sender: "user",
      //     text: userInput,
      //     prompt: "",
      //     productList: [],
      //     showList: false,
      //     activeStep: lastActiveStep?.activeStep + 1,
      //     timestamp: timestamp,
      //     contentUI: "",
      //   },
      // ];
      // setMessages(newMessages);
      // setUserInput("");
      if (activeStep === 1) {
        // obj = {
        //   sender: "bot",
        //   text: `${_textMessage}`,
        //   prompt: _prompt,
        //   productList: [],
        //   showList: false,
        //   activeStep: 2,
        //   timestamp: timestamp,
        // };
        // setTimeout(() => {
        //   setIsLoading(true);
        //   newMessages.push(obj);
        //   updateChatbotMessage(newMessages);
        // }, 500);
      } else {
      }
    }
  };

  // Image|File upload Modal box show|hide
  const handleImageFileUpload = (e, value) => {
    const newMessages = [
      ...messages,
      {
        sender: "user",
        text: e.name,
        timestamp: new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
        prompt: [],
        productList: [],
        showList: false,
        activeStep: 1,
        contentUI: "",
      },
    ];
    setMessages(newMessages);

    setTimeout(() => {
      handleSnack("success", "Customer & Machine details are validated.");
      closeModal();
      handleShowWarrantySummaryModal();
    }, 4000);
  };

  // select file
  const handleFileSelect = (file) => {
    // You can handle the selected file here
  };

  // warranty questions-answer flow
  const handleWarrantyProcess = () => {};

  // select question prompat
  const handleSelectPrompt = async (e, activeSteper) => {
    const { innerText } = e.target;
    let obj = {};
    const timestamp = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    setStartQuestions(true);

    if (showReport) {
      let textmessage = innerText;
      const newMessages = [
        ...messages,
        { sender: "user", text: innerText, timestamp: timestamp, prompt: [], productList: [], showList: false, activeStep: 1 },
      ];
      setMessages(newMessages);

      let _textMessage = "";
      let _prompt = [];
      let _contentUI = "";
      let _columns = [];
      let _table = "";
      let endUrl = "";

      if (textmessage === "Show latest claims") {
        newMessages.push({
          sender: "bot",
          text: `Kindly hold on for a moment`,
          timestamp: timestamp,
          prompt: [],
          productList: [],
          showList: false,
          activeStep: 1,
        });
        updateChatbotMessage(newMessages);
        setIsLoading(true);
        _columns = [
          {
            field: "claimOrderNumber",
            headerName: "Claim ID",
            flex: 1,
            width: 100,
            renderCell: ({ row }) => `#${row?.claimOrderNumber ? row?.claimOrderNumber : row?.claimId}`,
          },
          {
            field: "claimOrderStatus",
            headerName: "Status",
            flex: 1,
            width: 100,
          },
          {
            field: "claimRequestDate",
            headerName: "Created At",
            flex: 1,
            width: 100,
            renderCell: ({ row }) => (row?.claimRequestDate ? <Moment format="DD/MM/YYYY">{row?.claimRequestDate}</Moment> : "-"),
          },
        ];
        endUrl = `?pageNumber=${0}&pageSize=${10}&sortColumn=createdAt&orderBY=DESC`;
        await getRecentClaims(endUrl).then((result) => {
          if (result) {
            _textMessage = "Your Latest Claims are below here";
            _table = (
              <DataGrid
                sx={GRID_STYLE}
                getRowId={(row) => row.claimOrderId}
                autoHeight
                rows={result}
                columns={_columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20, 50]}
                pagination={false}
                hideFooter={true}
              />
            );
          } else {
            _textMessage = "No Records Found";
            _table = "";
          }
        });
      } else if (textmessage === "Show latest warranties") {
        newMessages.push({
          sender: "bot",
          text: `Kindly hold on for a moment`,
          timestamp: timestamp,
          prompt: [],
          productList: [],
          showList: false,
          activeStep: 1,
        });
        updateChatbotMessage(newMessages);
        setIsLoading(true);
        _columns = [
          {
            field: "warrantyId",
            headerName: "Warranty ID",
            flex: 1,
            width: 100,
            renderCell: ({ row }) => `#${row?.warrantyNumber ? row?.warrantyNumber : row?.warrantyId}`,
          },
          {
            field: "equipmentNumber",
            headerName: "Equipment Number",
            flex: 1,
            width: 100,
          },
          {
            field: "createdAt",
            headerName: "Created At",
            flex: 1,
            width: 100,
            renderCell: ({ row }) => (row?.createdAt ? <Moment format="DD/MM/YYYY">{row?.createdAt}</Moment> : "-"),
          },
        ];
        endUrl = `/recent`;
        await getRecentWarranties(endUrl).then((result) => {
          if (result) {
            _textMessage = "Your Latest Warranties are below here";
            _table = (
              <DataGrid
                sx={GRID_STYLE}
                getRowId={(row) => row.warrantyId}
                autoHeight
                rows={result}
                columns={_columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20, 50]}
                pagination={false}
                hideFooter={true}
              />
            );
          } else {
            _textMessage = "No Records Found";
            _table = "";
          }
        });
      } else if (textmessage === "Show latest warranty requests") {
        newMessages.push({
          sender: "bot",
          text: `Kindly hold on for a moment`,
          timestamp: timestamp,
          prompt: [],
          productList: [],
          showList: false,
          activeStep: 1,
        });
        updateChatbotMessage(newMessages);
        setIsLoading(true);
        _columns = [
          {
            field: "claimId",
            headerName: "Claim ID",
            flex: 1,
            width: 100,
            renderCell: ({ row }) => `#${row?.claimNumber ? row?.claimNumber : row?.claimId}`,
          },
          {
            field: "claimStatus",
            headerName: "Status",
            flex: 1,
            width: 100,
          },
          {
            field: "createdDate",
            headerName: "Created At",
            flex: 1,
            width: 100,
            renderCell: ({ row }) => (row?.createdDate ? <Moment format="DD/MM/YYYY">{row?.createdDate}</Moment> : "-"),
          },
        ];

        endUrl = `?pageNumber=${0}&pageSize=${10}&sortColumn=createdAt&orderBY=DESC`;
        await getRecentWarrantyRequests(endUrl).then((result) => {
          if (result) {
            _textMessage = "Your Latest Warranty Requests are below here";
            _table = (
              <DataGrid
                sx={GRID_STYLE}
                getRowId={(row) => row.claimId}
                autoHeight
                rows={result}
                columns={_columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20, 50]}
                pagination={false}
                hideFooter={true}
              />
            );
          } else {
            _textMessage = "No Records Found";
            _table = "";
          }
        });
      }

      _contentUI = (
        <div className="" style={{ width: "100%" }}>
          {_table}
        </div>
      );
      obj = {
        sender: "bot",
        text: `${_textMessage}`,
        prompt: _prompt,
        productList: [],
        showList: false,
        activeStep: 3,
        timestamp: timestamp,
        contentUI: _contentUI,
      };
      setTimeout(() => {
        setIsLoading(true);
        newMessages.push(obj);
        updateChatbotMessage(newMessages);
      }, 500);
    } else {
      if (activeSteper === 1) {
        let _textMessage = "";
        let _prompt = [];

        let newMessages = [...messages];
        let questionsObj = { ...questionsTypesObj };
        if (innerText.toLowerCase() === "warranty") {
          questionsObj = { ...questionsObj, warranty: true };

          _textMessage = `For the warranty, please select one of the processes listed below.`;
          _prompt = ["Warranty Registration", "Claim Submission", "Settlement Evaluation", "Warranty Extension", "Status Tracking"];
          // _prompt = ["Upload Service Report", "Input Claim Manually", "Guided Claim Creation"];
        } else if (innerText.toLowerCase() === "quote") {
          questionsObj = { ...questionsObj, quote: true };

          // _textMessage = `For the quote, please select one of the processes listed below.`;
          // _prompt = ["Upload Quote", "Input Quote Manually", "Guided Quote Creation"];

          _textMessage = `How would you like to create a repair or maintenance quote?`;
          _prompt = ["Type free-text details", "Upload an Excel file with parts and services", "Follow a guided quote process"];
        } else if (innerText.toLowerCase() === "inventory") {
          questionsObj = { ...questionsObj, inventory: true };
          _textMessage = `For the Inventory, please select one of the processes listed below.`;
          _prompt = ["Create Purchase Requisition", "Inventory Query Assistant", "Receive Goods", "Inventory Transfer Process"];
        } else if (innerText.toLowerCase() === "kit") {
          questionsObj = { ...questionsObj, kit: true };

          _textMessage = `For the kit, please select one of the processes listed below.`;
          _prompt = ["Upload Kit Items", "Input Kit Manually", "Guided Kit Creation"];
        } else if (innerText.toLowerCase() === "collection") {
          questionsObj = { ...questionsObj, collection: true };
          _textMessage = `For the Collection, please select one of the processes listed below.`;
          _prompt = ["Upload Collection Items", "Input Collection Manually", "Guided Collection Creation"];
        }

        newMessages = [
          ...messages,
          {
            sender: "user",
            text: innerText,
            timestamp: timestamp,
            prompt: [],
            productList: [],
            showList: false,
            activeStep: 1,
            contentUI: "",
          },
        ];

        setWarrantySummaryTitle(`${innerText} Summary`);
        setMessages(newMessages);

        obj = {
          sender: "bot",
          text: `${_textMessage}`,
          prompt: _prompt,
          productList: [],
          showList: false,
          activeStep: 2,
          timestamp: timestamp,
        };
        setTimeout(() => {
          setIsLoading(true);
          newMessages.push(obj);
          updateChatbotMessage(newMessages);
        }, 500);
        setQuestionType(questionsObj);
        setActiveStep(2);
      } else if (activeSteper === 2) {
        let textmessage = innerText;
        const newMessages = [
          ...messages,
          { sender: "user", text: innerText, timestamp: timestamp, prompt: [], productList: [], showList: false, activeStep: 1 },
        ];
        setMessages(newMessages);

        let _textMessage = "";
        let _prompt = [];
        let _contentUI = "";

        if (questionType?.warranty) {
          if (textmessage === "Warranty Registration") {
          } else if (textmessage === "Claim Submission") {
            _textMessage = `For the warranty, please select one of the processes listed below.`;
            _prompt = ["Upload Service Report", "Input Claim Manually", "Guided Claim Creation"];
          } else if (textmessage === "Settlement Evaluation") {
          } else if (textmessage === "Warranty Extension") {
          } else if (textmessage === "Status Tracking") {
          } else if (textmessage === "Upload Service Report") {
            _textMessage = `Do you have a service report you’d like to upload to create the claim? Click here to upload`;
            _prompt = [];

            _contentUI = (
              <div className="card py-3 px-1" style={{ width: "100%" }}>
                <div className="justify-content-center">
                  <h6 className="text-center font-weight-500 mt-3">
                    Drag and drop files to upload <br /> or
                  </h6>
                  <Box sx={{ minWidth: "100px", width: "100%" }}>
                    <FileUploader
                      name="file"
                      types={["JPG", "PNG"]}
                      sx={{ minWidth: "200px", maxWidth: "200px", width: "220px" }}
                      style={{ minWidth: "200px" }}
                      handleChange={handleImageFileUpload}
                      onSelect={handleFileSelect}
                    />
                  </Box>
                </div>
              </div>
            );
          } else if (textmessage === "Input Claim Manually") {
            _textMessage = `Kindly hold on for a moment to enter details manually`;
            _prompt = [];
            _contentUI = "";
          } else if (textmessage === "Guided Claim Creation") {
            _textMessage = `Prefer a step-by-step guide to creating your claim? Let me ask you a few questions to get started.`;
            _prompt = [];
            _contentUI = "";
          }
        } else if (questionType?.quote) {
          if (textmessage === "Upload Invoice") {
            _textMessage = "Do you have an invoice you’d like to upload to create the quote? Click here to upload";
            _prompt = [];
            _contentUI = (
              <div className="card py-3 px-1" style={{ width: "100%" }}>
                <div className="justify-content-center">
                  <h6 className="text-center font-weight-500 mt-3">
                    Drag and drop files to upload <br /> or
                  </h6>
                  <Box sx={{ minWidth: "100px", width: "100%" }}>
                    <FileUploader
                      name="file"
                      types={["JPG", "PNG"]}
                      sx={{ minWidth: "200px", maxWidth: "200px", width: "220px" }}
                      style={{ minWidth: "200px" }}
                      handleChange={handleImageFileUpload}
                      onSelect={handleFileSelect}
                    />
                  </Box>
                </div>
              </div>
            );
          } else if (textmessage === "Input Quote Manually") {
            _textMessage = "Want to enter details manually? Let’s start with the serial number or model.";
            _prompt = [];
            _contentUI = "";
          } else if (textmessage === "Guided Quote Creation") {
            _textMessage = "Prefer a step-by-step guide to creating your quote? Let me ask you a few questions to get started.";
            _prompt = [];
            _contentUI = "";
          } else if (textmessage === "Type free-text details") {
            _textMessage = "Please enter the details for your quote. You can include parts, labour hours, rates, and any additional notes.";
            _prompt = [];
            _contentUI = "";
          } else if (textmessage === "Upload an Excel file with parts and services") {
            _textMessage = "Please upload an Excel file with parts, labor, and rates listed. Ensure columns are labeled for easy processing.";
            _prompt = [];
            _contentUI = (
              <div className="card py-3 px-1" style={{ width: "100%" }}>
                <div className="justify-content-center">
                  <h6 className="text-center font-weight-500 mt-3">
                    Drag and drop files to upload <br /> or
                  </h6>
                  <Box sx={{ minWidth: "100px", width: "100%" }}>
                    <FileUploader
                      name="file"
                      types={["EXCEL"]}
                      // types={["JPG", "PNG"]}
                      sx={{ minWidth: "200px", maxWidth: "200px", width: "220px" }}
                      style={{ minWidth: "200px" }}
                      handleChange={handleImageFileUpload}
                      onSelect={handleFileSelect}
                    />
                  </Box>
                </div>
              </div>
            );
          } else if (textmessage === "Follow a guided quote process") {
            _textMessage = "Prefer a step-by-step guide to creating your quote? Let me ask you a few questions to get started.";
            _prompt = [];
            _contentUI = "";
          }
        } else if (questionType?.collection) {
          if (textmessage === "Upload Collection Items") {
            _textMessage = "Do you have an invoice you’d like to upload to create the quote? Click here to upload";
            _prompt = [];
            _contentUI = (
              <div className="card py-3 px-1" style={{ width: "100%" }}>
                <div className="justify-content-center">
                  <h6 className="text-center font-weight-500 mt-3">
                    Drag and drop files to upload <br /> or
                  </h6>
                  <Box sx={{ minWidth: "100px", width: "100%" }}>
                    <FileUploader
                      name="file"
                      types={["JPG", "PNG"]}
                      sx={{ minWidth: "200px", maxWidth: "200px", width: "220px" }}
                      style={{ minWidth: "200px" }}
                      handleChange={handleImageFileUpload}
                      onSelect={handleFileSelect}
                    />
                  </Box>
                </div>
              </div>
            );
          } else if (textmessage === "Input Collection Manually") {
            _textMessage = "Want to enter details manually? Let’s start with the serial number or model.";
            _prompt = [];
            _contentUI = "";
          } else if (textmessage === "Guided Collection Creation") {
            _textMessage = "Prefer a step-by-step guide to creating your quote? Let me ask you a few questions to get started.";
            _prompt = [];
            _contentUI = "";
          }
        } else if (questionType?.inventory) {
          if (textmessage === "Create Purchase Requisition") {
            _textMessage = "Select the one option for the Create Purchase Request";
            _prompt = ["Type Input Text", "Upload Excel", "Guided Creation"];
            _contentUI = "";
          } else if (textmessage === "Inventory Query Assistant") {
            _textMessage = "";
            _prompt = ["Type Input Text", "Upload Excel", "Guided Creation"];
            _contentUI = "";
          } else if (textmessage === "Receive Goods") {
            _textMessage = "";
            _prompt = [];
            _contentUI = "";
          } else if (textmessage === "Inventory Transfer Process") {
            _textMessage = "";
            _prompt = [];
            _contentUI = "";
          }
        }

        obj = {
          sender: "bot",
          text: `${_textMessage}`,
          prompt: _prompt,
          productList: [],
          showList: false,
          activeStep: 3,
          timestamp: timestamp,
          contentUI: _contentUI,
        };
        setTimeout(() => {
          setIsLoading(true);
          newMessages.push(obj);
          updateChatbotMessage(newMessages);
          if (questionType?.warranty && textmessage === "Guided Claim Creation") {
            setQuestionIndex(1);
            let _question = warrantyQuestions[0];
            obj = {
              sender: "bot",
              text: _question?.question,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 4,
              timestamp: timestamp,
              contentUI: "",
            };
            setStartQuestions(true);
            setTimeout(() => {
              setIsLoading(true);
              newMessages.push(obj);
              updateChatbotMessage(newMessages);
            }, 500);
          } else if (questionType?.quote && (textmessage === "Guided Quote Creation" || textmessage === "Follow a guided quote process")) {
            setQuestionIndex(1);
            let _question = quoteQuestions[0];
            obj = {
              sender: "bot",
              text: _question?.question,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 4,
              timestamp: timestamp,
              contentUI: "",
            };
            setStartQuestions(true);
            setTimeout(() => {
              setIsLoading(true);
              newMessages.push(obj);
              updateChatbotMessage(newMessages);
            }, 500);
          }
        }, 500);
        if (
          textmessage === "Input Claim Manually" ||
          textmessage === "Input Quote Manually" ||
          textmessage === "Type free-text details" ||
          textmessage === "Input Collection Manually"
        ) {
          setTimeout(() => {
            closeModal();
            handleShowGenerativeAIModal();
          }, 4000);
        }
        setActiveStep(3);
      } else if (activeSteper === 3) {
        let textmessage = innerText;
        const newMessages = [
          ...messages,
          { sender: "user", text: innerText, timestamp: timestamp, prompt: [], productList: [], showList: false, activeStep: 1 },
        ];
        setMessages(newMessages);

        let _textMessage = "";
        let _prompt = [];
        let _contentUI = "";
        if (questionType?.warranty) {
          if (textmessage === "Upload Service Report") {
            _textMessage = `Do you have a service report you’d like to upload to create the claim? Click here to upload`;
            _prompt = [];

            _contentUI = (
              <div className="card py-3 px-1" style={{ width: "100%" }}>
                <div className="justify-content-center">
                  <h6 className="text-center font-weight-500 mt-3">
                    Drag and drop files to upload <br /> or
                  </h6>
                  <Box sx={{ minWidth: "100px", width: "100%" }}>
                    <FileUploader
                      name="file"
                      types={["JPG", "PNG"]}
                      sx={{ minWidth: "200px", maxWidth: "200px", width: "220px" }}
                      style={{ minWidth: "200px" }}
                      handleChange={handleImageFileUpload}
                      onSelect={handleFileSelect}
                    />
                  </Box>
                </div>
              </div>
            );
          } else if (textmessage === "Input Claim Manually") {
            _textMessage = `Kindly hold on for a moment to enter details manually`;
            _prompt = [];
            _contentUI = "";
          } else if (textmessage === "Guided Claim Creation") {
            _textMessage = `Prefer a step-by-step guide to creating your claim? Let me ask you a few questions to get started.`;
            _prompt = [];
            _contentUI = "";
          }
        } else if (questionType?.inventory) {
          if (innerText === "Type Input Text") {
            _textMessage = `Kindly enter required items, quantities, and specifications  details manually`;
            _prompt = [];
            _contentUI = "";
          } else if (innerText === "Upload Excel") {
            _textMessage = "Please upload an Excel file. Ensure columns are labeled for easy processing.";
            _prompt = [];
            _contentUI = (
              <div className="card py-3 px-1" style={{ width: "100%" }}>
                <div className="justify-content-center">
                  <h6 className="text-center font-weight-500 mt-3">
                    Drag and drop files to upload <br /> or
                  </h6>
                  <Box sx={{ minWidth: "100px", width: "100%" }}>
                    <FileUploader
                      name="file"
                      types={["EXCEL"]}
                      // types={["JPG", "PNG"]}
                      sx={{ minWidth: "200px", maxWidth: "200px", width: "220px" }}
                      style={{ minWidth: "200px" }}
                      handleChange={handleImageFileUpload}
                      onSelect={handleFileSelect}
                    />
                  </Box>
                </div>
              </div>
            );
          } else if (innerText === "Guided Creation") {
            _textMessage = "Prefer a step-by-step guide to creating your inventory? Let me ask you a few questions to get started.";
            _prompt = [];
            _contentUI = "";
          }

          obj = {
            sender: "bot",
            text: `${_textMessage}`,
            prompt: _prompt,
            productList: [],
            showList: false,
            activeStep: 3,
            timestamp: timestamp,
            contentUI: _contentUI,
          };
          setTimeout(() => {
            setIsLoading(true);
            newMessages.push(obj);
            updateChatbotMessage(newMessages);
            if (questionType?.warranty && textmessage === "Guided Claim Creation") {
              setQuestionIndex(1);
              let _question = warrantyQuestions[0];
              obj = {
                sender: "bot",
                text: _question?.question,
                prompt: [],
                productList: [],
                showList: false,
                activeStep: 4,
                timestamp: timestamp,
                contentUI: "",
              };
              setStartQuestions(true);
              setTimeout(() => {
                setIsLoading(true);
                newMessages.push(obj);
                updateChatbotMessage(newMessages);
              }, 500);
            } else if (questionType?.quote && (textmessage === "Guided Quote Creation" || textmessage === "Follow a guided quote process")) {
              setQuestionIndex(1);
              let _question = quoteQuestions[0];
              obj = {
                sender: "bot",
                text: _question?.question,
                prompt: [],
                productList: [],
                showList: false,
                activeStep: 4,
                timestamp: timestamp,
                contentUI: "",
              };
              setStartQuestions(true);
              setTimeout(() => {
                setIsLoading(true);
                newMessages.push(obj);
                updateChatbotMessage(newMessages);
              }, 500);
            } else if (questionType?.inventory && textmessage === "Guided Creation") {
            }
          }, 500);
        }
        obj = {
          sender: "bot",
          text: `${_textMessage}`,
          prompt: _prompt,
          productList: [],
          showList: false,
          activeStep: 3,
          timestamp: timestamp,
          contentUI: _contentUI,
        };
        setTimeout(() => {
          setIsLoading(true);
          newMessages.push(obj);
          updateChatbotMessage(newMessages);
          // ||
          // textmessage === "Input Quote Manually" ||
          // textmessage === "Type free-text details" ||
          // textmessage === "Input Collection Manually"
          if (textmessage === "Input Claim Manually") {
            setTimeout(() => {
              closeModal();
              handleShowGenerativeAIModal();
            }, 4000);
          } else if (questionType?.warranty && textmessage === "Guided Claim Creation") {
            setQuestionIndex(1);
            let _question = warrantyQuestions[0];
            obj = {
              sender: "bot",
              text: _question?.question,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 4,
              timestamp: timestamp,
              contentUI: "",
            };
            setStartQuestions(true);
            setTimeout(() => {
              setIsLoading(true);
              newMessages.push(obj);
              updateChatbotMessage(newMessages);
            }, 500);
          } else if (questionType?.quote && (textmessage === "Guided Quote Creation" || textmessage === "Follow a guided quote process")) {
            setQuestionIndex(1);
            let _question = quoteQuestions[0];
            obj = {
              sender: "bot",
              text: _question?.question,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 4,
              timestamp: timestamp,
              contentUI: "",
            };
            setStartQuestions(true);
            setTimeout(() => {
              setIsLoading(true);
              newMessages.push(obj);
              updateChatbotMessage(newMessages);
            }, 500);
          }
        }, 500);
        setActiveStep(4);
      } else if (activeSteper === 4) {
        if (questionType?.warranty) {
          const newMessages = [
            ...messages,
            { sender: "user", text: innerText, timestamp: timestamp, prompt: [], productList: [], showList: false, activeStep: 1 },
          ];

          setMessages(newMessages);
          if (innerText.toLowerCase() === "yes") {
            let _question = warrantyQuestions[6];

            let obj = {
              sender: "bot",
              text: _question?.question,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 4,
              timestamp: timestamp,
              contentUI: "",
            };
            setUserInput("");
            setTimeout(() => {
              setIsLoading(true);
              newMessages.push(obj);
              updateChatbotMessage(newMessages);
            }, 500);
            setQuestionIndex(6);
          } else if (innerText.toLowerCase() === "no") {
            let _question = warrantyQuestions[9];
            const _questionsRecord = [...warrantyQuestions];
            _questionsRecord[8].answer = innerText;
            setWarrantyQuestions(_questionsRecord);

            let obj = {
              sender: "bot",
              text: _question?.question,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 4,
              timestamp: timestamp,
              contentUI: "",
            };
            setUserInput("");
            setTimeout(() => {
              setIsLoading(true);
              newMessages.push(obj);
              updateChatbotMessage(newMessages);
            }, 500);
            setQuestionIndex(9);
          }
        } else if (questionType?.quote) {
          const newMessages = [
            ...messages,
            { sender: "user", text: innerText, timestamp: timestamp, prompt: [], productList: [], showList: false, activeStep: 1 },
          ];

          setMessages(newMessages);
          if (innerText.toLowerCase() === "yes") {
            const _questionsRecord = [...quoteQuestions];
            _questionsRecord[5].answer = innerText;
            setQuoteQuestions(_questionsRecord);
            let _question = quoteQuestions[6];

            let obj = {
              sender: "bot",
              text: _question?.question,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 4,
              timestamp: timestamp,
              contentUI: "",
            };
            setUserInput("");
            setTimeout(() => {
              setIsLoading(true);
              newMessages.push(obj);
              updateChatbotMessage(newMessages);
            }, 500);
            setQuestionIndex(6);
          } else if (innerText.toLowerCase() === "no") {
            const _questionsRecord = [...quoteQuestions];
            _questionsRecord[5].answer = innerText;
            setQuoteQuestions(_questionsRecord);
            const ui = (
              <div className="card border p-2">
                <ul className="list-group">
                  <li className="list-group-item d-flex w-100 justify-content-between">
                    <h5>Parts:</h5>
                    <h5>
                      <b>{quoteQuestions[1]?.answer}</b>
                    </h5>
                  </li>
                  <li className="list-group-item d-flex w-100 justify-content-between">
                    <h5>Labour:</h5>
                    <h5>
                      <b>
                        {currencyFormatter.format(
                          (Number(!isNaN(quoteQuestions[3]?.answer) ? quoteQuestions[3]?.answer : 0) || 0) *
                            (Number(!isNaN(quoteQuestions[4]?.answer) ? quoteQuestions[4]?.answer : 0) || 0)
                        )}
                      </b>
                    </h5>
                  </li>
                  <li className="list-group-item d-flex w-100 justify-content-between">
                    <h5>Additional Costs:</h5>
                    <h5>
                      <b>
                        {currencyFormatter.format(
                          (Number(!isNaN(quoteQuestions[6]?.answer) ? quoteQuestions[6]?.answer : 0) || 0) +
                            (Number(!isNaN(quoteQuestions[7]?.answer) ? quoteQuestions[7]?.answer : 0) || 0)
                        )}
                      </b>
                    </h5>
                  </li>
                  <li className="list-group-item d-flex w-100 justify-content-between">
                    <h5>Total:</h5>
                    <h5>
                      <b>
                        {currencyFormatter.format(
                          Number(Number(!isNaN(quoteQuestions[3]?.answer) ? quoteQuestions[3]?.answer : 0) || 0) *
                            (Number(!isNaN(quoteQuestions[4]?.answer) ? quoteQuestions[4]?.answer : 0) || 0) +
                            Number((!isNaN(quoteQuestions[6]?.answer) ? quoteQuestions[6]?.answer : 0) || 0) +
                            Number((!isNaN(quoteQuestions[7]?.answer) ? quoteQuestions[7]?.answer : 0) || 0)
                        )}
                      </b>
                    </h5>
                  </li>
                </ul>
              </div>
            );
            let obj = {
              sender: `bot`,
              text: `Here’s the complete quote based on your inputs<br />`,
              prompt: [],
              productList: [],
              showList: false,
              activeStep: 4,
              timestamp: timestamp,
              contentUI: ui,
            };
            setTimeout(() => {
              setIsLoading(true);
              newMessages.push(obj);
              updateChatbotMessage(newMessages);
            }, 500);
            setUserInput("");
            setQuestionIndex(7);
          }
        }
      }
    }

    // setActiveStep(activeStep + 1);
  };

  // handle the chatbot summary
  const handleChatbotSummary = async (conversationHistory) => {
    setIsLoading(true);
    const userResponses = conversationHistory
      .filter((msg) => msg.sender === "user")
      .map((msg) => msg.text)
      .join("\n");
    const prompt = `Here are the user's responses:\n${userResponses}\nPlease summarize the warranty claim in a clear and concise format.`;
    try {
      const botMessage = "Hello";
      const timestamp = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
      setMessages([...conversationHistory, { sender: "bot", text: botMessage, timestamp: timestamp }]);
    } catch (error) {
      console.error("Error fetching summary from OpenAI:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Enter key press
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  // select the Producct|Serial Number
  const handleProductSelect = async (product) => {
    try {
      let timestamp = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
      let newMessages = [
        ...messages,
        {
          sender: "user",
          text: `${product?.makerSerialNumber}`,
          timestamp: timestamp,
          prompt: [],
          productList: [],
          showList: false,
          activeStep: 1,
          contentUI: "",
        },
      ];

      setMachineData({ make: product?.market, family: product?.maker });
      setMessages(newMessages);

      let obj = {};

      let _question = "";
      if (questionType?.warranty) {
        _question = warrantyQuestions[3];
        const _questionsRecord = [...warrantyQuestions];
        _questionsRecord[1].answer = product?.makerSerialNumber;
        _questionsRecord[2].answer = product?.model;
        setWarrantyQuestions(_questionsRecord);
        setQuestionIndex(3);
        obj = {
          sender: "bot",
          text: `Model Number is ${product?.model ? product?.model : "null"} for serial number ${product?.makerSerialNumber}`,
          prompt: [],
          productList: [],
          showList: false,
          activeStep: 4,
          timestamp: timestamp,
          contentUI: "",
        };
      } else if (questionType?.quote) {
        _question = quoteQuestions[2];
        const _questionsRecord = [...quoteQuestions];
        _questionsRecord[1].answer = product?.makerSerialNumber;
        // _questionsRecord[2].answer = product?.model;
        setQuoteQuestions(_questionsRecord);
        setQuestionIndex(2);

        obj = {
          sender: "bot",
          // text: `Model Number is ${product?.model ? product?.model : "null"} for serial number ${product?.makerSerialNumber}`,
          text: `Equipment number is ${product?.makerSerialNumber}`,
          prompt: [],
          productList: [],
          showList: false,
          activeStep: 4,
          timestamp: timestamp,
          contentUI: "",
        };
      }

      setIsLoading(true);
      setTimeout(async () => {
        newMessages.push(obj);
        await updateChatbotMessage(newMessages);

        obj = {
          sender: "bot",
          text: _question?.question,
          prompt: [],
          productList: [],
          showList: false,
          activeStep: 4,
          timestamp: timestamp,
          contentUI: "",
        };

        setIsLoading(true);
        setTimeout(() => {
          newMessages.push(obj);
          updateChatbotMessage(newMessages);
        }, 500);
      }, 500);
    } catch (error) {}
  };

  // click on Launch App
  const handleLaunchApp = () => {
    const isAnyOptionSelected =
      questionType?.warranty || questionType?.quote || questionType?.inventory || questionType?.kit || questionType?.collection;
    if (userInput.length === 0 && !isAnyOptionSelected) {
      handleSnack("error", "Please select the options above or type manually.");
      return;
    } else {
      if (questionType?.warranty) {
        navigate.push(CLAIM_MASTER);
      } else if (questionType?.quote) {
        navigate.push(QUOTE_SPARE_PARTS);
      } else if (questionType?.inventory) {
        navigate.push(WAREHOUSE_INVENTORY);
      } else if (questionType?.kit) {
        navigate.push(REPAIR_KITS);
      } else if (questionType?.collection) {
        // navigate.push("/equipment");
      } else if (userInput.length !== 0) {
      }
      closeModal();
    }
  };

  // click om Reports icons/Image
  const handleShowReports = () => {
    const isAnyOptionSelected =
      questionType?.warranty || questionType?.quote || questionType?.inventory || questionType?.kit || questionType?.collection;
    if (!isAnyOptionSelected) {
      handleSnack("error", "Please select the options above.");
      return;
    } else {
      setShowReport(true);
      setActiveStep(1);
      let obj = {};
      let _textMessage = "";
      let _prompt = [];
      const timestamp = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

      let newMessages = [...messages];

      newMessages = [
        ...messages,
        {
          sender: "user",
          text: `${"Reports"}`,
          timestamp: timestamp,
          prompt: [],
          productList: [],
          showList: false,
          activeStep: 1,
          contentUI: "",
        },
      ];
      setMessages(newMessages);

      if (questionType?.warranty) {
        _textMessage = "Please select the options below";
        _prompt = ["Show latest claims", "Show latest warranties", "Show latest warranty requests"];
      }

      obj = {
        sender: "bot",
        text: `${_textMessage}`,
        prompt: _prompt,
        productList: [],
        showList: false,
        activeStep: 1,
        timestamp: timestamp,
      };
      setTimeout(() => {
        setIsLoading(true);
        newMessages.push(obj);
        updateChatbotMessage(newMessages);
      }, 500);
    }
  };

  // click on Attach File Icons
  const handleClickOnAttachIcons = () => {
    const isAnyOptionSelected =
      questionType?.warranty || questionType?.quote || questionType?.inventory || questionType?.kit || questionType?.collection;
    if (!isAnyOptionSelected) {
      handleSnack("error", "Please select the options above.");
      return;
    } else {
      let _textMessage = "";
      let _prompt = [];
      let _contentUI = "";
      const timestamp = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

      let newMessages = [
        ...messages,
        {
          sender: "user",
          text: "Attach Files, Images or Reports",
          timestamp: timestamp,
          prompt: [],
          productList: [],
          showList: false,
          activeStep: 1,
          contentUI: "",
        },
      ];
      setMessages(newMessages);

      if (questionType?.warranty) {
        _textMessage = "Attach files, images or reports for warranty";
      } else if (questionType?.quote) {
        _textMessage = "Attach files, images or reports for quote";
      } else if (questionType?.inventory) {
        _textMessage = "Attach files, images or reports for inventory";
      } else if (questionType?.kit) {
        _textMessage = "Attach files, images or reports for kit";
      } else if (questionType?.collection) {
        _textMessage = "Attach files, images or reports for collection";
      }

      _contentUI = (
        <div className="card py-3 px-1" style={{ width: "100%" }}>
          <div className="justify-content-center">
            <h6 className="text-center font-weight-500 mt-3">
              Drag and drop files to upload <br /> or
            </h6>
            <Box sx={{ minWidth: "100px", width: "100%" }}>
              <FileUploader
                name="file"
                types={["JPG", "PDF", "EXCEL"]}
                sx={{ minWidth: "200px", maxWidth: "200px", width: "220px" }}
                style={{ minWidth: "200px" }}
                handleChange={handleImageFileUpload}
                onSelect={handleFileSelect}
              />
            </Box>
          </div>
        </div>
      );

      let obj = {
        sender: "bot",
        text: `${_textMessage}`,
        prompt: _prompt,
        productList: [],
        showList: false,
        activeStep: 3,
        timestamp: timestamp,
        contentUI: _contentUI,
      };

      setTimeout(() => {
        setIsLoading(true);
        newMessages.push(obj);
        updateChatbotMessage(newMessages);
      }, 500);
    }
  };

  return (
    <div className="app-container">
      <div className="chat-header">
        <svg id="bot-avatar" data-name="Layer 1" className="bot-avatar" xmlns="http://www.w3.org/2000/svg" viewBox="2 2 125.88 135.35">
          <path d="M57.49,29.2V23.53a14.41,14.41,0,0,1-2-.93A12.18,12.18,0,0,1,50.44,7.5a12.39,12.39,0,0,1,2.64-3.95A12.21,12.21,0,0,1,57,.92,12,12,0,0,1,61.66,0,12.14,12.14,0,0,1,72.88,7.5a12.14,12.14,0,0,1,0,9.27,12.08,12.08,0,0,1-2.64,3.94l-.06.06a12.74,12.74,0,0,1-2.36,1.83,11.26,11.26,0,0,1-2,.93V29.2H94.3a15.47,15.47,0,0,1,15.42,15.43v2.29H115a7.93,7.93,0,0,1,7.9,7.91V73.2A7.93,7.93,0,0,1,115,81.11h-5.25v2.07A15.48,15.48,0,0,1,94.3,98.61H55.23L31.81,118.72a2.58,2.58,0,0,1-3.65-.29,2.63,2.63,0,0,1-.63-1.85l1.25-18h-.21A15.45,15.45,0,0,1,13.16,83.18V81.11H7.91A7.93,7.93,0,0,1,0,73.2V54.83a7.93,7.93,0,0,1,7.9-7.91h5.26v-2.3A15.45,15.45,0,0,1,28.57,29.2H57.49ZM82.74,47.32a9.36,9.36,0,1,1-9.36,9.36,9.36,9.36,0,0,1,9.36-9.36Zm-42.58,0a9.36,9.36,0,1,1-9.36,9.36,9.36,9.36,0,0,1,9.36-9.36Zm6.38,31.36a2.28,2.28,0,0,1-.38-.38,2.18,2.18,0,0,1-.52-1.36,2.21,2.21,0,0,1,.46-1.39,2.4,2.4,0,0,1,.39-.39,3.22,3.22,0,0,1,3.88-.08A22.36,22.36,0,0,0,56,78.32a14.86,14.86,0,0,0,5.47,1A16.18,16.18,0,0,0,67,78.22,25.39,25.39,0,0,0,72.75,75a3.24,3.24,0,0,1,3.89.18,3,3,0,0,1,.37.41,2.22,2.22,0,0,1,.42,1.4,2.33,2.33,0,0,1-.58,1.35,2.29,2.29,0,0,1-.43.38,30.59,30.59,0,0,1-7.33,4,22.28,22.28,0,0,1-7.53,1.43A21.22,21.22,0,0,1,54,82.87a27.78,27.78,0,0,1-7.41-4.16l0,0ZM94.29,34.4H28.57A10.26,10.26,0,0,0,18.35,44.63V83.18A10.26,10.26,0,0,0,28.57,93.41h3.17a2.61,2.61,0,0,1,2.41,2.77l-1,14.58L52.45,94.15a2.56,2.56,0,0,1,1.83-.75h40a10.26,10.26,0,0,0,10.22-10.23V44.62A10.24,10.24,0,0,0,94.29,34.4Z" />
        </svg>
        <div className="chat-header-details">
          <h2 className="text-white">AI Assistant</h2>
          <span>24×7 Support Bot</span>
        </div>
      </div>

      <div className="chat-window">
        <div className="chat-messages">
          {messages.map((msg, index) => (
            <>
              <div key={index} className={`message ${msg?.sender === "user" ? "user-message" : "bot-message"}`}>
                <div
                  className="message-text"
                  dangerouslySetInnerHTML={{
                    __html: msg?.text,
                  }}
                ></div>
                <div className="message-meta">
                  <span className="timestamp">{msg.timestamp}</span>
                </div>
              </div>

              <div className="bot-message-prompt">
                {msg?.prompt.length > 0 &&
                  msg?.prompt.map((prompt, j) => (
                    <p key={`prompt-${j}`} className="py-2 px-2" onClick={(e) => handleSelectPrompt(e, msg?.activeStep)}>
                      {prompt}
                    </p>
                  ))}
              </div>

              {msg?.contentUI && <div className="bot-message-card">{msg?.contentUI}</div>}
              {msg?.showList && msg?.productList.length > 0 && (
                <div className="product-list">
                  {msg?.productList.map(
                    (product) =>
                      product?.makerSerialNumber && (
                        <Tooltip>
                          <button key={product.id} className="product-option" onClick={() => handleProductSelect(product)}>
                            {product.makerSerialNumber}
                          </button>
                        </Tooltip>
                      )
                  )}
                </div>
              )}
            </>
          ))}

          {isLoading && (
            <div className="bot-message-typing-indicator typing-indicator">
              <div className="d-flex align-items-center justify-content-start">
                <Loader type="bubble-top" bgColor={"#808080"} title={""} color={"#FFFFFF"} size={25} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="chat-input">
        <div className="container">
          <div className="d-flex flex-column border border-radius-10 shadow-sm py-2 px-3 bg-white">
            <input
              type="text"
              value={userInput}
              onChange={handleInputChange}
              id="userInput"
              className="form-control border-0 mb-2" // Added margin-bottom for spacing
              onKeyPress={handleKeyPress}
              autoComplete="off"
              placeholder="Ask anything ..."
            />
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center gap-4">
                <button className="btn bg-primary text-white align-self-start user-button" onClick={handleLaunchApp}>
                  Launch app
                </button>
                <button className="btn bg-gray p-1 mx-1 other-icons-button" onClick={handleClickOnAttachIcons}>
                  <Tooltip title="Attach Files, Images, or Reports">
                    <AttachFileIcon sx={{ fontSize: 35 }} className="text-secondary" />
                  </Tooltip>
                </button>
                <button className="p-1 other-icons-button">
                  <EmojiEmotionsIcon sx={{ fontSize: 35 }} />
                </button>
                {/* <DropdownButton className="ml-2 px-2 p-1 other-icons-dropdown-btn" drop="up-centered" title="">
                  <Dropdown.Item className="cursor font-size-14">
                    <div className="d-flex justify-content-between align-items-center py-1 px-2 font-weight-500 dropdown-menu-item">
                      <span>Show latest claims</span>
                      <ArrowRightAltIcon sx={{ fontSize: 35 }} />
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item className="cursor font-size-14">
                    <div className="d-flex justify-content-between align-items-center py-1 px-2 font-weight-500 dropdown-menu-item">
                      <span>Show latest warranties</span>
                      <ArrowRightAltIcon sx={{ fontSize: 35 }} />
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item className="cursor font-size-14">
                    <div className="d-flex justify-content-between align-items-center py-1 px-2 font-weight-500 dropdown-menu-item">
                      <span>Show latest warranty requests</span>
                      <ArrowRightAltIcon sx={{ fontSize: 35 }} />
                    </div>
                  </Dropdown.Item>
                </DropdownButton> */}
                <button className="p-1 other-icons-btn-img" onClick={handleShowReports}>
                  <Tooltip title="Reports">
                    <svg
                      id="uuid-2339a07b-c6d4-4b85-897c-b6ee6a6101ee"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 110.29999 145.5706"
                      fill="#888888"
                      height={20}
                      width={20}
                    >
                      <path
                        className="uuid-e32578ab-2aa2-47d9-8df4-2b8a18d4a5e3"
                        d="M110.29996,19.605C110.29996,8.79397,101.50601,.00002,90.69571,.00002H21.81094V21.81081H-.00009v123.75977H88.48916v-21.81104h21.81079V19.605Zm-30.14575,117.63062H8.33487V30.14577H74.89273c2.90088,0,5.26147,2.3606,5.26147,5.26172v101.82812Zm21.81079-21.81104h-13.47583V35.40749c0-7.49658-6.09912-13.59668-13.59644-13.59668H30.1459V8.33498h60.5498c6.21387,0,11.26929,5.0564,11.26929,11.27002V115.42458Z"
                      />
                      <path
                        className="uuid-e32578ab-2aa2-47d9-8df4-2b8a18d4a5e3"
                        d="M63.08194,98.36257H25.40616c-2.30176,0-4.16748,1.86548-4.16748,4.16748,0,2.30176,1.86572,4.16748,4.16748,4.16748H63.08194c2.302,0,4.16748-1.86572,4.16748-4.16748,0-2.302-1.86548-4.16748-4.16748-4.16748Z"
                      />
                      <path
                        className="uuid-e32578ab-2aa2-47d9-8df4-2b8a18d4a5e3"
                        d="M63.08194,118.39895H25.40616c-2.30176,0-4.16748,1.86548-4.16748,4.16748s1.86572,4.16748,4.16748,4.16748H63.08194c2.302,0,4.16748-1.86548,4.16748-4.16748s-1.86548-4.16748-4.16748-4.16748Z"
                      />
                      <path
                        className="uuid-e32578ab-2aa2-47d9-8df4-2b8a18d4a5e3"
                        d="M44.24454,40.64919c-12.68555,0-23.00586,10.32104-23.00586,23.00586s10.32031,23.00415,23.00586,23.00415c12.68481,0,23.00488-10.31934,23.00488-23.00415s-10.32007-23.00586-23.00488-23.00586Zm-14.6709,23.00586c0-8.08911,6.58179-14.6709,14.6709-14.6709,2.53198,0,4.91577,.64526,6.99658,1.7793l-11.16406,11.16602v15.7832c-6.06421-1.80078-10.50342-7.41675-10.50342-14.05762Zm18.83838,14.05762v-12.33203l8.7229-8.72339c1.13428,2.0813,1.77954,4.46558,1.77954,6.9978,0,6.64087-4.43848,12.25684-10.50244,14.05762Z"
                      />
                    </svg>
                  </Tooltip>
                </button>
              </div>
              <button className="btn btn-light ms-3 p-0 send-button" onClick={handleSendMessage} disabled={userInput.length == 0}>
                <NorthIcon sx={{ fontSize: 30 }} />
              </button>
            </div>
          </div>
        </div>
        {/* <input
          type="text"
          value={userInput}
          onChange={handleInputChange}
          id="userInput"
          className="userInput"
          onKeyPress={handleKeyPress}
          autoComplete="off"
          placeholder="Ask anything ..."
        />
        <button onClick={handleSendMessage}>Send</button> */}
      </div>
    </div>
  );
};

export default AIAssistence;
