import React, { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import PaymentIcon from "@mui/icons-material/Payment";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DescriptionIcon from "@mui/icons-material/Description";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

import { DataGrid } from "@mui/x-data-grid";
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, LinearProgress, Tooltip } from "@mui/material";

import Moment from "react-moment";
import { Modal } from "react-bootstrap";
import { currencyFormatter } from "pages/Common/utils/currencyFormatter";
import { GRID_STYLE } from "pages/Common/constants";

const orderItemsArr = [
  {
    partsId: 198,
    partNumber: "2454908",
    partDescription: "Cement",
    quantity: 1,
    allocatedQuantity: 0,
    listPrice: 22.94,
    totalPrice: 22.94,
    sparepartImages: [],
    fullyAllocated: false,
  },
  {
    partsId: 199,
    partNumber: "2454907",
    partDescription: 'Rod 12"',
    quantity: 1,
    allocatedQuantity: 0,
    listPrice: 25.56,
    totalPrice: 25.56,
    sparepartImages: [],
    fullyAllocated: false,
  },
];

const ProgressBar = ({ progress, styles }) => {
  return (
    <LinearProgress
      variant="determinate"
      value={progress}
      style={{ height: 10, borderRadius: 5, backgroundColor: "#e0e0e0" }}
      sx={{
        "& .MuiLinearProgress-bar": { borderRadius: 5, backgroundColor: "#872ff7", ...styles },
      }}
    />
  );
};

const POOrderProgress = ({ show, hideModal, handleSnack, recordId }) => {
  const [orderedItems, setOrderedItems] = useState([...orderItemsArr]);

  const columns = [
    {
      field: "partsId",
      headerName: "ID",
      flex: 1,
    },
    { field: "partNumber", headerName: "Part Number", flex: 1 },
    { field: "partDescription", headerName: "Part Description", flex: 1 },
    { field: "quantity", headerName: "Quantity", flex: 1 },
    {
      field: "listPrice",
      headerName: "Price",
      flex: 1,
      renderCell: ({ row }) => currencyFormatter.format(row.listPrice),
    },
    {
      field: "tax",
      headerName: "Tax",
      flex: 1,
      renderCell: ({ row }) => currencyFormatter.format((row.listPrice * 10) / 100),
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      flex: 1,
      renderCell: ({ row }) => (
        <div className="font-weight-500 font-size-12">{currencyFormatter.format((row.listPrice + (row.listPrice * 10) / 100) * row?.quantity)}</div>
      ),
    },
  ];
  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        <Modal.Body style={{ backgroundColor: "#f6f6f6" }}>
          <div className="d-flex justify-content-between my-2 mt-2 ">
            <div>
              <h3 className="text-light">#PO000168</h3>
              <h5 className="text-muted">
                Order History / Order Details / - <Moment format="DD-MM-YYYY hh:mm A">{new Date()}</Moment>
              </h5>
            </div>
            <div>
              <button className="btn btn-danger mr-2">
                <DeleteIcon /> Delete Order
              </button>
              <button
                className="btn btn-info mr-2 "
                //   onClick={() => handleTrackOrder("progressExpended")}
              >
                <TrackChangesIcon /> Track Order
              </button>
              <button className="btn bg-primary text-white mr-2 ">
                <DriveFileRenameOutlineIcon /> Edit Order
              </button>
            </div>
          </div>
          <div className="card border px-3 py-2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="mb-1">Progress</h4>
                <h6 className="text-muted">Current Order Status</h6>
              </div>
              <h3>{currencyFormatter.format(557.62)}</h3>
            </div>
            <div className="d-flex">
              <div className="card border p-3 m-2" style={{ width: "20%" }}>
                <div>
                  <AllInboxIcon />
                </div>
                <h5 className="mt-2 mb-3">Order Confirming</h5>
                <ProgressBar progress={100} />
              </div>
              <div className="card border p-3 m-2" style={{ width: "20%" }}>
                <div>
                  <PaymentIcon />
                </div>
                <h5 className="mt-2 mb-3">Payment Pending</h5>
                <ProgressBar progress={100} />
              </div>
              <div className="card border p-3 m-2" style={{ width: "20%" }}>
                <div>
                  <AccountTreeIcon />
                </div>
                <h5 className="mt-2 mb-3">Processing</h5>
                <ProgressBar progress={50} />
              </div>
              <div className="card border p-3 m-2" style={{ width: "20%" }}>
                <div>
                  <LocalShippingIcon />
                </div>
                <h5 className="mt-2 mb-3">Shipping</h5>
                <ProgressBar progress={0} />
              </div>
              <div className="card border p-3 m-2" style={{ width: "20%" }}>
                <div>
                  <CheckCircleRoundedIcon />
                </div>
                <h5 className="mt-2 mb-3">Delivered</h5>
                <ProgressBar progress={0} />
              </div>
            </div>
          </div>
          <Divider sx={{ my: 0 }} />
          <div className="card border px-3 py-2">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="mb-0">Customer</h4>
                <h6 className="text-muted">Information Details</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-4">
                <div className="card border" style={{ overflow: "hidden" }}>
                  <div className={`d-flex justify-content-between align-items-center bg-green p-2 text-white`}>
                    <h6 className="bg-green text-white mb-0">Order Info </h6>
                    <Tooltip title="View Order info">
                      <ControlPointIcon className="cursor" />
                    </Tooltip>
                  </div>
                  <div className={"p-2"}>
                    <div className="row py-2">
                      <div className="col-md-3 col-sm-3">
                        <p className="mb-0">Account</p>
                      </div>
                      <div className="col-md-9 col-sm-9 text-right px-3">
                        <h6 className="mb-0 ">
                          <b>1021034 - SCHNEIDER ELECTRIC INDIA PRIVATE LIMITED</b>
                        </h6>
                      </div>
                    </div>
                    <div className="hr my-0"></div>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Store</p>
                      <h6 className="mb-0">
                        <b>{"-"}</b>
                      </h6>
                    </div>
                    <div className="hr my-0"></div>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Order Type</p>
                      <h6 className="mb-0">
                        <b>{"-"}</b>
                      </h6>
                    </div>
                    <div className="hr my-0"></div>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Order By</p>
                      <h6 className="mb-0">
                        <b>{"-"}</b>
                      </h6>
                    </div>
                    <div className="hr my-0"></div>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Email</p>
                      <h6 className="mb-0">
                        <b>{"-"}</b>
                      </h6>
                    </div>
                    <div className="hr my-0"></div>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Contact</p>
                      <h6 className="mb-0">
                        <b>{"-"}</b>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="card border" style={{ overflow: "hidden", height: "18rem" }}>
                  <div className={`d-flex justify-content-between align-items-center bg-green ${"p-2"} text-white`}>
                    <h6 className="bg-green text-white mb-0">Shipping Details</h6>
                    <Tooltip title="View Shipping Details">
                      <AddLocationAltIcon className="cursor" />
                    </Tooltip>
                  </div>
                  <div className={"p-2"}>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Delivery Type</p>
                      <h6 className="mb-0">
                        <b>{"Standard"}</b>
                      </h6>
                    </div>
                    <div className="hr my-0"></div>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Delivery Priority</p>
                      <h6 className="mb-0">
                        <b>{"-"}</b>
                      </h6>
                    </div>
                    <div className="hr my-0"></div>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Lead Time</p>
                      <h6 className="mb-0">
                        <b>{"$" + 0 + " Days" || "-"}</b>
                      </h6>
                    </div>
                    <div className="hr my-0"></div>
                    <div className="d-block py-2">
                      <p className="mb-0">Address</p>
                      <h6 className="mb-0">
                        <b>{"-"}</b>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="card border " style={{ overflow: "hidden", height: "18rem" }}>
                  <div className={`d-flex justify-content-between bg-green py-2 ${"p-2"} text-white mb-0`}>
                    <h6 className="bg-green text-white mb-0">Billing Details</h6>
                    <Tooltip title="View Billing Details">
                      <ControlPointIcon className="cursor" />
                    </Tooltip>
                  </div>
                  <div className={"p-2"}>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Payment Terms</p>
                      <h6 className="mb-0">
                        <b>{"Immediate"}</b>
                      </h6>
                    </div>
                    <div className="hr my-0"></div>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Currency</p>
                      <h6 className="mb-0">
                        <b>{"USD"}</b>
                      </h6>
                    </div>
                    <div className="hr my-0"></div>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Billing Type</p>
                      <h6 className="mb-0">
                        <b>{"Pay Fixed Price"}</b>
                      </h6>
                    </div>
                    <div className="hr my-0"></div>
                    <div className="d-flex justify-content-between py-2">
                      <p className="mb-0">Billing Frequency</p>
                      <h6 className="mb-0">
                        <b>{"One Time"}</b>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="card border p-2">
                <div className="d-flex justify-content-between align-items-baseline">
                  <div>
                    <h4 className="mb-1">Product</h4>
                    <h6 className="text-muted">Your Shipment</h6>
                  </div>
                  <div>
                    <DescriptionIcon /> Download CSV
                  </div>
                </div>
                <Divider className="mb-2" />
                <div className="order-summary-items">
                  <DataGrid
                    rows={orderedItems}
                    columns={columns}
                    disableSelectionOnClick
                    getRowId={(row) => row.partsId}
                    sx={GRID_STYLE}
                    hideFooter={true}
                    autoHeight
                    pagination={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default POOrderProgress;
