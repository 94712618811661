import React from "react";

import LaunchIcon from "@mui/icons-material/Launch";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import Moment from "react-moment";
import { Modal } from "react-bootstrap";

import { currencyFormatter } from "pages/Common/utils/currencyFormatter";
import { LinearProgress } from "@mui/material";

const ProgressBar = ({ progress, styles }) => {
  return (
    <LinearProgress
      variant="determinate"
      value={progress}
      style={{ height: 6, borderRadius: 5, backgroundColor: "#e0e0e0" }}
      sx={{
        "& .MuiLinearProgress-bar": { borderRadius: 5, backgroundColor: "#872ff7", ...styles },
      }}
    />
  );
};

const POSummaryModal = ({ show, hideModal, handleSnack, handleOpenProgressModal, recordId }) => {
  return (
    <>
      <Modal show={show} onHide={hideModal} centered>
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center">
            <h3>PO-0004</h3>
            <p className="po-summary-open-span p-2 border-radius-10 font-weight-500 text-black cursor" onClick={handleOpenProgressModal}>
              <OpenInNewIcon className="mr-1" />
              Open
            </p>
          </div>
          <ProgressBar progress={33} styles={{ backgroundColor: "#397752" }} />
          <div className="d-flex justify-content-between align-items-center mt-3">
            <p className="font-size-18">
              <span className="text-black">{currencyFormatter.format(42150)}</span> billed of {currencyFormatter.format(125000)}
            </p>
            <p className="font-size-18">33%</p>
          </div>

          <div className="row mb-2 font-size-18">
            <div className="col-md-5 col-sm-5 text-left">Status</div>
            <div className="col-md-7 col-sm-7 text-left">
              <span className="po-summary-status-span py-2 px-2 font-size-16 font-weight-500">Open</span>
            </div>
          </div>
          <div className="row mb-2 font-size-18">
            <div className="col-md-5 col-sm-5 text-left">Created</div>
            <div className="col-md-7 col-sm-7 text-left">
              <span className="text-black">
                <Moment format="MMM D, YYYY HH:mmA">{new Date()}</Moment>
              </span>
            </div>
          </div>
          <div className="row mb-2 font-size-18">
            <div className="col-md-5 col-sm-5 text-left">Requester</div>
            <div className="col-md-7 col-sm-7 text-left">
              <span className="text-black">Joshua Barnes</span> (IT)
            </div>
          </div>
          <div className="row mb-2 font-size-18">
            <div className="col-md-5 col-sm-5 text-left">Purchase Request</div>
            <div className="col-md-7 col-sm-7 text-left text-black">Apple (REQ-8)</div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default POSummaryModal;
