import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const WithSpareMaterialAddUpdate = ({ show, hideModal, handleSnack, operationData }) => {
  const title = operationData?.componentCode + "-" + operationData?.componentCodeDescription;
  const [partsData, setPartsData] = useState({
    id: "",
    jobCode: "",
    jobOperation: "",
    description: "",
    pricingMethod: "",
    componentCode: "",
    user: "USER1",
  });
  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        <Modal.Header className="modal-header-border">
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 bg-white">
          <div className="px-3">
            <div className="row mt-2 input-fields">
              <div className="col-md-4 col-sm-4">
                <div class="form-group mt-3">
                  <label className="text-light-dark font-size-12 font-weight-600">JOB CODE</label>
                  <input type="text" disabled class="form-control border-radius-10 text-primary" value={partsData.jobCode} />
                  <div className="css-w8dmq8">*Mandatory</div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div class="form-group mt-3">
                  <label className="text-light-dark font-size-12 font-weight-600">JOB OPERATION</label>
                  <input type="text" disabled class="form-control border-radius-10 text-primary" value={partsData.jobOperation} />
                  <div className="css-w8dmq8">*Mandatory</div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div class="form-group mt-3">
                  <label className="text-light-dark font-size-12 font-weight-600">DESCRIPTION</label>
                  <input type="text" disabled class="form-control border-radius-10 text-primary" value={partsData.description} />
                  <div className="css-w8dmq8">*Mandatory</div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div class="form-group mt-3">
                  <label className="text-light-dark font-size-12 font-weight-600">COMPONENT CODE</label>
                  <input type="text" disabled class="form-control border-radius-10 text-primary" value={partsData.componentCode} />
                  <div className="css-w8dmq8">*Mandatory</div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div class="form-group mt-3">
                  <label className="text-light-dark font-size-12 font-weight-600">USER</label>
                  <input type="text" disabled class="form-control border-radius-10 text-primary" value={partsData.user} />
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div class="form-group mt-3 mb-0 text-right">
                  <button
                    type="button"
                    className="btn border-primary text-primary mr-2"
                    onClick={hideModal}
                    //   onClick={() => populatePartsData(serviceEstimateData, false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn bg-primary text-white"
                    //   onClick={createPartlistAndUpdate}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WithSpareMaterialAddUpdate;
