import React, { useEffect, useState } from "react";

import InfoIcon from "@mui/icons-material/Info";

import { Divider, Tooltip } from "@mui/material";

import Select from "react-select";
import { Modal, ProgressBar } from "react-bootstrap";

import { API_SUCCESS } from "services/ResponseCode";
import { callGetApi, callPostApi, callPutApi } from "services/ApiCaller";
import {
  UPDATE_ORDER_BY_PARTS_QUANTITY_ALLOCATION,
  PARTS_ORDER_MASTER_URL,
  SEARCH_SPAREPART,
  SPARE_PARTS_MASTER_URL_DATA_SVC,
} from "services/CONSTANTS";

import { FONT_STYLE_SELECT } from "pages/Common/constants";
import { partsOrderReqObj, plantByOptions } from "../warehouseConstents";
import LoadingProgress from "pages/Repair/components/Loader";

const AllocateStock = ({
  show,
  hideModal,
  handleSnack,
  recordId,
  partsOrderObj,
  pendingParts,
  setPendingParts,
  handleOpenCreateSTOModal,
  handleOpenCreatePRModal,
  orderStockRecords,
  setOrderStockRecords,
  partOrderRow,
}) => {
  const [orderingPlantBy, setOrderingPlantBy] = useState(plantByOptions[0]);
  const [deliveryPlantBy, setDeliveryPlantBy] = useState(plantByOptions[1]);
  const [orderRecord, setOrderRecord] = useState({ ...partsOrderReqObj });
  const [partsRecord, setPartsRecord] = useState([]);
  const [loading, setLoading] = useState(false);

  const [mouseDownDirection, setMouseDownDirection] = useState(null);

  useEffect(() => {
    if (partsOrderObj && partsOrderObj?.partsQty && (partsOrderObj?.partsQty).length > 0) {
      getOrderPartsDetails(partsOrderObj?.partsQty);
    }
  }, [partsOrderObj]);

  // get parts order id details
  const getOrderPartsDetails = (partsQty = []) => {
    if (partsQty) {
      const rUrl = SEARCH_SPAREPART(`${partsQty.map((item) => `partNumber:${item?.partNumber}`).join(" OR ")}`);

      setLoading(true);

      callGetApi(
        rUrl,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            const partsData = responseData.map((data) => {
              let partsRow = partsQty.find((item) => item?.partNumber === data?.partNumber);

              // const requested = partsRow?.quantity;
              // const avilable = data.availability;

              return {
                ...data,
                availability: data.availability || 0,
                quantity: partsRow?.quantity || 0,
                allocatedQuantity: data.availability < partsRow?.quantity ? Number(data.availability || 0) : Number(partsRow?.quantity || 0),

                // allocatedQuantity:
                //   partsRow?.allocatedQuantity > Number(data.availability || 0) ? partsRow?.allocatedQuantity || 0 : Number(data.availability || 0),
              };
            });
            setPartsRecord(partsData);
            setLoading(false);
          } else {
            setLoading(false);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }
  };

  // allocate incerase/decrease button
  const handleButtonChange = (direction, row) => {
    let next;

    switch (direction) {
      case "up":
        next = row?.allocatedQuantity + (1 || 1);
        break;
      case "down":
        next = row?.allocatedQuantity - (1 || 1) >= 0 ? row?.allocatedQuantity - (1 || 1) : 0;
        break;
      default:
        next = row?.allocatedQuantity;
        break;
    }
    if (next !== 0) {
      updatePartsAllocation(next, row?.partsId);
    }

    // setValue((curr) => {
    //   return `${next}`.length <= max(curr) ? next : curr;
    // });
  };

  // update part allocation
  const updatePartsAllocation = async (value, partsId) => {
    const _parts = partsRecord.map((item) => (item.partsId === partsId ? { ...item, allocatedQuantity: value } : item));
    setPartsRecord(_parts);
  };

  // update the part details
  const handleUpdatePartDetails = async (rObj) => {
    const rUrl = `${SPARE_PARTS_MASTER_URL_DATA_SVC}/${rObj.id}`;
    await callPutApi(
      null,
      rUrl,
      rObj,
      (response) => {
        if (response.status === API_SUCCESS) {
        } else {
        }
      },
      (error) => {}
    );
  };

  // update the order
  const handleUpdateOrder = async (data) => {
    const rUrl = `${PARTS_ORDER_MASTER_URL}/service-cpq/${recordId}`;
    await callPutApi(
      null,
      rUrl,
      data,
      (response) => {
        if (response.status === API_SUCCESS) {
          // handleSnack("Order updated successfully");
          // hideModal();
        } else {
          // handleSnack("Failed to update order");
        }
      },
      (error) => {
        // handleSnack("Failed to update order");
      }
    );
  };

  // click on submit
  const handleSubmit = async () => {
    const rObj = {
      partsQtyAllocations: [],
      partOrderNumber: partsOrderObj?.partOrderNumber,
      orderingPlantName: orderingPlantBy?.value || "",
      deliveringPlantName: deliveryPlantBy?.value || "",
      // partlistId: 0,
      // builderNumber: "",
      repairBuilder: false,
    };
    for (const row of partsRecord) {
      rObj["partsQtyAllocations"].push({
        partNumber: row?.partNumber,
        allocatedQty: row?.allocatedQuantity,
        fullyAllocated: Number(row?.quantity || 0) === Number(row?.availability || 0) ? true : false,
      });
    }

    const rUrl = `${UPDATE_ORDER_BY_PARTS_QUANTITY_ALLOCATION}`;

    callPutApi(
      null,
      rUrl,
      rObj,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setPendingParts(partsRecord);
        } else {
          setPendingParts(partsRecord);
        }
      },
      (error) => {}
    );
    // const _partsRecord = partsRecord
    //   .map((data) => {
    //     if (data?.quantity > Number(data?.availability || 0)) {
    //       return data;
    //     }
    //     return undefined;
    //   })
    //   .filter(Boolean);

    // for (const row of partsRecord) {
    //   try {
    //     const partResult = handleGetPartDetails(row);
    //   } catch (error) {}
    // }

    // await handleUpdateOrder({
    //   ...partOrderRow,
    //   sendToERP: true,
    //   salesOffice: partsRecord.reduce((sum, item) => {
    //     return sum + (item?.allocatedQuantity || 0);
    //   }, 0),
    // });

    // // orderStockRecords,
    // setOrderStockRecords(
    //   orderStockRecords.map((row) =>
    //     row?.partsOrderId === recordId
    //       ? {
    //           ...row,
    //           sendToERP: true,
    //           salesOffice: partsRecord.reduce((sum, item) => {
    //             return sum + (item?.allocatedQuantity || 0);
    //           }, 0),
    //         }
    //       : row
    //   )
    // );
    // handleSnack("info", "The allocation is updated to ERP");
    // if (_partsRecord.length > 0) {
    //   const rowsData = [];
    //   for (const row of _partsRecord) {
    //     try {
    //       let obj = {
    //         shipmentPartNumber: row.partNumber,
    //         shipmentPartDescription: row.partDescription,
    //         cylinderPack: "",
    //         quantity: row.quantity - (row?.allocatedQuantity || 0) || 0,
    //         // quantity: 5,
    //         analysis: "KNOWN_TO_BE_FAULTY",
    //         returnType: "INTRA_COMPANY",
    //         cost: row.listPrice,
    //         partsType: "FAILURE_PARTS",
    //         disposeType: "RECEIVED",
    //         shipmentHeaderId: 0,
    //       };
    //       const result = await handleCreateNewPart(obj, row);
    //       if (result.success) {
    //         rowsData.push(result.data);
    //       }
    //     } catch (error) {}
    //   }
    //   setPendingParts(rowsData);
    // } else {
    //   setPendingParts([]);
    //   hideModal();
    // }
  };

  // create the STO
  const handleCreateSTO = () => {
    const _penditngParts = pendingParts.map((part, i) => ({
      ...part,
      partsNumber: part?.partNumber || "",
      partsDescription: part?.partDescription || "",
      stoPartsId: i + 1,
    }));
    setPendingParts(_penditngParts);
    handleOpenCreateSTOModal();
  };

  // create PR
  const handleCreatePR = () => {
    const _penditngParts = pendingParts.map((part, i) => ({
      ...part,
      partsNumber: part?.partNumber || "",
      partsDescription: part?.partDescription || "",
      prPartsId: i + 1,
    }));
    setPendingParts(_penditngParts);
    handleOpenCreatePRModal();
  };

  // show pending parts Allocation UI
  const pendingPartsAllocate = () => {
    return (
      <>
        <p className="font-size-14">
          {pendingParts.length} Parts quantity is pending, parts available in other plants. Do you want to create STO / PR?
        </p>
        <div className="card border">
          <div className="d-flex justify-content-between align-items-center px-3 mt-2">
            <h5>Product Name</h5>
            <h5>Requested</h5>
            <h5>Pending </h5>
          </div>
          <Divider />
          <div className="allocate-parts">
            {pendingParts.length !== 0 &&
              pendingParts.map((part) => {
                return (
                  <>
                    <div className="row px-3 mt-2" key={part?.partsId}>
                      <div className="col-md-6 col-sm-6">
                        <p className="mb-0">{part?.partDescription}</p>
                        <div className="d-flex align-items-baseline">
                          <span className="mr-2">{part?.partNumber}</span>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2">
                        <p className="text-left">{part?.quantity}</p>
                      </div>
                      <div className="col-md-4 col-sm-4 d-flex" style={{ justifyContent: "right" }}>
                        <p className="text-left">{part?.quantity - part?.allocatedQuantity}</p>
                      </div>
                    </div>
                    <Divider />
                  </>
                );
              })}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-6">
            <button className="btn border-primary text-primary w-100" onClick={handleCreateSTO}>
              Create STO
            </button>
          </div>
          <div className="col-md-6 col-sm-6 w-100">
            <button className="btn border-primary text-primary w-100" onClick={handleCreatePR}>
              Create PR
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <Modal show={show} onHide={hideModal} size="w-75">
      <Modal.Header>
        <Modal.Title>Parts {pendingParts.length === 0 ? "Allocation" : "Pending"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {pendingParts.length === 0 ? (
          <>
            <div className="card border px-3 py-2">
              <div className="d-flex justify-content-between align-items-baseline mb-0">
                <div className="d-block">
                  <h6 className="mb-0 font-weight-500">{partsOrderObj?.customer || "NA"}</h6>
                  <p className="mb-0">{partsOrderObj?.address || ""}</p>
                </div>
                <h5 className="mb-0">#{partsOrderObj?.partOrderNumber}</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-13 font-weight-500">ORDERING PLANT BY</label>
                  <Select options={plantByOptions} value={orderingPlantBy} onChange={(e) => setOrderingPlantBy(e)} styles={FONT_STYLE_SELECT} />
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-13 font-weight-500">DELIVERY PLANT BY</label>
                  <Select options={plantByOptions} value={deliveryPlantBy} onChange={(e) => setDeliveryPlantBy(e)} styles={FONT_STYLE_SELECT} />
                </div>
              </div>
            </div>
            <div className="card border">
              <div className="d-flex justify-content-between align-items-center px-3 mt-2">
                <h5>Product Name</h5>
                <h5>Requested</h5>
                <h5>Allocate</h5>
              </div>
              <Divider />
              <div className="allocate-parts">
                {loading ? (
                  <LoadingProgress />
                ) : (
                  <>
                    {partsRecord.length !== 0 &&
                      partsRecord.map((part) => {
                        return (
                          <>
                            <div className="row px-3 mt-2" key={part?.partsId}>
                              <div className="col-md-6 col-sm-6">
                                <p className="mb-0">{part?.partDescription}</p>
                                <div className="d-flex align-items-baseline">
                                  <span className="mr-2">{part?.partNumber}</span>
                                  <Tooltip title={`Available ${part?.availability} quantities`}>
                                    <InfoIcon /> {part?.availability} Available
                                  </Tooltip>
                                </div>
                              </div>
                              <div className="col-md-2 col-sm-2">
                                <p className="text-left">{part?.quantity}</p>
                              </div>
                              <div className="col-md-4 col-sm-4 d-flex" style={{ justifyContent: "right" }}>
                                <div className="input-number">
                                  <button
                                    onClick={(e) => handleButtonChange("down", part)}
                                    onMouseDown={() => setMouseDownDirection("down", part)}
                                    onMouseOut={() => setMouseDownDirection(null)}
                                    onMouseUp={() => setMouseDownDirection(null)}
                                    disabled={part?.allocatedQuantity < part?.quantity || Number(part?.availability) === 0}
                                    // disabled={part?.allocate === 0}
                                  >
                                    -
                                  </button>
                                  <input type="number" step={1} value={part?.allocatedQuantity} readOnly />
                                  <button
                                    onClick={(e) => handleButtonChange("up", part)}
                                    onMouseDown={() => setMouseDownDirection("up", part)}
                                    onMouseUp={() => setMouseDownDirection(null)}
                                    onMouseOut={() => setMouseDownDirection(null)}
                                    disabled={
                                      part?.allocatedQuantity === Number(part?.availability || 0) ||
                                      part?.allocatedQuantity === part?.quantity ||
                                      Number(part?.availability) === 0
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                              <div className="col-md-12 col-sm-12 mb-0">
                                <div className="d-flex align-items-baseline mt-1">
                                  <ProgressBar
                                    variant={`${
                                      part?.allocatedQuantity === part?.quantity ? "success" : part?.allocatedQuantity === 0 ? "danger" : "warning"
                                    }`}
                                    now={Math.ceil((part?.allocatedQuantity / part?.quantity) * 100)}
                                    className={`${
                                      part?.allocatedQuantity === part?.quantity
                                        ? "custom-in-stock-bar"
                                        : part?.allocatedQuantity === 0
                                        ? "custom-out-of-stock-bar"
                                        : "custom-low-stock-bar"
                                    } mr-2 w-100`}
                                    style={{ height: "8px" }}
                                  />
                                  <h6>
                                    {part?.allocatedQuantity}/{part?.quantity}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <Divider />
                          </>
                        );
                      })}
                  </>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">ADD NOTES (Optional)</label>
                  <textarea
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    // value={claimOrderData?.partNumber}
                    name="addNotes"
                    placeholder="Type a Message"
                    // onChange={handleClaimTabInputTextChange}
                  />
                </div>
              </div>
            </div>
            <Modal.Footer>
              <div className="row mb-0">
                <button className="btn border-primary text-primary mx-2" onClick={hideModal}>
                  Cancel
                </button>
                <button className="btn bg-primary text-white" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </Modal.Footer>
          </>
        ) : (
          pendingPartsAllocate()
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AllocateStock;
