import React, { useState } from "react";

import Select from "react-select";
import { Modal } from "react-bootstrap";
import SearchBox from "pages/Common/SearchBox";
import { customerSearch } from "services/searchServices";

// font color
let fontColor = "#872ff7 !important";

// select Type custom style
const customStyle = {
  control: (styles, { isDisabled }) => {
    return { ...styles, borderRadius: 10, fontSize: 14, color: fontColor };
  },
  singleValue: (styles, { isDisabled }) => {
    return { ...styles, borderRadius: 10, fontSize: 14, fontWeight: 500, color: fontColor };
  },
};

// customer/Partner Type Options
const customerTypeOption = [
  { label: "Partner", value: "P" },
  { label: "Customer", value: "C" },
];

// Roles Options
const rolesOption = [
  { label: "Partner Admin", value: "TENANT_ADMIN" },
  { label: "End Customer", value: "End_Customer" },
];

// record Object
const masterSelectObj = {
  id: 0,
  customerId: "",
  firstName: "",
  lastName: "",
  fullName: "",
  customerType: "",
  businessArea: "",
  distributionChannel: "",
  email: "",
  serviceRecipent: "",
  payer: "",
  shipTo: "",
  billTo: "",
  customerGroup: "",
  customerSegment: "",
  taxRelevant: "",
  currency: "",
  contactType: "",
  contactName: "",
  primaryContact: "",
  contactAddress: "",
  createdBy: "",
  updatedBy: "",
};

// address request object
const addressDTOObj = {
  id: 0,
  addressId: 0,
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  fullAddress: "",
  district: "",
  regionOrState: "",
  country: "",
  createdBy: "",
  updatedBy: "",
};

const CustomerAddUpdate = ({ show, hideModal, handleSnack }) => {
  const [record, setRecord] = useState({
    ...masterSelectObj,
    type: "",
    customerId: "",
    customerName: "",
    customerNumber: "",
    role: "",
    email: "",
    phoneNumber: "",
    address: "",
    city: "",
    state: "",
    country: "",
  });

  const [addressRecord, setAddressRecord] = useState({ ...addressDTOObj });
  const [isCustomerSelect, setIsCustomerSelect] = useState(false);
  const [searchCustResults, setSearchCustResults] = useState([]);
  const [noOptionsCust, setNoOptionsCust] = useState(false);

  const [isAddressDTO, setIsAddressDTO] = useState(false);

  // select the Type Customer/Partner
  const handleSelectType = (e) => {
    let _contactType = "";
    let _customerId = "";
    if (e.value === "P") {
      _contactType = { label: "Partner Admin", value: "TENANT_ADMIN" };
      //   _customerId = loginTenantId;
    } else if (e.value === "C") {
      _contactType = { label: "End Customer", value: "End_Customer" };
      _customerId = "";
    }
    setRecord({ ...record, customerType: e, contactType: _contactType, customerId: _customerId });
    setIsCustomerSelect(false);
  };

  // change input fields value
  const handleInputValueChange = (e) => {
    const { name, value } = e.target;
    setRecord({ ...record, [name]: value });
  };

  // change input fields value
  const handleAddressInputValueChange = (e) => {
    const { name, value } = e.target;
    setAddressRecord({ ...addressRecord, [name]: value });
  };

  // Search Customer with customer ID
  const handleCustSearch = async (searchCustfieldName, searchText) => {
    setSearchCustResults([]);
    record.customerId = searchText;
    record.id = "";
    record.firstName = "";
    record.lastName = "";
    record.fullName = "";
    record.email = "";
    record.primaryContact = "";

    setAddressRecord({ ...addressDTOObj });

    if (searchText) {
      await customerSearch(searchCustfieldName + "~" + searchText)
        .then((result) => {
          if (result && result.length > 0) {
            setSearchCustResults(result);
            setNoOptionsCust(false);
          } else {
            setNoOptionsCust(true);
          }
        })
        .catch((e) => {
          handleSnack("error", "Error occurred while searching the customer!");
        });
    }
  };

  // Select the customer from search result
  const handleCustSelect = (type, currentItem) => {
    setRecord({
      ...record,
      id: currentItem.id,
      customerId: currentItem.customerId,
      firstName: currentItem.firstName,
      lastName: currentItem.lastName,
      // customerName: currentItem.fullName,
      email: currentItem.email,
      primaryContact: currentItem.primaryContact,
      fullName: currentItem.fullName,
    });
    setIsAddressDTO(false);
    if (currentItem["addressDTO"]) {
      setIsAddressDTO(true);
      setAddressRecord(currentItem.addressDTO);
    }
    setIsCustomerSelect(true);
    setSearchCustResults([]);
  };

  // click on submit button
  const handleSubmit = () => {
    hideModal();
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        <Modal.Header className="modal-header-border">
          <Modal.Title>Add Customer/Partner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card border px-3">
            <div className="row input-fields mt-3">
              <div className="col-md-4 col-sm-4">
                <div class="form-group w-100">
                  <label className="text-light-dark font-size-12 font-weight-500">TYPE</label>
                  <Select
                    onChange={handleSelectType}
                    styles={customStyle}
                    getOptionLabel={(option) => `${option.label}`}
                    value={record.customerType}
                    options={customerTypeOption}
                  />
                  <div className="css-w8dmq8">*Mandatory</div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div class="form-group w-100">
                  <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER ID</label>
                  <SearchBox
                    value={record.customerId}
                    onChange={(e) => handleCustSearch("customerId", e.target.value)}
                    type="customerId"
                    result={searchCustResults}
                    onSelect={handleCustSelect}
                    noOptions={noOptionsCust}
                    placeholder="Customer Id"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER NAME</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="customerName"
                    placeholder="Customer Name"
                    value={record?.fullName}
                    onChange={handleInputValueChange}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div class="form-group w-100">
                  <label className="text-light-dark font-size-14 font-weight-500">EMAIL</label>
                  <input
                    type="email"
                    name="email"
                    value={record?.email}
                    onChange={handleInputValueChange}
                    className="form-control border-radius-10 text-primary"
                    placeholder="Email Address"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div class="form-group w-100">
                  <label className="text-light-dark font-size-14 font-weight-500">WEBSITE</label>
                  <input
                    type="text"
                    name="website"
                    value={record?.website}
                    onChange={handleInputValueChange}
                    className="form-control border-radius-10 text-primary"
                    placeholder="Website"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="card border px-3">
            <div className="row input-fields mt-3">
              <div className="col-md-12 col-sm-12">
                <div class="form-group w-100">
                  <label className="text-light-dark font-size-14 font-weight-500">ADDRESS</label>
                  <input
                    type="text"
                    name="fullAddress"
                    // value={addressRecord.fullAddress}
                    // onChange={handleAddressInputValueChange}
                    className="form-control border-radius-10 text-primary"
                    placeholder="Addreess"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div class="form-group w-100">
                  <label className="text-light-dark font-size-14 font-weight-500">DISTRICT</label>
                  <input
                    type="text"
                    name="district"
                    // value={addressRecord.district}
                    // onChange={handleAddressInputValueChange}
                    className="form-control border-radius-10 text-primary"
                    placeholder="District"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div class="form-group w-100">
                  <label className="text-light-dark font-size-14 font-weight-500">REGION/STATE</label>
                  <input
                    type="text"
                    name="regionOrState"
                    // value={addressRecord.regionOrState}
                    // onChange={handleAddressInputValueChange}
                    className="form-control border-radius-10 text-primary"
                    placeholder="Region/State"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div class="form-group w-100">
                  <label className="text-light-dark font-size-14 font-weight-500">COUNTRY</label>
                  <input
                    type="text"
                    name="country"
                    // value={addressRecord.country}
                    // onChange={handleAddressInputValueChange}
                    className="form-control border-radius-10 text-primary"
                    placeholder="Country"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-6 col-sm-6">
              <button className="btn border-primary text-primary w-100" onClick={hideModal}>
                Cancel
              </button>
            </div>
            <div className="col-md-6 col-sm-6">
              <button className="btn bg-primary text-white w-100" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CustomerAddUpdate;
