import React, { useEffect, useState } from "react";

import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faPen } from "@fortawesome/free-solid-svg-icons";

import Pagination from "@mui/material/Pagination";
import { Divider, PaginationItem, Stack, Tooltip } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Switch from "@mui/material/Switch";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import FormGroup from "@mui/material/FormGroup";
import { FormControlLabel } from "@material-ui/core";

import $ from "jquery";
import Select from "react-select";
import SelectFilter from "react-select";

import ImageZoom from "react-image-zoom";

import { callGetApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { Get_Spare_Parts_Datails_By_Id_GET } from "services/CONSTANTS";
import {
  SEARCH_FLAG_PARTS,
  SPARE_PARTS_ALTERNATE_PARTS_DETAILS,
  SPARE_PARTS_PRICE_DETAILS,
  SPARE_PARTS_REMAN_OR_REFURB_DETAILS,
  SPARE_PARTS_REPLACED_BY_DETAILS,
  SPARE_PARTS_WARRENTY_DETAILS,
} from "pages/MasterData/equipmentMasterConstants";
import { isEmpty } from "pages/Common/textUtilities";
import EquipmentSearchComponent from "pages/MasterData/EquipmentSearchComponent";
import { GRID_STYLE } from "pages/Common/constants";
import LoadingProgress from "pages/Repair/components/Loader";
import PartsReportDetails from "pages/MasterData/SparePartsMaster/PartsReportDetails";
import { currencyFormatter } from "pages/Common/utils/currencyFormatter";
import { sparePartSearch } from "services/searchServices";
import CustomizedSnackbar from "pages/Common/CustomSnackBar";
import ImageViewer from "pages/Common/utils/ImageViewer";
import PartsSeachMaster from "../PartsSeachMaster";
import PartsSearchList from "../PartsSearchList";
import { sparePartRequestObj } from "pages/Common/RequestObjConstant";
import { uploadPartImage } from "services/fileUploadService";
import PartImageFilesModal from "pages/MasterData/SparePartsMaster/PartImageFilesModal";
import FileUploaderModal from "pages/Common/utils/FileUploaderModal";
import PartImageViewModal from "pages/MasterData/SparePartsMaster/PartImageViewModal";
import PartAddUpdateModal from "pages/MasterData/SparePartsMaster/PartAddUpdateModal";

const label = { inputProps: { "aria-label": "Switch demo" } };

const partsPriceData = {
  groupNumber: "3620566",
  type: "reman",
  partNumber: "OR6158",
  salesUnit: "PC",
  quantity: "1.00",
  price: "498.00",
  currency: "USD",
  validFrom: "44481",
  validTo: "45291",
};

const imgZoomProps = {
  // width: 300,
  // height: 300,
  width: 225,
  height: 225,
  zoomWidth: 175,
  // zoomWidth: 200,
  // img: "../assets/images/spare-parts.png",
  zoomPosition: "original",
  // zoomPosition: "left",
  zoomLensStyle: {
    opacity: "0.7",
  },
};

const ConstructionMaterialMaster = () => {
  const [partRecord, setPartRecord] = useState({ ...sparePartRequestObj });
  const [searchPartList, setSearchPartList] = useState([]);
  const [selectedPart, setSelectedPart] = useState(null);
  const [viewDetails, setViewDetails] = useState(false);

  const [bundleItems, setBundleItems] = useState([
    {
      replacedBy: "OR6159",
      quantity: "2.00",
      availability: "Not available",
      totalAvailability: "0",
      salesUnit: "PC",
      price: "0",
      id: 1,
    },
  ]);

  const [openPartsImgUploadModal, setOpenPartsImgUploadModal] = useState(false);
  const [openFileUploadModal, setOpenFileUploadModal] = useState(false);
  const [openViewFileModal, setOpenViewFileModal] = useState(false);
  const [selectedPartImg, setSelectedPartImg] = useState(null);
  const [partsImages, setPartsImages] = useState([]);

  const [showPartsAddModal, setShowPartsAddModal] = useState(false);

  const [dummyalternateRefurb, setDummyalternateRefurb] = useState([
    {
      itemName: "OR6159",
      quantity: "2.00",
      itemDescription: "Not available",
      itemHeaderStrategy: "N/A",
      taskType: "PC",
      recommendedValue: "36",
      id: 1,
    },
  ]);

  const [partsPriceDetails, setPartsPriceDetails] = useState({ ...partsPriceData });
  const [partsERPPriceDetails, setPartsERPPriceDetails] = useState([
    {
      erpCondition: "C12345",
      amount: "498",
      costPrice: "335",
      margin: "890",
      lastPricedDate: "12-10-2021",
      priceChangeDate: "12-10-2021",
    },
  ]);
  const [showModal, setShowModal] = useState(false);
  const [modelHeaderTitle, setModelHeaderTitle] = useState("");
  const [modelContentReportType, setModelContentReportType] = useState("");
  const [modelContentReportObj, setModelContentReportObj] = useState(null);

  const [warrentyItems, setWarrentyItems] = useState([
    {
      warrantyType: "Materials",
      warrentyDuration: "6 Months",
      dateOfSale: "12-11-2022",
      dateOfInstallation: "N/A",
      id: "1",
    },
  ]);
  const [searchList, setSearchList] = useState([]);

  const [selectedPartsId, setSelectedPartsId] = useState(null);
  const [value, setValue] = React.useState("1");

  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showReplacedBy, setShowReplacedBy] = useState(true);
  const [showAlternateParts, setShowAlternateParts] = useState(true);
  const [showRefurbOption, setShowRefurbOptions] = useState(true);

  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  useEffect(() => {
    if (!showModal) {
      setModelHeaderTitle("");
      setModelContentReportType("");
      setModelContentReportObj(null);
    }
  }, [showModal]);

  useEffect(() => {
    setSelectedPartsId(null);
  }, [searchList]);

  //pagination code
  const [displayedPages, setDisplayedPages] = useState([1, 2, 3]);
  const totalPage = 4;
  // const[totalPage,setTotalPage]= useState(4);
  useEffect(() => {
    const calculateDisplayedPages = () => {
      if (pageNo === 1) {
        setDisplayedPages([1, 2, 3]);
      } else if (pageNo === totalPage) {
        setDisplayedPages([totalPage - 2, totalPage - 1, totalPage]);
      } else {
        setDisplayedPages([pageNo - 1, pageNo, pageNo + 1]);
      }
    };

    calculateDisplayedPages();
  }, [pageNo, totalPage]);

  // handle Page change
  const handlePageChange = (event, value) => {
    setPageNo(value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleShowReplaceBy = () => {
    setShowReplacedBy(!showReplacedBy);
  };

  const handleShowAlternateParts = () => {
    setShowAlternateParts(!showAlternateParts);
  };

  const handleShowRefurbOptions = () => {
    setShowRefurbOptions(!showRefurbOption);
  };

  //updatedreplpacedItemColumns
  const replacedItemColumns = [
    {
      field: "replacedBy",
      headerName: "Replaced By",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Replaced Quantity",
      flex: 1,
    },
    {
      field: "availability",
      headerName: "Availability",
      flex: 1,
    },
    {
      field: "totalAvailability",
      headerName: "Total Available",
      flex: 1,
    },
    {
      field: "salesUnit",
      headerName: "Sales Unit",
      flex: 1,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor" onClick={() => handleShowReportDetails("Replaced By", SPARE_PARTS_REPLACED_BY_DETAILS, params.row)}>
                <Tooltip title="Edit">
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="Delete">
                  <DeleteOutlineOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  //updated  alternate parts columns
  const alternateItemColumns = [
    {
      field: "itemName",
      headerName: "Alternte Material #",
      flex: 1,
    },
    {
      field: "itemDescription",
      headerName: "Quantity",
      flex: 1,
    },
    {
      field: "itemHeaderStrategy",
      headerName: "Availability",
      flex: 1,
    },
    {
      field: "taskType",
      headerName: "Total Available",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Sales Unit",
      flex: 1,
    },
    {
      field: "recommendedValue",
      headerName: "Price",
      flex: 1,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor" onClick={() => handleShowReportDetails("Alternate Materials", SPARE_PARTS_ALTERNATE_PARTS_DETAILS, params.row)}>
                <Tooltip title="Edit">
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="Delete">
                  <DeleteOutlineOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  // updatedremanItemColumns
  const remanItemColumns = [
    {
      field: "itemName",
      headerName: "Alternte Material #",
      flex: 1,
    },
    {
      field: "itemDescription",
      headerName: "Quantity",
      flex: 1,
    },
    {
      field: "itemHeaderStrategy",
      headerName: "Availability",
      flex: 1,
    },
    {
      field: "taskType",
      headerName: "Total Available",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Sales Unit",
      flex: 1,
    },
    {
      field: "recommendedValue",
      headerName: "Price",
      flex: 1,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <div
                className="cursor"
                onClick={() => handleShowReportDetails("Reman or Refurb Options", SPARE_PARTS_REMAN_OR_REFURB_DETAILS, params.row)}
              >
                <Tooltip title="Edit">
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="Delete">
                  <DeleteOutlineOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  //Price itemColumns
  const priceItemColumns = [
    {
      field: "groupNumber",
      headerName: "Group#",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "partNumber",
      headerName: "Material#",
      flex: 1,
    },
    {
      field: "salesUnit",
      headerName: "Sales Unit",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
    },
    {
      field: "validFrom",
      headerName: "Valid From",
      flex: 1,
    },
    {
      field: "validTo",
      headerName: "Valid To",
      flex: 1,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor" onClick={() => handleShowReportDetails("Price Details", SPARE_PARTS_PRICE_DETAILS, params.row)}>
                <Tooltip title="Edit">
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="Delete">
                  <DeleteOutlineOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  //new erp Details column
  const erpDetailsItemColumns = [
    {
      field: "erpCondition",
      headerName: "ERP Condition",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "ERP Amount",
      flex: 1,
    },
    {
      field: "costPrice",
      headerName: "ERP Cost Price",
      flex: 1,
    },
    {
      field: "margin",
      headerName: "ERP Margin",
      flex: 1,
    },
    {
      field: "lastPricedDate",
      headerName: "Last Priced Date",
      flex: 1,
    },
    {
      field: "priceChangeDate",
      headerName: "Price Change Date",
      flex: 1,
    },
  ];

  const warrentyItemColumns = [
    {
      field: "warrantyType",
      headerName: "Warranty Type",
      flex: 1,
    },
    {
      field: "warrentyDuration",
      headerName: "Warranty Duration",
      //   width: 90,
      flex: 1,
    },
    {
      field: "dateOfSale",
      headerName: "Date of Sale",
      //   width: 90,
      flex: 1,
    },
    {
      field: "dateOfInstallation",
      headerName: "Date of Installation",
      flex: 1,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor" onClick={() => handleShowReportDetails("Warranty Details", SPARE_PARTS_WARRENTY_DETAILS, params.row)}>
                <Tooltip title="Edit">
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="Delete">
                  <DeleteOutlineOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  // show file upload modal
  const handleShowFileUploadModal = () => {
    setOpenFileUploadModal(!openFileUploadModal);
    setOpenPartsImgUploadModal(!openPartsImgUploadModal);
  };

  // upload filee modal
  const handleUploadFile = async (imgSrc) => {
    if (!imgSrc) {
      handleSnack("error", "No image source provided");
      return;
    }

    // Check if imgSrc is a valid File object
    if (!(imgSrc instanceof File)) {
      handleSnack("error", "provide file is not a File object");
      return;
    }

    const form = new FormData();
    form.append("file", imgSrc, imgSrc.name);

    // Define the API endpoint
    await uploadPartImage(partRecord?.id, form)
      .then((result) => {
        if (result) {
          // docSignedUrl
          const _claimFilesRecords = [...partsImages];
          _claimFilesRecords.push({
            id: _claimFilesRecords.length + 1,
            fileName: `image${_claimFilesRecords.length + 1}.png`,
            uploadDate: "01/01/2024",
            author: "User",
            // author: "Makino",
            fileType: "IMAGE",
            category: "IMAGE",
            imgSrc: result?.docSignedUrl,
          });
          setPartsImages(_claimFilesRecords);
          handleShowFileUploadModal();
        }
      })
      .catch((err) => {
        handleSnack("error", `Failed to upload the part Image!`);
      });
  };

  // open view file Modal
  const handleViewFileModal = (row) => {
    setSelectedPartImg(row.imgSrc);
    setOpenPartsImgUploadModal(!openPartsImgUploadModal);
    setOpenViewFileModal(!openViewFileModal);
  };

  // close view file Modal
  const handleCloseViewFileModal = () => {
    setSelectedPartImg("");
    setOpenPartsImgUploadModal(!openPartsImgUploadModal);
    setOpenViewFileModal(!openViewFileModal);
  };

  // view select search parts details
  const handleViewPartDetails = (id) => {
    setLoading(true);
    setSelectedPart(id);
    setPageNo(1);
    const rUrl = Get_Spare_Parts_Datails_By_Id_GET + id;
    callGetApi(
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setPartRecord(responseData);
          if (responseData.sparepartImages && responseData.sparepartImages.length > 0) {
            let imagesSrc = responseData.sparepartImages.map((imgSrc, i) => ({
              id: i + 1,
              fileName: `image${i + 1}.png`,
              uploadDate: "01/01/2024",
              author: "User",
              // author: "Makino",
              fileType: "IMAGE",
              category: "IMAGE",
              imgSrc: imgSrc || "../assets/images/partDefaultImage.png",
            }));
            setPartsImages(imagesSrc);
          } else {
            setPartsImages([]);
          }
          setViewDetails(true);
          // setSelectedPartsId(id);
          // setSelectedPartsDetails(responseData);
          setPartsPriceDetails((prevState) => ({
            ...prevState,
            groupNumber: responseData.groupNumber,
            type: responseData.partType,
            price: responseData.listPrice,
            salesUnit: responseData.salesUnit,
            currency: responseData.currency,
            partNumber: responseData.partNumber,
          }));
          setLoading(false);
        } else {
          setViewDetails(false);
          setLoading(false);
        }
      },
      (error) => {
        setViewDetails(false);
        setLoading(false);
      }
    );
  };

  // view the detais for data table row
  const handleShowReportDetails = (title, reportType, row) => {
    setModelHeaderTitle(title);
    setModelContentReportType(reportType);
    setModelContentReportObj(row);
    setShowModal(true);
  };

  // show Parts Add Modal
  const handleShowPartAddModal = () => {
    setShowPartsAddModal(true);
  };

  // Materials page 1 details
  const viewDetailsPage_1 = () => {
    return (
      <>
        <div className="bg-white px-2 pt-3 border-radius-10 overflow-hidden">
          <div className="row align-items-center mt-2">
            <div className="col-lg-8 col-md-8 col-sm-8">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="d-block">
                    <p className="text-light-60 font-size-12 m-0 font-weight-500">DESCRIPTION</p>
                    <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                      {partRecord?.partDescription + " " + partRecord?.modelGroupDescription}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="d-block">
                    <p className="text-light-60 font-size-12 m-0 font-weight-500">TYPE</p>
                    <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                      {isEmpty(partRecord?.partType) ? "NA" : partRecord?.partType}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                  <div className="d-block">
                    <p className="text-light-60 font-size-12 m-0 font-weight-500">MANUFACTURER</p>
                    <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                      {isEmpty(partRecord?.manufacturer) ? "NA" : partRecord?.manufacturer}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3 ">
                  <div className="d-block">
                    <p className="text-light-60 font-size-12 m-0 font-weight-500">MODEL</p>
                    <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                      {isEmpty(partRecord?.model) ? "NA" : partRecord?.model}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                  <p className="text-light-60 font-size-12 m-0 font-weight-500">GROUP NUMBER</p>
                  <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                    {isEmpty(partRecord?.groupNumber) ? "NA" : partRecord?.groupNumber}
                  </p>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                  <div className="d-block">
                    <p className="text-light-60 font-size-12 m-0 font-weight-500">PARTS GROUP</p>
                    <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                      {isEmpty(partRecord?.partsGroup) ? "NA" : partRecord?.partsGroup}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                  <div className="d-block">
                    <p className="text-light-60 font-size-12 m-0 font-weight-500">BEC CODE</p>
                    <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                      {isEmpty(partRecord?.becCode) ? "NA" : partRecord?.becCode}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                  <p className="text-light-60 font-size-12 m-0 font-weight-500">BEC CODE DESCRIPTION</p>
                  <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                    {isEmpty(partRecord?.becCodeDescription) ? "NA" : partRecord?.becCodeDescription}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
              <ImageZoom
                {...imgZoomProps}
                img={
                  partRecord?.sparepartImages && partRecord?.sparepartImages.length > 0
                    ? partRecord?.sparepartImages[0] || "../assets/images/partDefaultImage.png"
                    : "../assets/images/partDefaultImage.png"
                }
              />
            </div>
          </div>
          <div className="row align-items-end mt-1">
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">SERIAL NUMBER (IF ANY)</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                  {isEmpty(partRecord?.serialNo) ? "NA" : partRecord?.serialNo}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">STATUS</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                  {isEmpty(partRecord?.status) ? "NA" : partRecord?.status}
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const viewERPDetails = () => {
    return (
      <>
        <h5 className="font-weight-500 mt-4">ERP DETAILS</h5>
        <div className="bg-white px-3 py-2 border-radius-10 mt-2 mb-5">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">MATERIAL GROUP</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                {/* {isEmpty(partRecord?.materialGroup)
                      ? "NA"
                      : partRecord?.materialGroup} */}
                AA: 0S1619
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">MATERIAL NUMBER</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                {/* {isEmpty(partRecord?.erpMaterialNumber)
                      ? "NA"
                      : partRecord?.erpMaterialNumber} */}
                AA: 0S1619
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">OLD MATERIAL NUMBER</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                {/* {isEmpty(partRecord?.oldMaterialNumber)
                      ? "NA"
                      : partRecord?.manufacturer} */}
                AA: 0S1619
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">AVERAGE COST</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                {/* {isEmpty(partRecord?.costPrice)
                      ? 0
                      : partRecord?.costPrice} */}
                $ 90534
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">AVAILABILITY</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                {/* {isEmpty(partRecord?.availability)
                      ? "NA"
                      : partRecord?.availability} */}
                Stock
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">TOTAL NUMBER AVAILABLE</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                {/* {isEmpty(partRecord?.totalAvailability)
                      ? "NA"
                      : partRecord?.totalAvailability} */}
                10
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">STATUS</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                {/* {isEmpty(partRecord?.status)
                      ? "NA"
                      : partRecord?.status} */}
                Active
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };

  // Materials page 2 details
  const viewDetailsPage_2 = () => {
    return (
      <>
        <h5 className="font-weight-500 mt-4 px-2">Substitute Details</h5>
        <div className="d-flex align-items-center mt-4 px-2">
          <h6 className="m-0 mr-2 font-weight-600">Replaced By</h6>
          <Switch {...label} checked={showReplacedBy} onChange={handleShowReplaceBy} size="small" />
        </div>
        {showReplacedBy && (
          <div className="px-2">
            <div className="bg-white p-3 border-radius-10 mt-3 overflow-hidden">
              <div className="row align-items-center mb-3">
                <div className="col-lg-9 col-md-9">
                  <div className="d-flex align-items-center">
                    <h6 className="font-weight-500 mb-0 mr-3">Replaced By</h6>
                    <EquipmentSearchComponent searchPlaceholder={"Replaced By"} />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 text-right">
                  <a href="#" className="btn bg-primary text-white">
                    <span className="mr-1">
                      <AddIcon />
                    </span>
                    Upload
                  </a>
                </div>
              </div>
              <div style={{ height: 180, width: "100%" }}>
                <DataGrid sx={GRID_STYLE} rows={bundleItems} columns={replacedItemColumns} getRowId={(row) => row.id} pageSize={5} />
              </div>
            </div>
          </div>
        )}

        <div className="d-flex align-items-center mt-4 px-2">
          <h6 className="m-0 mr-2 font-weight-600">Alternate Materials</h6>
          <Switch {...label} checked={showAlternateParts} onChange={handleShowAlternateParts} size="small" />
        </div>

        {showAlternateParts && (
          <div className="px-2">
            <div className="bg-white p-3 border-radius-10 mt-3 overflow-hidden">
              <div className="row align-items-center mb-3">
                <div className="col-lg-9 col-md-9">
                  <div className="d-flex align-items-center">
                    <h6 className="font-weight-500 mb-0 mr-3">Alternate Materials</h6>
                    <EquipmentSearchComponent searchPlaceholder={"Alternate Materials"} />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 text-right">
                  <a href="#" className="btn bg-primary text-white">
                    <span className="mr-1">
                      <AddIcon />
                    </span>
                    Upload
                  </a>
                </div>
              </div>
              <div style={{ height: 180, width: "100%" }}>
                <DataGrid sx={GRID_STYLE} rows={dummyalternateRefurb} columns={alternateItemColumns} getRowId={(row) => row.id} pageSize={5} />
              </div>
            </div>
          </div>
        )}

        <div className="d-flex align-items-center mt-4 px-2">
          <h6 className="m-0 mr-2 font-weight-600">Reman or Refurb Option</h6>
          <Switch {...label} checked={showRefurbOption} onChange={handleShowRefurbOptions} size="small" />
        </div>

        {showRefurbOption && (
          <div className="px-2">
            <div className="bg-white p-3 border-radius-10 mt-3 overflow-hidden">
              <div className="row align-items-center mb-3">
                <div className="col-lg-9 col-md-9">
                  <div className="d-flex align-items-center">
                    <h6 className="font-weight-500 mb-0 mr-3">Reman or Refurb Option</h6>
                    <EquipmentSearchComponent searchPlaceholder={"Reman or Refurb Options"} />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 text-right">
                  <a href="#" className="btn bg-primary text-white">
                    <span className="mr-1">
                      <AddIcon />
                    </span>
                    Upload
                  </a>
                </div>
              </div>
              <div style={{ height: 180, width: "100%" }}>
                <DataGrid sx={GRID_STYLE} rows={dummyalternateRefurb} columns={remanItemColumns} getRowId={(row) => row.id} pageSize={5} />
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  // Materials page 3 details
  const viewDetailsPage_3 = () => {
    return (
      <Box className="mt-3" sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Price" value="1" />
              <Tab label="Discount" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" className="px-0">
            <div className="bg-white p-3 border-radius-10">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 master-input-fields">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">Select a combination</label>
                    <Select
                      className="text-primary"
                      // value={generalComponentData.customerSegment}
                      // options={customerSegmentKeyValue}
                      placeholder="Customer Segment"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500" htmlFor="exampleInputEmail1">
                      Enter respective values
                    </label>
                    <input className="form-control border-light-blue text-primary border-radius-10" placeholder="Marine" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500" htmlFor="exampleInputEmail1">
                      Select Price Method
                    </label>
                    <input className="form-control border-light-blue border-radius-10 text-primary" placeholder="List Price" />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white p-3 border-radius-10 mt-3 overflow-hidden">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h6 className="font-weight-600 mb-0 mr-3">Price Details</h6>
                <a className="btn cursor bg-primary text-white">Add New</a>
              </div>
              <div style={{ height: 180, width: "100%" }}>
                <DataGrid sx={GRID_STYLE} rows={[partsPriceDetails]} columns={priceItemColumns} getRowId={(row) => row.partNumber} pageSize={5} />
              </div>
            </div>
            <h6 className="font-weight-500 pl-2 mt-5">ERP Price</h6>
            <div className="bg-white p-3 border-radius-10 mt-3 overflow-hidden">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h6 className="font-weight-600 mb-0 mr-3">ERP Details</h6>
                <a className="btn cursor bg-primary text-white">Add New</a>
              </div>
              <div style={{ height: 180, width: "100%" }}>
                <DataGrid
                  sx={GRID_STYLE}
                  rows={partsERPPriceDetails}
                  columns={erpDetailsItemColumns}
                  getRowId={(row) => row.erpCondition}
                  pageSize={5}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value="2" className="px-0">
            <div className="bg-white p-3 border-radius-10">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 master-input-fields">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">Select a combination</label>
                    <Select
                      className="text-primary"
                      // value={generalComponentData.customerSegment}
                      // options={customerSegmentKeyValue}
                      placeholder="Customer Segment"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500" htmlFor="exampleInputEmail1">
                      Enter respective values
                    </label>
                    <input className="form-control border-light-blue text-primary border-radius-10" placeholder="Marine" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500" htmlFor="exampleInputEmail1">
                      Select Price Method
                    </label>
                    <input className="form-control border-light-blue border-radius-10 text-primary" placeholder="List Price" />
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white p-3 border-radius-10 mt-3 overflow-hidden">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h6 className="font-weight-600 mb-0 mr-3">Price Details</h6>
                <a className="btn cursor bg-primary text-white">Add New</a>
              </div>
              <div style={{ height: 180, width: "100%" }}>
                <DataGrid sx={GRID_STYLE} rows={[partsPriceDetails]} columns={priceItemColumns} getRowId={(row) => row.partNumber} pageSize={5} />
              </div>
            </div>
            <h6 className="font-weight-500 pl-2 mt-5">ERP Price</h6>

            <div className="bg-white p-3 border-radius-10 mt-3 overflow-hidden">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h6 className="font-weight-600 mb-0 mr-3">ERP Details</h6>
                <a className="btn cursor bg-primary text-white">Add New</a>
              </div>
              <div style={{ height: 180, width: "100%" }}>
                <DataGrid
                  sx={GRID_STYLE}
                  rows={partsERPPriceDetails}
                  columns={erpDetailsItemColumns}
                  getRowId={(row) => row.erpCondition}
                  pageSize={5}
                />
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    );
  };

  // Materials page 4 details
  const viewDetailsPage_4 = () => {
    return (
      <>
        <h5 className="font-weight-500 mt-5 px-2">Warranty</h5>
        <div className="px-2">
          <div className="bg-white p-3 border-radius-10 mt-3 overflow-hidden">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h6 className="font-weight-600 mb-0 mr-3">Warranty Details</h6>
              <a className="btn cursor bg-primary text-white">Add New</a>
            </div>
            <div style={{ height: 180, width: "100%" }}>
              <DataGrid sx={GRID_STYLE} rows={warrentyItems} columns={warrentyItemColumns} getRowId={(row) => row.id} pageSize={5} />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />

      <div className="content-body" style={{ minHeight: "884px" }}>
        <div className="container-fluid">
          <h5 className="font-weight-600 mb-0 mt-3">Material Master</h5>
          <p className="mb-1 mt-4 font-size-12">Select the search criteria for equipment</p>
          <PartsSeachMaster
            flagType="Material"
            setSelectedPart={setSelectedPart}
            setViewDetails={setViewDetails}
            setSearchPartList={setSearchPartList}
            handleSnack={handleSnack}
            showAddBtn={true}
            handleOpenModal={handleShowPartAddModal}
          />
          <div className="row mt-3">
            {searchPartList.length !== 0 && (
              <>
                <div className="col-xl-4 col-lg-5 col-md-12 col-sm-12 border-50">
                  <PartsSearchList searchPartList={searchPartList} selectedPart={selectedPart} handleViewPartDetails={handleViewPartDetails} />
                </div>
              </>
            )}
            <div className="col-xl-8 col-lg-7 col-md-12 col-sm-12 equipment-master-chart mt-custom">
              {loading ? (
                <LoadingProgress />
              ) : (
                <>
                  {viewDetails && (
                    <>
                      <div className="bg-white p-3 border-radius-10 ">
                        <div className="d-flex align-items-center justify-content-between equipment-pagination px-2">
                          <h5 className="font-weight-600 mb-0">{!isEmpty(partRecord?.partDescription) && partRecord?.partDescription}</h5>
                          <Stack spacing={2}>
                            <Pagination
                              count={totalPage}
                              page={pageNo}
                              onChange={handlePageChange}
                              shape="rounded"
                              renderItem={(item) => displayedPages.includes(item.page) && <PaginationItem {...item} />}
                            />
                          </Stack>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-3 px-2">
                          <div className="d-block ">
                            <h6 className="text-primary font-weight-600">{!isEmpty(partRecord?.partNumber) && partRecord?.partNumber}</h6>
                            <p className="text-light-60 font-size-12 mb-0">{!isEmpty(partRecord?.model) && partRecord?.model}</p>
                          </div>
                          <div className="d-flex align-items-center mx-1">
                            <Tooltip title="Price">
                              <h5 className="text-red font-weight-600 mb-0">{currencyFormatter.format(partRecord?.listPrice || 0)}</h5>
                            </Tooltip>
                            <Tooltip title="Upload Image">
                              <span className="upload-icon-button cursor mx-2" onClick={() => setOpenPartsImgUploadModal(true)}>
                                <UploadOutlinedIcon sx={{ fontSize: 45 }} />
                                {/* <FontAwesomeIcon icon={faUpload} style={{ fontSize: "20px" }} /> */}
                              </span>
                            </Tooltip>
                            <Tooltip title="Edit">
                              <span className="upload-icon-button cursor">
                                <EditOutlinedIcon sx={{ fontSize: 40 }} />
                                {/* <FontAwesomeIcon icon={faPen} style={{ fontSize: "20px" }} /> */}
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                        <Divider sx={{ marginTop: 1 }} />
                        {pageNo === 1 && viewDetailsPage_1()}
                      </div>
                      {pageNo === 1 && viewERPDetails()}
                      {pageNo === 2 && viewDetailsPage_2()}
                      {pageNo === 3 && viewDetailsPage_3()}
                      {pageNo === 4 && viewDetailsPage_4()}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <PartsReportDetails
          show={showModal}
          hideModal={() => setShowModal(false)}
          headerTitle={modelHeaderTitle}
          contentReportType={modelContentReportType}
          contetntReportObj={modelContentReportObj}
        />
      )}

      {openPartsImgUploadModal && (
        <PartImageFilesModal
          show={openPartsImgUploadModal}
          hideModal={() => setOpenPartsImgUploadModal(false)}
          filesRecords={partsImages}
          setFilesRecords={setPartsImages}
          handleShowFileUploadModal={handleShowFileUploadModal}
          handleViewFileModal={handleViewFileModal}
        />
      )}

      {openFileUploadModal && (
        <FileUploaderModal
          show={openFileUploadModal}
          hideModal={handleShowFileUploadModal}
          handleSnack={handleSnack}
          uploadFile={true}
          handleUploadFile={handleUploadFile}
        />
      )}

      {openViewFileModal && <PartImageViewModal show={openViewFileModal} src={selectedPartImg} hideModal={handleCloseViewFileModal} />}

      {showPartsAddModal && (
        <PartAddUpdateModal show={showPartsAddModal} hideModal={() => setShowPartsAddModal(false)} handleSnack={handleSnack} title={"Material"} />
      )}
    </>
  );
};

export default ConstructionMaterialMaster;
