import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  Box,
  Tab,
  Radio,
  RadioGroup,
  Tooltip,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  TextareaAutosize,
  Card,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Divider,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import TabPanel from "@mui/lab/TabPanel";
import {
  faArrowRight,
  faCloudUploadAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import folderaddIcon from "../../assets/icons/svg/folder-add.svg";
import smalldeleteicon from "../../assets/icons/png/small-delete.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { faFileAlt, faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { FileUploader } from "react-drag-drop-files";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Users } from "./Users";
import { PACKAGES, PLANS } from "./CONSTANTS";
import { FONT_STYLE, FONT_STYLE_SELECT } from "pages/Common/constants";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Label } from "recharts";
import { Button } from "react-bootstrap";
import { IconButton, MenuItem } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
const fileTypes = ["XLS"];

export function AccountConfig(props) {
  const [value, setValue] = React.useState("packages");
  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState("annual");
  const [dValue, setDValue] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [isSwapped, setIsSwapped] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedApi, setSelectedApi] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [count, setCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [uploadFileImage, setUploadFileImage] = useState("");
  const [automationOption, setAtomationOption] = useState(null);
  const [showCustomBox, setShowCustomBox] = useState(false);

  // Toggle function to switch the columns
  const toggleColumns = () => {
    setIsSwapped(!isSwapped);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // selection of api
  const handleApiSelect = (selectedOption) => {
    setSelectedApi(selectedOption); // Set selected API
  };
  const options = [
    { value: "chocolate", label: "Construction-Heavy" },
    { value: "strawberry", label: "Construction-Low" },
    { value: "vanilla", label: "Construction-Medium" },
    { value: "Construction", label: "Construction" },
  ];

  // Handle Select change
  const handleSelectOptionChange = (option) => {
    setAtomationOption(option);
    if (option.value === "custom") {
      setShowCustomBox(true);
    } else {
      setShowCustomBox(false);
    }
  };

  const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];

  // hide file uplaod modal
  const handleOpenCloseFileUploadModal = () => {
    setShowFileUploadModal(!showFileUploadModal);
  };

  // Image|File upload Modal box show|hide
  const handleImageFileUpload = (e, value) => {
    setUploadFileImage(value);
  };

  const handleChangeSelect = (e) => {
    setDValue(e);
  };
  const internalApiTypeOptions = [
    { label: "Spare part Master", value: "Spare part Master" },
    { label: "Equipment Master", value: "Equipment Master" },
    { label: "Consumable Master", value: "Consumable Master" },
    { label: " Labor Master", value: " Labor Master" },
    { label: "Component Master", value: "Component Master" },
    { label: "Service Master", value: "Service Master" },
    { label: " Customer Master", value: " Customer Master" },
    { label: "Orders", value: "Orders" },
    { label: "Repair Quote", value: "Repair Quote" },
    { label: " Parts Quote", value: "  Parts Quote" },
    { label: "Solution Quote", value: "Solution Quote" },
    { label: "Allocation", value: "Allocation" },
    { label: "Warehouse Inventory", value: "Warehouse Inventory" },
    { label: "Stock Transfer Order", value: "Stock Transfer Order" },
    { label: "Purchase Requisition", value: "Purchase Requisition" },
    { label: " Goods Receipt", value: " Goods Receipt" },
  ];
  const poNumberOptions = [
    { label: "Field Combination", value: "allocation" },
    { label: "Index", value: "index" },
    { label: "ID", value: "id" },
    { label: "PO Number", value: "allocation" },
    { label: "UPC", value: "allocation" },
    { label: "Door Name", value: "allocation" },
  ];
  const automationTypeOption = [
    { label: "Does not Repeat", value: "doesnotrepeat" },
    { label: "Daily", value: "daily" },
    { label: "Weekly on Friday", value: "weeklyonfriday" },
    { label: "Monthly on the third Friday", value: "monthlyonthethirdfriday" },
    { label: " Annually on September 20", value: " annuallyonSeptember20" },
    { label: "Every weekday (Monday to Friday)", value: "everyweekday " },
    { label: "Custom", value: "custom" },
    { label: "Manually", value: "manually" },
  ];
  const timeOptions = [
    { label: "Day", value: "Day" },
    { label: "Week", value: "Week" },
    { label: "Month", value: "Month" },
    { label: "Year", value: "Year" },
  ];
  const referenceOptions = [
    { label: "Reference#", value: "Reference" },
    { label: "Vendor", value: "Vendor" },
    { label: "Currency", value: "Currency" },
    { label: "Specify On Import", value: "SpecifyOnImport" },
    { label: "Header name", value: "Headername" },
  ];
  const [rows, setRows] = useState([]);

  const addRow = () => {
    const newRow = { id: rows.length + 1, value1: "", value2: "" };
    setRows([newRow, ...rows]);
  };

  // Function to handle input change for each row and column
  const handleInputChange = (e, id, field) => {
    const updatedRows = rows.map((row) =>
      row.id === id ? { ...row, [field]: e.target.value } : row
    );
    setRows(updatedRows);
  };

  const increase = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const decrease = () => {
    setCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
  };
  const toggleDaySelection = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(
        selectedDays.filter((selectedDay) => selectedDay !== day)
      );
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  return (
    <>
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div class="container-fluid mt-3">
          <h4>Account Configuration</h4>
          <Box className="mt-4" sx={{ width: "100%", typography: "body1" }}>
            <div className="Account-custom-tabs">
              <div class="row mt-5">
                <div class="col-2 text-center">
                  <ul class="nav nav-tabs tabs-left sideways">
                    <li class="">
                      <a
                        className="active"
                        href="#activemodules-v"
                        data-toggle="tab"
                      >
                        Active Modules
                      </a>
                    </li>
                    <li>
                      <a href="#businessarea-v" data-toggle="tab">
                        Business Area
                      </a>
                    </li>
                    <li>
                      <a href="#rulespermisssion-v" data-toggle="tab">
                        Rules & Permissions
                      </a>
                    </li>
                    <li>
                      <a href="#systemsettings-v" data-toggle="tab">
                        System Settings
                      </a>
                    </li>
                    <li>
                      <a href="#visualisationoptions-v" data-toggle="tab">
                        Visualisation Options
                      </a>
                    </li>
                    <li>
                      <a href="#pricesettings-v" data-toggle="tab">
                        Price Settings
                      </a>
                    </li>
                    <li>
                      <a href="#documenteditableoptions-v" data-toggle="tab">
                        Document Editable Options
                      </a>
                    </li>
                    <li>
                      <a href="#loaddata-v" data-toggle="tab">
                        Load Data
                      </a>
                    </li>
                    <li>
                      <a href="#extapiint-v" data-toggle="tab">
                        External API Integration
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-10">
                  <div class="tab-content">
                    <div class="tab-pane active" id="activemodules-v">
                      <div className="row">
                        <div className="col-md-4 mt-3">
                          <div className="card p-3">
                            <h5>Active Modules</h5>
                            <div>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox defaultChecked />}
                                  label="Repair Builder"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Solution Builder"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Price Configurator"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Quote Configurator"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Commerce"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Master Data"
                                />
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mt-3">
                          <div className="card p-3">
                            <h5>Active Sub-Modules</h5>
                            <div>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox defaultChecked />}
                                  label="Repair Option"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Part List"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Maintenance & Repair Template"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Kit"
                                />
                              </FormGroup>
                            </div>
                          </div>
                          <div className="card p-3 mt-2">
                            <h5>Active Sub-Modules</h5>
                            <div>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox defaultChecked />}
                                  label="Portfolio"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Service Programs"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Guided Selling"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Solution Configuration"
                                />
                              </FormGroup>
                            </div>
                          </div>
                          <div className="card p-3 mt-2">
                            <h5>Active Sub-Modules</h5>
                            <div>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox defaultChecked />}
                                  label="Repair Quote"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Soution Quote"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Spare Parts Quote"
                                />
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mt-3">
                          <div className="card p-3">
                            <h5>Additional Modules</h5>
                            <div>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox defaultChecked />}
                                  label="Al Price Recommendation"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Al Product Recommendation"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Chat & Collaboration"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="ERP Integration"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="CRM Integration"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Third Party Integration"
                                />
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="businessarea-v">
                      <div className="card  mt-3 p-3">
                        <div>
                          <div className="row mt-3">
                            <div className="col-md-4 col-sm-4">
                              <div className="form-group">
                                <label
                                  className="font-size-14 "
                                  for="exampleInputEmail1"
                                >
                                  COUNTRY
                                </label>
                                <Select
                                  defaultValue={selectedOption}
                                  onChange={setSelectedOption}
                                  options={options}
                                  placeholder="USA"
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                              <div className="form-group">
                                <label
                                  className="font-size-14 "
                                  for="exampleInputEmail1"
                                >
                                  SALES BRANCH+
                                </label>
                                <Select
                                  defaultValue={selectedOption}
                                  onChange={setSelectedOption}
                                  options={options}
                                  placeholder="New York"
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                              <div className="form-group ">
                                <label
                                  class="font-size-14 "
                                  for="exampleInputEmail1"
                                >
                                  RELATED ERP ORGANISATION
                                </label>
                                <input
                                  type="email"
                                  class="form-control "
                                  aria-describedby="emailHelp"
                                  placeholder="Long Isiand"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-4 col-sm-4">
                              <div className="form-group ">
                                <label
                                  class="font-size-14 "
                                  for="exampleInputEmail1"
                                >
                                  SALES ORGANISATION{" "}
                                </label>
                                <input
                                  type="email"
                                  class="form-control "
                                  aria-describedby="emailHelp"
                                  placeholder="USA"
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                              <div className="form-group">
                                <label
                                  className="font-size-14 "
                                  for="exampleInputEmail1"
                                >
                                  SALES BRANCH+
                                </label>
                                <Select
                                  defaultValue={selectedOption}
                                  onChange={setSelectedOption}
                                  options={options}
                                  placeholder="Long Isiand"
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                              <div className="form-group ">
                                <label
                                  class="font-size-14 "
                                  for="exampleInputEmail1"
                                >
                                  RELATED ERP ORGANISATION
                                </label>
                                <input
                                  type="email"
                                  class="form-control "
                                  aria-describedby="emailHelp"
                                  placeholder="Long Isiand"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-4 col-sm-4">
                              <div className="form-group">
                                <label
                                  className="font-size-14 "
                                  for="exampleInputEmail1"
                                >
                                  CHANNEL
                                </label>
                                <Select
                                  defaultValue={selectedOption}
                                  onChange={setSelectedOption}
                                  options={options}
                                  placeholder="Long Isiand"
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                              <div className="form-group ">
                                <label
                                  class="font-size-14 "
                                  for="exampleInputEmail1"
                                >
                                  DISPLAYED BUISSNESS NAME
                                </label>
                                <input
                                  type="email"
                                  class="form-control "
                                  aria-describedby="emailHelp"
                                  placeholder="Long Isiand"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-4 col-sm-4">
                              <div className="form-group">
                                <label
                                  className="font-size-14 "
                                  for="exampleInputEmail1"
                                >
                                  DEFAULT CURRENCY
                                </label>
                                <Select
                                  defaultValue={selectedOption}
                                  onChange={setSelectedOption}
                                  options={options}
                                  placeholder="Long Isiand"
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                              <div className="form-group ">
                                <label
                                  class="font-size-14 "
                                  for="exampleInputEmail1"
                                >
                                  DISPLAYED ADDRESS
                                </label>
                                <input
                                  type="email"
                                  class="form-control "
                                  aria-describedby="emailHelp"
                                  placeholder="Long Isiand"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-4 col-sm-4">
                              <div className="form-group ">
                                <label
                                  class="font-size-14 "
                                  for="exampleInputEmail1"
                                >
                                  DISPLAYED BUISSNESS NAME
                                </label>
                                <input
                                  type="email"
                                  class="form-control "
                                  aria-describedby="emailHelp"
                                  placeholder="Long Isiand"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-4 col-sm-4">
                              <div className="form-group ">
                                <label
                                  class="font-size-14 "
                                  for="exampleInputEmail1"
                                >
                                  DISPLAYED ADDRESS
                                </label>
                                <input
                                  type="email"
                                  class="form-control "
                                  aria-describedby="emailHelp"
                                  placeholder="Long Isiand"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                    <div class="tab-pane" id="rulespermisssion-v">
                      <div className="maintableheader bg-white mt-3 border-radius-10 p-3 mt-3 ">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex justify-content-between align-items-center p-3 bg-light-dark border-radius-10">
                            <div className="d-flex align-items-center">
                              <span className="mr-3">Document</span>
                              <div className="customselect d-flex align-items-center mr-3">
                                <Select
                                  // onChange={handleChangeSelect}
                                  isClearable={true}
                                  // value={dValue}
                                  options={[{ label: "One", value: "one" }]}
                                  placeholder="Repair Quote"
                                />{" "}
                                <span>
                                  <a href="#" className="btn-sm">
                                    <DeleteIcon className="font-size-14" />
                                  </a>
                                </span>
                              </div>
                              <div className="customselect d-flex align-items-center mr-3">
                                <Select
                                  // onChange={handleChangeSelect}
                                  isClearable={true}
                                  // value={dValue}
                                  options={[{ label: "One", value: "one" }]}
                                  placeholder="&"
                                />
                              </div>
                              <div className="customselect d-flex align-items-center mr-3">
                                <Select
                                  // onChange={handleChangeSelect}
                                  isClearable={true}
                                  // value={dValue}
                                  options={[{ label: "One", value: "one" }]}
                                  placeholder=""
                                />{" "}
                                <span>
                                  <a href="#" className="btn-sm">
                                    <DeleteIcon className="font-size-14" />
                                  </a>
                                </span>
                              </div>
                              <div className="customselect d-flex align-items-center mr-3">
                                <Select
                                  // onChange={handleChangeSelect}
                                  isClearable={true}
                                  // value={dValue}
                                  options={[{ label: "One", value: "one" }]}
                                  placeholder="&"
                                />
                              </div>
                              <div className="customselect d-flex align-items-center mr-3">
                                <Select
                                  onChange={handleChangeSelect}
                                  isClearable={true}
                                  value={dValue}
                                  options={[{ label: "One", value: "one" }]}
                                  placeholder="%"
                                />{" "}
                                <span>
                                  <a href="#" className="btn-sm">
                                    <DeleteIcon className="font-size-14" />
                                  </a>
                                </span>
                              </div>
                              <div>
                                <a
                                  href="#"
                                  className="btn-sm text-violet border"
                                  style={{ border: "1px solid #872FF7" }}
                                >
                                  +
                                </a>
                              </div>
                            </div>
                          </div>
                          <div>
                            <a href="#" className="btn-sm">
                              <DeleteIcon
                                className="font-size-14"
                                style={{ color: "#DD9088" }}
                              />
                            </a>
                          </div>
                        </div>
                        <div className="mt-3">
                          <a href="#" className="btn alert-messges ">
                            Add Rules <AddBoxOutlinedIcon />
                          </a>
                        </div>
                        <div className="mt-3 text-right">
                          <a
                            href="#"
                            className="btn bg-green btn text-white mr-3"
                          >
                            Save
                          </a>
                          <a href="#" className="btn border ">
                            Cancel
                          </a>
                        </div>
                      </div>
                      <div className="maintableheader bg-white mt-3 border-radius-10 p-3 mt-3 ">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex justify-content-between align-items-center p-3 bg-light-dark border-radius-10">
                            <div className="d-flex align-items-center">
                              <span className="mr-3">Document</span>
                              <div className="customselect d-flex align-items-center mr-3">
                                <Select
                                  // onChange={handleChangeSelect}
                                  isClearable={true}
                                  // value={dValue}
                                  options={[{ label: "One", value: "one" }]}
                                  placeholder="Repair Quote"
                                />{" "}
                                <span>
                                  <a href="#" className="btn-sm">
                                    <DeleteIcon className="font-size-14" />
                                  </a>
                                </span>
                              </div>
                              <div className="customselect d-flex align-items-center mr-3">
                                <Select
                                  // onChange={handleChangeSelect}
                                  isClearable={true}
                                  // value={dValue}
                                  options={[{ label: "One", value: "one" }]}
                                  placeholder="&"
                                />
                              </div>
                              <div className="customselect d-flex align-items-center mr-3">
                                <Select
                                  // onChange={handleChangeSelect}
                                  isClearable={true}
                                  // value={dValue}
                                  options={[{ label: "One", value: "one" }]}
                                  placeholder=""
                                />{" "}
                                <span>
                                  <a href="#" className="btn-sm">
                                    <DeleteIcon className="font-size-14" />
                                  </a>
                                </span>
                              </div>
                              <div className="customselect d-flex align-items-center mr-3">
                                <Select
                                  // onChange={handleChangeSelect}
                                  isClearable={true}
                                  // value={dValue}
                                  options={[{ label: "One", value: "one" }]}
                                  placeholder="&"
                                />
                              </div>
                              <div className="customselect d-flex align-items-center mr-3">
                                <Select
                                  onChange={handleChangeSelect}
                                  isClearable={true}
                                  value={dValue}
                                  options={[{ label: "One", value: "one" }]}
                                  placeholder="%"
                                />{" "}
                                <span>
                                  <a href="#" className="btn-sm">
                                    <DeleteIcon className="font-size-14" />
                                  </a>
                                </span>
                              </div>
                              <div>
                                <a
                                  href="#"
                                  className="btn-sm text-violet border"
                                  style={{ border: "1px solid #872FF7" }}
                                >
                                  +
                                </a>
                              </div>
                            </div>
                          </div>
                          <div>
                            <a href="#" className="btn-sm">
                              <DeleteIcon
                                className="font-size-14"
                                style={{ color: "#DD9088" }}
                              />
                            </a>
                          </div>
                        </div>
                        <div className="mt-3">
                          <a
                            href="#"
                            className="btn alert-messges "
                            data-toggle="modal"
                            data-target="#myModal2"
                          >
                            View/Add Approvers <AddBoxOutlinedIcon />
                          </a>
                        </div>
                        <div className="mt-3 text-right">
                          <a
                            href="#"
                            className="btn bg-green btn text-white mr-3"
                          >
                            Edit
                          </a>
                          <a href="#" className="btn border ">
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="systemsettings-v">
                      <div className="row">
                        <div className="col-md-4 mt-3">
                          <div className="card p-3">
                            <div className="form-group">
                              <label
                                className="font-size-14 "
                                for="exampleInputEmail1"
                              >
                                DOCUMENT TYPE
                              </label>
                              <Select
                                defaultValue={selectedOption}
                                onChange={setSelectedOption}
                                options={options}
                                placeholder="Portfolio/Program"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <a href="#" className="bg-green btn text-white">
                            <span className="mr-2">+</span>Add New
                          </a>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-4">
                          <div className="card Add-new-segment-div p-3">
                            <div>
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  <label
                                    className="font-size-14 "
                                    for="exampleInputEmail1"
                                  >
                                    DOCUMENT TYPE
                                  </label>
                                </div>
                                <div>
                                  <a
                                    href="#"
                                    className="btn-sm bg-lightparpal text-white"
                                  >
                                    +
                                  </a>
                                </div>
                              </div>
                              <div className="d-flex customizeselecter">
                                <Select
                                  className="first-Select"
                                  defaultValue={selectedOption}
                                  onChange={setSelectedOption}
                                  options={options}
                                  placeholder="Portfolio/Program"
                                />
                                <Select
                                  className="w-100"
                                  defaultValue={selectedOption}
                                  onChange={setSelectedOption}
                                  options={options}
                                  placeholder="Portfolio/Program"
                                />
                              </div>
                            </div>
                            <div className=" mt-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  <label
                                    className="font-size-14 "
                                    for="exampleInputEmail1"
                                  >
                                    HEADER-STRATEGY
                                  </label>
                                </div>
                                <div>
                                  <a
                                    href="#"
                                    className="btn-sm bg-lightparpal text-white"
                                  >
                                    +
                                  </a>
                                </div>
                              </div>
                              <div className="d-flex customizeselecter">
                                <Select
                                  className="first-Select"
                                  defaultValue={selectedOption}
                                  onChange={setSelectedOption}
                                  options={options}
                                  placeholder="Strategy Task"
                                />
                                <Select
                                  className="w-100"
                                  defaultValue={selectedOption}
                                  onChange={setSelectedOption}
                                  options={options}
                                  placeholder="Placeholder(Optional)"
                                />
                              </div>
                            </div>
                            <div className="mt-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  <label
                                    className="font-size-14 "
                                    for="exampleInputEmail1"
                                  >
                                    HEADER-COVERAGE
                                  </label>
                                </div>
                                <div>
                                  <a
                                    href="#"
                                    className="btn-sm bg-lightparpal text-white"
                                  >
                                    +
                                  </a>
                                </div>
                              </div>
                              <div className="d-flex customizeselecter">
                                <Select
                                  className="first-Select"
                                  defaultValue={selectedOption}
                                  onChange={setSelectedOption}
                                  options={options}
                                  placeholder="Make"
                                />
                                <Select
                                  className="w-100"
                                  defaultValue={selectedOption}
                                  onChange={setSelectedOption}
                                  options={options}
                                  placeholder="Placeholder(Optional)"
                                />
                              </div>
                            </div>
                            <div className="mt-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  <label
                                    className="font-size-14 "
                                    for="exampleInputEmail1"
                                  >
                                    HEADER PRICE LIST
                                  </label>
                                </div>
                              </div>
                              <div className="d-flex customizeselecter">
                                <Select
                                  className="first-Select"
                                  defaultValue={selectedOption}
                                  onChange={setSelectedOption}
                                  options={options}
                                  placeholder="Price Methods"
                                />
                                <Select
                                  className="w-100"
                                  defaultValue={selectedOption}
                                  onChange={setSelectedOption}
                                  options={options}
                                  placeholder="Placeholder(Optional)"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="visualisationoptions-v">
                      <div className="row">
                        <div className="col-md-4 mt-3">
                          <div className="card p-3">
                            <div className="form-group">
                              <label
                                className="font-size-14 "
                                for="exampleInputEmail1"
                              >
                                BUISNESS ROLE
                              </label>
                              <Select
                                defaultValue={selectedOption}
                                onChange={setSelectedOption}
                                options={options}
                                placeholder="Product Expert"
                              />
                            </div>
                            <div>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox defaultChecked />}
                                  label="display price"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="search price"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="display net value"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="display descounts"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="modify net value"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="display price breakdown"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="approve or reject colleagu'e work"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="share data"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="display master data"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="search master data"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="display colleague's work"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="search colleague's work"
                                />
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mt-3">
                          <div className="card p-3">
                            <label
                              className="font-size-14 "
                              for="exampleInputEmail1"
                            >
                              Date Format
                            </label>
                            <div>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox defaultChecked />}
                                  label="12 hours"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="24 hours"
                                />
                              </FormGroup>
                            </div>
                          </div>
                          <div>
                            <div className="form-group">
                              <label
                                className="font-size-14 "
                                for="exampleInputEmail1"
                              >
                                TIMEZONE
                              </label>
                              <Select
                                defaultValue={selectedOption}
                                onChange={setSelectedOption}
                                options={options}
                                placeholder="GMT + 5:30"
                              />
                            </div>
                            <div className="form-group">
                              <label
                                className="font-size-14 "
                                for="exampleInputEmail1"
                              >
                                TIME FORMAT
                              </label>
                              <Select
                                defaultValue={selectedOption}
                                onChange={setSelectedOption}
                                options={options}
                                placeholder="HH:MM:SS"
                              />
                            </div>
                            <div className="form-group">
                              <label
                                className="font-size-14 "
                                for="exampleInputEmail1"
                              >
                                DATE FORMAT
                              </label>
                              <Select
                                defaultValue={selectedOption}
                                onChange={setSelectedOption}
                                options={options}
                                placeholder="DD/MM/YYYY"
                              />
                            </div>
                            <div className="form-group">
                              <label
                                className="font-size-14 "
                                for="exampleInputEmail1"
                              >
                                LANGUAGE
                              </label>
                              <Select
                                defaultValue={selectedOption}
                                onChange={setSelectedOption}
                                options={options}
                                placeholder="EN"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mt-3">
                          <div className="card p-3">
                            <label
                              className="font-size-14 "
                              for="exampleInputEmail1"
                            >
                              Currency Format
                            </label>
                            <div>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox defaultChecked />}
                                  label="123.xy"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="123,xy"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="123,45,xy"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="123,45,xy"
                                />
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="pricesettings-v">
                      <div className="row">
                        <div className="col-md-4 mt-3">
                          <div className="card p-3">
                            <div className="form-group">
                              <label
                                className="font-size-14 "
                                for="exampleInputEmail1"
                              >
                                BUISNESS ROLE
                              </label>
                              <Select
                                defaultValue={selectedOption}
                                onChange={setSelectedOption}
                                options={options}
                                placeholder="Product Expert"
                              />
                            </div>
                            <div>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox defaultChecked />}
                                  label="overwrite price"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="overwrite header price"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="maintain manual price"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="overwrite net value"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="overwrite discounts"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="maintain flat rate"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="overwrite item price"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="price recommendation active"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="switch to past date price"
                                />
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mt-3">
                          <div className="card p-3">
                            <label
                              className="font-size-14 "
                              for="exampleInputEmail1"
                            >
                              Set exclusion rules spare parts
                            </label>
                            <div>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox defaultChecked />}
                                  label="Rule A"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Rule B"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Rule C"
                                />
                              </FormGroup>
                            </div>
                          </div>
                          <div className="card p-3">
                            <label
                              className="font-size-14 "
                              for="exampleInputEmail1"
                            >
                              Set exclusion rules Services
                            </label>
                            <div>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox defaultChecked />}
                                  label="Rule A"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Rule B"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Rule C"
                                />
                              </FormGroup>
                            </div>
                          </div>
                          <div></div>
                        </div>
                        <div className="col-md-4 mt-3">
                          <div className="form-group ">
                            <label
                              class="font-size-14 "
                              for="exampleInputEmail1"
                            >
                              DEFAULT MARGIN PARTS
                            </label>
                            <input
                              type="email"
                              class="form-control "
                              aria-describedby="emailHelp"
                              placeholder="10%"
                            />
                          </div>
                          <div className="form-group ">
                            <label
                              class="font-size-14 "
                              for="exampleInputEmail1"
                            >
                              DEFAULT MARGIN LABOR
                            </label>
                            <input
                              type="email"
                              class="form-control "
                              aria-describedby="emailHelp"
                              placeholder="20%"
                            />
                          </div>
                          <div className="form-group ">
                            <label
                              class="font-size-14 "
                              for="exampleInputEmail1"
                            >
                              DEFAULT MARGIN MISC.
                            </label>
                            <input
                              type="email"
                              class="form-control "
                              aria-describedby="emailHelp"
                              placeholder="10%"
                            />
                          </div>
                          <div className="form-group ">
                            <label
                              class="font-size-14 "
                              for="exampleInputEmail1"
                            >
                              IF % ON TOTAL MISC.(DEFAULT){" "}
                            </label>
                            <input
                              type="email"
                              class="form-control "
                              aria-describedby="emailHelp"
                              placeholder="15%"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3 mt-3">
                          <div className="card p-3">
                            <label
                              className="font-size-14 "
                              for="exampleInputEmail1"
                            >
                              Spare parts / Component
                            </label>
                            <div>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox defaultChecked />}
                                  label="Cost Price"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="List Price"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Contract price"
                                />
                              </FormGroup>
                            </div>
                          </div>
                          <div></div>
                        </div>
                        <div className="col-md-3 mt-3">
                          <div className="card p-3">
                            <label
                              className="font-size-14 "
                              for="exampleInputEmail1"
                            >
                              Labor Rates
                            </label>
                            <div>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox defaultChecked />}
                                  label="Cost Price"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="List Price"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Contract Price"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Fait rate"
                                />
                              </FormGroup>
                            </div>
                          </div>
                          <div></div>
                        </div>
                        <div className="col-md-3 mt-3">
                          <div className="card p-3">
                            <label
                              className="font-size-14 "
                              for="exampleInputEmail1"
                            >
                              Misc
                            </label>
                            <div>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox defaultChecked />}
                                  label="% of Tatal (On Labor) "
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="% of Tatal (On Total price) "
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Flat rate"
                                />
                              </FormGroup>
                            </div>
                          </div>
                          <div></div>
                        </div>
                        <div className="col-md-3 mt-3">
                          <div className="card p-3">
                            <label
                              className="font-size-14 "
                              for="exampleInputEmail1"
                            >
                              Portfolio / Bundles / Services
                            </label>
                            <div>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox defaultChecked />}
                                  label="Option Price"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="List Price"
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Manual Price"
                                />
                              </FormGroup>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="documenteditableoptions-v">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="row">
                            <div className="col-md-5 mt-3">
                              <div className="form-group">
                                <label
                                  className="font-size-14 "
                                  for="exampleInputEmail1"
                                >
                                  FOR DOCUMENT CONETNTS
                                </label>
                                <Select
                                  defaultValue={selectedOption}
                                  onChange={setSelectedOption}
                                  options={options}
                                  placeholder="Product Expert"
                                />
                              </div>
                            </div>
                            <div className="col-md-7 mt-3">
                              <div className=" card p-3">
                                <label
                                  className="font-size-14 "
                                  for="exampleInputEmail1"
                                >
                                  Changebale Fields
                                </label>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={<Checkbox defaultChecked />}
                                          label="All Fields"
                                        />
                                        <FormControlLabel
                                          control={<Checkbox />}
                                          label="Description"
                                        />
                                        <FormControlLabel
                                          control={<Checkbox />}
                                          label="Quantity"
                                        />
                                        <FormControlLabel
                                          control={<Checkbox />}
                                          label="Comments"
                                        />
                                      </FormGroup>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={<Checkbox defaultChecked />}
                                          label="Price"
                                        />
                                        <FormControlLabel
                                          control={<Checkbox />}
                                          label="Owner"
                                        />
                                        <FormControlLabel
                                          control={<Checkbox />}
                                          label="Date"
                                        />
                                        <FormControlLabel
                                          control={<Checkbox />}
                                          label="% Usage"
                                        />
                                      </FormGroup>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="Add-new-segment-div p-3 border-radius-10">
                                <a href="#" className="btn bg-green text-white">
                                  Apply Settings
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="loaddata-v">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-12 mt-3">
                              <div className=" card p-3">
                                <label
                                  className="font-size-14 "
                                  for="exampleInputEmail1"
                                >
                                  Changebale Fields
                                </label>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={<Checkbox defaultChecked />}
                                          label="Customer"
                                        />
                                        <FormControlLabel
                                          control={<Checkbox />}
                                          label="Equipment"
                                        />
                                        <FormControlLabel
                                          control={<Checkbox />}
                                          label="Spare Parts"
                                        />
                                        <FormControlLabel
                                          control={<Checkbox />}
                                          label="Component Code"
                                        />
                                      </FormGroup>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={<Checkbox defaultChecked />}
                                          label="Job Code"
                                        />
                                        <FormControlLabel
                                          control={<Checkbox />}
                                          label="Consumable"
                                        />
                                        <FormControlLabel
                                          control={<Checkbox />}
                                          label="External Work"
                                        />
                                        <FormControlLabel
                                          control={<Checkbox />}
                                          label="Price"
                                        />
                                      </FormGroup>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="Add-new-segment-div p-3 border-radius-10">
                                <a
                                  href="#"
                                  className="btn bg-green text-white"
                                  onClick={handleOpenCloseFileUploadModal}
                                >
                                  Load Data
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" col-md-12 mt-3">
                          <div className=" row">
                            {/* <div className=" col-md-6">
                                                            <div className=" card p-3">
                                                                <h6>Import File</h6>
                                                                <div className="add-new-recod">
                                                                    <div>
                                                                        <FontAwesomeIcon className="cloudupload" icon={faCloudUploadAlt} />
                                                                        <h6 className="font-weight-500 mt-3">
                                                                            Drag and drop files to upload <br /> or
                                                                        </h6>
                                                                        <a
                                                                            onClick={() => setOpen(true)}
                                                                            style={{ cursor: "pointer" }}
                                                                            className="btn text-light border-light font-weight-500 border-radius-10 mt-3"
                                                                        >
                                                                            <span className="mr-2">
                                                                                <FontAwesomeIcon icon={faPlus} />
                                                                            </span>
                                                                            Select files to upload
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <p className="mt-3">
                                                                    Single upload file should not be more than 10MB. Only the .xls, .xlsx file types
                                                                    are allowed
                                                                </p>

                                                                <div>
                                                                    <div className="recent-items mt-3">
                                                                        <div className="d-flex justify-content-between align-items-center ">
                                                                            <p className="mb-0 ">
                                                                                <FontAwesomeIcon className=" font-size-14" icon={faFileAlt} />
                                                                                <span className="font-weight-500 ml-2">Customer</span>
                                                                            </p>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="white-space custom-checkbox">
                                                                                    <FormGroup>
                                                                                        <FormControlLabel
                                                                                            control={<Checkbox defaultChecked />}
                                                                                            label=""
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                                <a href="#" className="ml-3 font-size-14">
                                                                                    <FileUploadOutlinedIcon />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between align-items-center mt-2">
                                                                        <p className="font-size-12 mb-0">2:38pm, 19 Aug 21 </p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="recent-items mt-3">
                                                                        <div className="d-flex justify-content-between align-items-center ">
                                                                            <p className="mb-0 ">
                                                                                <FontAwesomeIcon className=" font-size-14" icon={faFileAlt} />
                                                                                <span className="font-weight-500 ml-2">Equipment</span>
                                                                            </p>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="white-space custom-checkbox">
                                                                                    <FormGroup>
                                                                                        <FormControlLabel
                                                                                            control={<Checkbox defaultChecked />}
                                                                                            label=""
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                                <a href="#" className="ml-3 font-size-14">
                                                                                    <FileUploadOutlinedIcon />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between align-items-center mt-2">
                                                                        <p className="font-size-12 mb-0">2:38pm, 19 Aug 21 </p>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-md-6">
                                                                        <a href="#" className="btn border d-block">
                                                                            Cancel
                                                                        </a>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <a href="#" className="btn border d-block bg-green text-white">
                                                                            <span className="mr-2">
                                                                                <BackupOutlinedIcon />
                                                                            </span>
                                                                            Upload
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                            <div className="col-md-6">
                              <div className="card p-3">
                                <div>
                                  <label
                                    class="font-size-14 "
                                    for="exampleInputEmail1"
                                  >
                                    DATA LOAD STATUS
                                  </label>
                                  <div className="recent-items mt-3">
                                    <div className="d-flex justify-content-between align-items-center ">
                                      <p className="mb-0 ">
                                        <FontAwesomeIcon
                                          className=" font-size-14"
                                          icon={faFileAlt}
                                        />
                                        <span className="font-weight-500 ml-2">
                                          Equipment
                                        </span>
                                      </p>
                                      <div className="d-flex align-items-center">
                                        <div className="white-space custom-checkbox">
                                          <FormGroup>
                                            <FormControlLabel
                                              control={
                                                <Checkbox defaultChecked />
                                              }
                                              label=""
                                            />
                                          </FormGroup>
                                        </div>
                                        <a
                                          href="#"
                                          className="ml-3 font-size-14"
                                        >
                                          <FileUploadOutlinedIcon />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center mt-2">
                                    <p className="font-size-12 mb-0">
                                      2:38pm, 19 Aug 21{" "}
                                    </p>
                                    <p className="mb-0">
                                      <b style={{ color: "#5DBAB5" }}>
                                        Uploaded Succesfully
                                      </b>
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <div className="recent-items mt-3">
                                    <div className="d-flex justify-content-between align-items-center ">
                                      <p className="mb-0 ">
                                        <FontAwesomeIcon
                                          className=" font-size-14"
                                          icon={faFileAlt}
                                        />
                                        <span className="font-weight-500 ml-2">
                                          Equipment
                                        </span>
                                      </p>
                                      <div className="d-flex align-items-center">
                                        <div className="white-space custom-checkbox">
                                          <FormGroup>
                                            <FormControlLabel
                                              control={
                                                <Checkbox defaultChecked />
                                              }
                                              label=""
                                            />
                                          </FormGroup>
                                        </div>
                                        <a
                                          href="#"
                                          className="ml-3 font-size-14"
                                        >
                                          <FileUploadOutlinedIcon />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center mt-2">
                                    <p className="font-size-12 mb-0">
                                      2:38pm, 19 Aug 21{" "}
                                    </p>
                                    <p className="mb-0">
                                      <b style={{ color: "#EE799B" }}>
                                        Few Records Are Not Updated
                                      </b>
                                    </p>
                                  </div>
                                </div>
                                <p>
                                  The errors may have occeured due to Incorrect
                                  fieid values, or it taak too long. Click here
                                  to check the incorrect entries.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="extapiint-v">
                      <div className="row">
                        <div className="col-md-6">
                          <Accordion
                            // expanded={expanded.panel1}
                            expanded={expanded === "panel1"}
                            onChange={handleChange("panel1")}
                            sx={{ mb: 2, backgroundColor: "#f0f0f0" }}
                          >
                            <AccordionSummary
                              expandIcon={
                                <ChevronRightIcon
                                  sx={{
                                    transform:
                                      expanded === "panel1"
                                        ? "rotate(270deg)"
                                        : "rotate(0deg)",
                                    transition: "transform 0.3s ease",
                                  }}
                                />
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              sx={{ flexDirection: "row-reverse" }}
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography>1. General</Typography>
                              </Box>
                            </AccordionSummary>
                            <Divider variant="middle" />

                            <AccordionDetails>
                              <Typography>
                                <div className="row mt-2 input-fields">
                                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">
                                        External API Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        // value={installerRecord.companyName}
                                        name="companyName"
                                        placeholder=" External API Name"
                                        // onChange={handleInstallerFieldsChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">
                                        {" "}
                                        External API URL
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        // value={installerRecord.companyName}
                                        name="companyName"
                                        placeholder=" External API URL"
                                        // onChange={handleInstallerFieldsChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">
                                        External Method Type
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        // value={installerRecord.companyName}
                                        name="companyName"
                                        placeholder="External Method Type"
                                        // onChange={handleInstallerFieldsChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">
                                        Internal Method Type
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        // value={installerRecord.companyName}
                                        name="companyName"
                                        placeholder="Internal Method Type"
                                        // onChange={handleInstallerFieldsChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">
                                        {" "}
                                        Internal API Name
                                      </label>
                                      <Select
                                        options={internalApiTypeOptions}
                                        onChange={handleApiSelect}
                                        value={selectedApi}
                                        styles={FONT_STYLE_SELECT}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">
                                        {" "}
                                        Internal API URL
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        // value={installerRecord.companyName}
                                        name="companyName"
                                        value={selectedApi?.value || ""}
                                        placeholder=" Autofilled"
                                        readOnly
                                        // onChange={handleInstallerFieldsChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            // expanded={expanded.panel2}
                            expanded={expanded === "panel2"}
                            onChange={handleChange("panel2")}
                            sx={{ mb: 2, backgroundColor: "#f0f0f0" }}
                          >
                            <AccordionSummary
                              expandIcon={
                                <ChevronRightIcon
                                  sx={{
                                    transform:
                                      expanded === "panel2"
                                        ? "rotate(270deg)"
                                        : "rotate(0deg)",
                                    transition: "transform 0.3s ease",
                                  }}
                                />
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              sx={{ flexDirection: "row-reverse" }}
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography>2. Field Mapping</Typography>
                              </Box>
                            </AccordionSummary>
                            <Divider variant="middle" />

                            <AccordionDetails>
                              <Typography>
                                {!isSwapped ? (
                                  <div>
                                    <div className="row mt-3 input-fields">
                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <label className="text-light-dark font-size-14 font-weight-700">
                                          Export Column Header
                                        </label>
                                      </div>
                                      <div className="col-lg-2 col-md-2 col-sm-2 col-12 text-center">
                                        <FontAwesomeIcon
                                          icon={faExchangeAlt}
                                          className="text-primary"
                                          style={{ cursor: "pointer" }}
                                          onClick={toggleColumns} // Toggle on click
                                        />
                                      </div>
                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <label className="text-light-dark font-size-14 font-weight-700">
                                          Joor Data Input
                                        </label>
                                      </div>
                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <Select
                                          className=" mb-3"
                                          options={referenceOptions}
                                          defaultValue={referenceOptions[0]}
                                          styles={FONT_STYLE_SELECT}
                                        />

                                        {/* <input
                                          type="text"
                                          className="form-control border-radius-10 text-primary mb-3"
                                          name="companyName"
                                          placeholder="Reference #"
                                        /> */}
                                      </div>
                                      <div className="col-lg-2 col-md-2 col-sm-2 col-12 text-center">
                                        <ArrowRightAltIcon
                                          fontSize="large"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </div>
                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <Select
                                          className="mb-3"
                                          options={poNumberOptions}
                                          defaultValue={poNumberOptions[0]}
                                          // placeholder="{poNumberOptions[0]} "
                                          styles={FONT_STYLE_SELECT}
                                        />
                                      </div>
                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <Select
                                          className=" mb-3"
                                          options={referenceOptions}
                                          defaultValue={referenceOptions[1]}
                                          styles={FONT_STYLE_SELECT}
                                        />
                                        {/* <input
                                          type="text"
                                          className="form-control border-radius-10 text-primary  mb-3"
                                          name="companyName"
                                          placeholder="Vendor"
                                        /> */}
                                      </div>
                                      <div className="col-lg-2 col-md-2 col-sm-2 col-12 text-center">
                                        <ArrowRightAltIcon
                                          fontSize="large"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </div>
                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <Select
                                          className="mb-3"
                                          options={poNumberOptions}
                                          defaultValue={poNumberOptions[1]}
                                          // placeholder="{poNumberOptions[0]} "
                                          styles={FONT_STYLE_SELECT}
                                        />

                                        {/* <input
                                          type="text"
                                          className="form-control border-radius-10 text-primary mb-3"
                                          name="companyName"
                                          placeholder="PO Number"
                                        /> */}
                                      </div>
                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <Select
                                          className=" mb-3"
                                          options={referenceOptions}
                                          defaultValue={referenceOptions[2]}
                                          styles={FONT_STYLE_SELECT}
                                        />
                                        {/* <input
                                          type="text"
                                          className="form-control border-radius-10 text-primary  mb-3"
                                          name="companyName"
                                          placeholder=" Currency"
                                        /> */}
                                      </div>
                                      <div className="col-lg-2 col-md-2 col-sm-2 col-12 text-center">
                                        <ArrowRightAltIcon
                                          fontSize="large"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </div>
                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <Select
                                          className="mb-3"
                                          options={poNumberOptions}
                                          defaultValue={poNumberOptions[2]}
                                          // placeholder="{poNumberOptions[0]} "
                                          styles={FONT_STYLE_SELECT}
                                        />

                                        {/* <input
                                          type="text"
                                          className="form-control border-radius-10 text-primary mb-3"
                                          name="companyName"
                                          placeholder="PO Number"
                                        /> */}
                                      </div>
                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <Select
                                          className=" mb-3"
                                          options={referenceOptions}
                                          defaultValue={referenceOptions[3]}
                                          styles={FONT_STYLE_SELECT}
                                        />
                                        {/* <input
                                          type="text"
                                          className="form-control border-radius-10 text-primary mb-3"
                                          name="companyName"
                                          placeholder=" [Specify on Import]"
                                        /> */}
                                      </div>
                                      <div className="col-lg-2 col-md-2 col-sm-2 col-12 text-center">
                                        <ArrowRightAltIcon
                                          fontSize="large"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </div>
                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <Select
                                          className="mb-3"
                                          options={poNumberOptions}
                                          defaultValue={poNumberOptions[3]}
                                          // placeholder="{poNumberOptions[0]} "
                                          styles={FONT_STYLE_SELECT}
                                        />

                                        {/* <input
                                          type="text"
                                          className="form-control border-radius-10 text-primary mb-3"
                                          name="companyName"
                                          placeholder="External API Name"
                                        /> */}
                                      </div>
                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <Select
                                          className=" mb-3"
                                          options={referenceOptions}
                                          defaultValue={referenceOptions[4]}
                                          styles={FONT_STYLE_SELECT}
                                        />
                                        {/* <input
                                          type="text"
                                          className="form-control border-radius-10 text-primary mb-3"
                                          name="companyName"
                                          placeholder="Header name"
                                        /> */}
                                      </div>
                                      <div className="col-lg-2 col-md-2 col-sm-2 col-12 text-center">
                                        <ArrowRightAltIcon
                                          fontSize="large"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </div>
                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <Select
                                          className="mb-3"
                                          options={poNumberOptions}
                                          defaultValue={poNumberOptions[4]}
                                          // placeholder="{poNumberOptions[0]} "
                                          styles={FONT_STYLE_SELECT}
                                        />

                                        {/* <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <input
                                          type="text"
                                          className="form-control border-radius-10 text-primary mb-3"
                                          name="companyName"
                                          placeholder="External API Name"
                                        /> */}
                                      </div>
                                    </div>
                                    {rows.map((row) => (
                                      <div
                                        className="row input-fields "
                                        key={row.id}
                                      >
                                        <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                          <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary mb-3"
                                            value={row.value1}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                row.id,
                                                "value1"
                                              )
                                            }
                                            placeholder="Input for Column 1"
                                          />
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-2 col-12 text-center">
                                          <ArrowRightAltIcon
                                            fontSize="large"
                                            style={{ cursor: "pointer" }}
                                          />
                                        </div>
                                        <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                          <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary mb-3"
                                            value={row.value2}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                row.id,
                                                "value2"
                                              )
                                            }
                                            placeholder="Input for Column 2"
                                          />
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <>
                                    <div className="row mt-3 input-fields">
                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <label className="text-light-dark font-size-14 font-weight-700">
                                          Joor Data Input
                                        </label>
                                      </div>
                                      <div className="col-lg-2 col-md-2 col-sm-2 col-12 text-center">
                                        <FontAwesomeIcon
                                          icon={faExchangeAlt}
                                          className="text-primary"
                                          style={{ cursor: "pointer" }}
                                          onClick={toggleColumns} // Toggle on click
                                        />
                                      </div>
                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <label className="text-light-dark font-size-14 font-weight-700">
                                          Export Column Header
                                        </label>
                                      </div>
                                      {/* </div> */}

                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <Select
                                          className=" mb-3"
                                          options={poNumberOptions}
                                          defaultValue={poNumberOptions[0]}
                                          // placeholder=" PO Number"
                                          styles={FONT_STYLE_SELECT}
                                        />
                                      </div>
                                      <div className="col-lg-2 col-md-2 col-sm-2 col-12 text-center">
                                        <ArrowRightAltIcon
                                          fontSize="large"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </div>
                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <Select
                                          className=" mb-3"
                                          options={referenceOptions}
                                          defaultValue={referenceOptions[0]}
                                          styles={FONT_STYLE_SELECT}
                                        />
                                      </div>
                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <Select
                                          className=" mb-3"
                                          options={poNumberOptions}
                                          defaultValue={poNumberOptions[1]}
                                          styles={FONT_STYLE_SELECT}
                                        />
                                      </div>
                                      <div className="col-lg-2 col-md-2 col-sm-2 col-12 text-center">
                                        <ArrowRightAltIcon
                                          fontSize="large"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </div>
                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <Select
                                          className=" mb-3"
                                          options={referenceOptions}
                                          defaultValue={referenceOptions[1]}
                                          styles={FONT_STYLE_SELECT}
                                        />
                                      </div>
                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <Select
                                          className=" mb-3"
                                          options={poNumberOptions}
                                          defaultValue={poNumberOptions[2]}
                                          styles={FONT_STYLE_SELECT}
                                        />
                                      </div>
                                      <div className="col-lg-2 col-md-2 col-sm-2 col-12 text-center">
                                        <ArrowRightAltIcon
                                          fontSize="large"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </div>
                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <Select
                                          className=" mb-3"
                                          options={referenceOptions}
                                          defaultValue={referenceOptions[2]}
                                          styles={FONT_STYLE_SELECT}
                                        />
                                      </div>
                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <Select
                                          className=" mb-3"
                                          options={poNumberOptions}
                                          defaultValue={poNumberOptions[3]}
                                          styles={FONT_STYLE_SELECT}
                                        />
                                      </div>
                                      <div className="col-lg-2 col-md-2 col-sm-2 col-12 text-center">
                                        <ArrowRightAltIcon
                                          fontSize="large"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </div>
                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <Select
                                          className=" mb-3"
                                          options={referenceOptions}
                                          defaultValue={referenceOptions[3]}
                                          styles={FONT_STYLE_SELECT}
                                        />
                                      </div>

                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <Select
                                          className=" mb-3"
                                          options={poNumberOptions}
                                          defaultValue={poNumberOptions[4]}
                                          styles={FONT_STYLE_SELECT}
                                        />
                                      </div>
                                      <div className="col-lg-2 col-md-2 col-sm-2 col-12 text-center">
                                        <ArrowRightAltIcon
                                          fontSize="large"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </div>
                                      <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <Select
                                          className=" mb-3"
                                          options={referenceOptions}
                                          defaultValue={referenceOptions[4]}
                                          styles={FONT_STYLE_SELECT}
                                        />
                                      </div>
                                      {rows.map((row) => (
                                        <div
                                          className="row input-fields mx-2"
                                          key={row.id}
                                        >
                                          <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                            <div className="form-group">
                                              <input
                                                type="text"
                                                className="form-control border-radius-10 text-primary mb-3"
                                                value={row.value2}
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    e,
                                                    row.id,
                                                    "value2"
                                                  )
                                                }
                                                placeholder="Input for Column 2 "
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-2 col-md-2 col-sm-2 col-12 text-center">
                                            <ArrowRightAltIcon
                                              fontSize="large"
                                              style={{ cursor: "pointer" }}
                                            />
                                          </div>
                                          <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                            <div className="form-group">
                                              <input
                                                type="text"
                                                className="form-control border-radius-10 text-primary mb-2"
                                                value={row.value1}
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    e,
                                                    row.id,
                                                    "value1"
                                                  )
                                                }
                                                placeholder="Input for Column1"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </>
                                )}

                                <button
                                  className="btn btn border-primary"
                                  onClick={addRow}
                                >
                                  + Add Data Point
                                </button>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={expanded === "panel3"}
                            onChange={handleChange("panel3")}
                            sx={{ mb: 2, backgroundColor: "#f0f0f0" }}
                          >
                            <AccordionSummary
                              expandIcon={
                                <ChevronRightIcon
                                  sx={{
                                    transform:
                                      expanded === "panel3"
                                        ? "rotate(270deg)"
                                        : "rotate(0deg)",
                                    transition: "transform 0.3s ease",
                                  }}
                                />
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              sx={{ flexDirection: "row-reverse" }}
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography>3.Data Behavior</Typography>
                              </Box>
                            </AccordionSummary>
                            <Divider variant="middle" />

                            <AccordionDetails>
                              <Typography>
                                Here is the content of the accordion.
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={expanded === "panel4"}
                            onChange={handleChange("panel4")}
                            sx={{ backgroundColor: "#f0f0f0" }}
                          >
                            <AccordionSummary
                              expandIcon={
                                <ChevronRightIcon
                                  sx={{
                                    transform:
                                      expanded === "panel4"
                                        ? "rotate(270deg)"
                                        : "rotate(0deg)",
                                    transition: "transform 0.3s ease",
                                  }}
                                />
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              sx={{ flexDirection: "row-reverse" }}
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography>4. Automation</Typography>
                              </Box>
                            </AccordionSummary>
                            <Divider variant="middle" />

                            <AccordionDetails>
                              <Typography>
                                <div className="row mt-3">
                                  <div className="col-lg-11 col-md-11 col-sm-11 col-12">
                                    <label>Automation Type</label>

                                    <Select
                                      className="mb-4"
                                      options={automationTypeOption}
                                      styles={FONT_STYLE_SELECT}
                                      onChange={handleSelectOptionChange}
                                      value={automationOption}
                                    />
                                    {showCustomBox && (
                                      <>
                                        <Box
                                          mt={3}
                                          p={3} // Adds padding inside the box
                                          sx={{
                                            backgroundColor: "#f9f9f9",
                                            border: "1px solid #ddd",
                                            borderRadius: "8px",
                                          }}
                                        >
                                          <Typography variant="h6">
                                            Custom Recurrence
                                          </Typography>
                                          <Typography variant="body1">
                                            <div className="d-flex flex-column">
                                              <div className="d-flex justify-content-start align-items-center mb-3">
                                                <span className="mr-3">
                                                  Repeat every Week
                                                </span>
                                                <div
                                                  className="px-3 py-2 mr-2 border-radius-10 font-weight-600"
                                                  style={{
                                                    backgroundColor: "#f0f0f0",
                                                  }}
                                                >
                                                  {count}
                                                </div>
                                                <div
                                                  className="d-flex flex-column"
                                                  // style={{
                                                  //   display: "flex",
                                                  //   flexDirection: "column",
                                                  // }}
                                                >
                                                  <IconButton
                                                    onClick={increase}
                                                    color="primary"
                                                    size="small"
                                                  >
                                                    <KeyboardArrowUpIcon />
                                                  </IconButton>
                                                  <IconButton
                                                    onClick={decrease}
                                                    color="secondary"
                                                    size="small"
                                                  >
                                                    <KeyboardArrowDownIcon />
                                                  </IconButton>
                                                </div>
                                                <FormControl
                                                  className="ml-4 p-1 "
                                                  style={{
                                                    backgroundColor: "#f0f0f0",
                                                    borderRadius: "5px",
                                                  }}
                                                >
                                                  <Select
                                                    // className="mb-3"
                                                    options={timeOptions}
                                                    defaultValue={
                                                      timeOptions[0]
                                                    }
                                                    styles={FONT_STYLE_SELECT}
                                                  />
                                                </FormControl>
                                              </div>
                                              <div className="mb-3">
                                                Repeat on1
                                              </div>
                                              <div className="d-flex justify-content-start align-items-center">
                                                {daysOfWeek.map((day) => (
                                                  <div
                                                    className="d-flex justify-content-center align-items-center mr-4"
                                                    key={day}
                                                    onClick={() =>
                                                      toggleDaySelection(day)
                                                    }
                                                    style={{
                                                      width: "20px",
                                                      height: "20px",
                                                      // display: "flex",
                                                      // alignItems: "center",
                                                      // justifyContent: "center",
                                                      borderRadius: "50%",
                                                      backgroundColor:
                                                        selectedDays.includes(
                                                          day
                                                        )
                                                          ? "#007bff"
                                                          : "#f0f0f0", // Blue if selected, grey if not
                                                      color:
                                                        selectedDays.includes(
                                                          day
                                                        )
                                                          ? "white"
                                                          : "black", // Change text color when selected
                                                      // marginRight: " 20px",
                                                      cursor: "pointer",
                                                      // fontWeight: 'semibold',
                                                    }}
                                                  >
                                                    {day}
                                                  </div>
                                                ))}
                                              </div>
                                              <div className="mt-3">
                                                Ends
                                                {/* <div style={{ marginTop: '20px' }}>
        <span >Ends</span> */}
                                                {/* <div className="d-flex flex-column align-items-start" style={{ marginTop: '10px' }}> */}
                                                {/* Checkbox 1 */}
                                                <div className="d-flex flex-column mt-2">
                                                  <div class="form-check mb-3 d-flex align-items-center ">
                                                    <input
                                                      class="form-check-input"
                                                      type="radio"
                                                      name="flexRadioDefault"
                                                      id="flexRadioDefault1"
                                                    />
                                                    <label
                                                      class="form-check-label"
                                                      for="flexRadioDefault1"
                                                    >
                                                      Never
                                                    </label>
                                                  </div>
                                                  <div class="form-check mb-1 d-flex align-items-center">
                                                    {/* <div> */}
                                                    <input
                                                      class="form-check-input"
                                                      type="radio"
                                                      name="flexRadioDefault"
                                                      id="flexRadioDefault1"
                                                    />
                                                    <label
                                                      class="form-check-label"
                                                      for="flexRadioDefault1"
                                                    >
                                                      On
                                                    </label>
                                                    <div
                                                      className="ml-5 p-1"
                                                      style={{
                                                        backgroundColor:
                                                          "#f0f0f0",
                                                        borderRadius: "5px",
                                                      }}
                                                    >
                                                      Dec23 2024
                                                    </div>
                                                  </div>
                                                  <div class="form-check mb-3 d-flex align-items-center">
                                                    <input
                                                      class="form-check-input"
                                                      type="radio"
                                                      name="flexRadioDefault"
                                                      id="flexRadioDefault1"
                                                    />
                                                    <label
                                                      class="form-check-label"
                                                      for="flexRadioDefault1"
                                                      style={{
                                                        marginRight: "30px",
                                                      }}
                                                    >
                                                      After
                                                    </label>
                                                    <div
                                                      style={{
                                                        backgroundColor:
                                                          "#f0f0f0",
                                                        padding: "5px",
                                                        borderRadius: "5px",
                                                      }}
                                                    >
                                                      13 occurrence
                                                    </div>

                                                    <div className="d-flex flex-column">
                                                      <IconButton
                                                        color="primary"
                                                        size="small"
                                                      >
                                                        <KeyboardArrowUpIcon />
                                                      </IconButton>
                                                      <IconButton
                                                        color="secondary"
                                                        size="small"
                                                      >
                                                        <KeyboardArrowDownIcon />
                                                      </IconButton>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Typography>
                                          <div className="d-flex  justify-content-end  ">
                                            <button
                                              className="btn btn-outline-secondary mr-3"
                                              onClick={() => {
                                                setShowCustomBox(false);
                                              }}
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              className="btn btn-primary"
                                              onClick={() => {
                                                setShowCustomBox(false);
                                              }}
                                            >
                                              Done
                                            </button>
                                          </div>
                                        </Box>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </div>
      </div>

      {/* Excel File Upload */}
      <Modal
        show={showFileUploadModal}
        onHide={handleOpenCloseFileUploadModal}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Files</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="p-3">
            <div className="add-new-recod">
              <div>
                <FontAwesomeIcon
                  className="cloudupload"
                  icon={faCloudUploadAlt}
                />
                <h6 className="font-weight-500 mt-3">
                  Drag and drop files to upload <br /> or
                </h6>
                <FileUploader
                  name="file"
                  types={fileTypes}
                  handleChange={handleImageFileUpload}
                />
              </div>
            </div>
            <p className="mt-3">
              Single upload file should not be more than 10MB. Only the .lgs,
              .lgsx file types are allowed
            </p>
          </div>
        </Modal.Body>
        <div className="row m-0 p-3">
          <div className="col-md-6 col-sm-6">
            <button
              className="btn border w-100 bg-white"
              onClick={handleOpenCloseFileUploadModal}
            >
              Cancel
            </button>
          </div>
          <div className="col-md-6 col-sm-6">
            <button
              className="btn btn-primary w-100 cursor"
              onClick={handleOpenCloseFileUploadModal}
            >
              <FontAwesomeIcon className="mr-2" icon={faCloudUploadAlt} />{" "}
              Upload
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
