import React, { useState } from "react";

import { Divider, FormControlLabel, FormGroup, Switch } from "@mui/material";
import TextField from "@mui/material/TextField";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Modal } from "react-bootstrap";

import SearchBox from "pages/Common/SearchBox";
import { customerSearch, machineSearch } from "services/searchServices";
import { FONT_STYLE } from "pages/Common/constants";
import { Switch_label_Object } from "../equipmentMasterConstants";
import { equipmentMasterRequestObj } from "../masterConstents";
import { ca } from "date-fns/locale";
import { callPostApi, callPutApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { DATA_SVC_EQUIPMENT } from "services/CONSTANTS";

const addressDTOObj = {
  id: 0,
  addressId: 0,
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  fullAddress: "",
  district: "",
  regionOrState: "",
  country: "",
  createdBy: "",
  updatedBy: "",
};

const EquipmentMasterAddUpdateModal = ({ show, hideModal, handleSnack }) => {
  const [recordObj, setRecordObj] = useState({ ...equipmentMasterRequestObj });

  const [searchCustResults, setSearchCustResults] = useState([]);
  const [noOptionsCust, setNoOptionsCust] = useState(false);
  const [searchModelResults, setSearchModelResults] = useState([]);
  const [noOptionsModel, setNoOptionsModel] = useState(false);
  const [searchSerialResults, setSearchSerialResults] = useState([]);
  const [noOptionsSerial, setNoOptionsSerial] = useState(false);

  const [machineData, setMachineData] = useState({
    make: "",
    model: "",
    serialNo: "",
    smu: "",
    fleetNo: "",
    equipmentNumber: "",
    family: "",
  });

  const [customerData, setCustomerData] = useState({
    customerId: "",
    customerName: "",
    contactEmail: "",
    contactPhone: "",
  });

  const [addressRecord, setAddressRecord] = useState({ ...addressDTOObj });

  // Machine search based on model and serial number
  const handleMachineSearch = async (searchMachinefieldName, searchText) => {
    let searchQueryMachine = "";
    setSearchModelResults([]);
    setSearchSerialResults([]);

    if (searchMachinefieldName === "model") {
      machineData.model = searchText;
      searchQueryMachine = searchText ? searchMachinefieldName + "~" + searchText : "";
    } else if (searchMachinefieldName === "serialNo") {
      machineData.serialNo = searchText;
      searchQueryMachine = searchText
        ? machineData.model
          ? `model:${machineData.model} %26%26 makerSerialNumber~` + searchText
          : "makerSerialNumber~" + searchText
        : "";
    }
    if (searchQueryMachine) {
      await machineSearch(searchQueryMachine)
        .then((result) => {
          if (result) {
            if (searchMachinefieldName === "model") {
              if (result && result.length > 0) {
                setSearchModelResults(result);
                setNoOptionsModel(false);
              } else {
                setNoOptionsModel(true);
              }
            } else if (searchMachinefieldName === "serialNo") {
              if (result && result.length > 0) {
                setSearchSerialResults(result);
                setNoOptionsSerial(false);
              } else {
                setNoOptionsSerial(true);
              }
            }
          }
        })
        .catch((e) => {
          handleSnack("error", "Error occurred while searching the machine!");
        });
    } else {
      searchMachinefieldName === "model" ? setSearchModelResults([]) : setSearchSerialResults([]);
    }
  };

  // Select machine from the search result
  const handleModelSelect = (type, currentItem) => {
    if (type === "model") {
      setMachineData({
        ...machineData,
        maker: currentItem.maker,
        model: currentItem.model,
        serialNo: currentItem.makerSerialNumber,
        smu: currentItem.sensorId,
        fleetNo: currentItem.stockNumber,
        // warrantyId: currentItem.warrantyId,
        equipmentNumber: currentItem.equipmentNumber,
      });
      setRecordObj({
        ...recordObj,
        equipmentNumber: currentItem?.equipmentNumber,
        replacedEquipmentNumber: currentItem?.replacedEquipmentNumber,
        description: currentItem?.description,
        status: currentItem?.status,
        maker: currentItem.maker,
        makerSerialNumber: currentItem?.makerSerialNumber,
        technicalIdendificationNumber: currentItem?.technicalIdendificationNumber,
        motorBrand: currentItem?.motorBrand,
        engineModel: currentItem?.engineModel,
        motorSerialNumber: currentItem?.motorSerialNumber,
        typeOfApplication: currentItem?.typeOfApplication,
        unitOfMeasurement: currentItem?.unitOfMeasurement,
        mainWork: currentItem?.mainWork,
        modelPrefix: currentItem?.modelPrefix,
        model: currentItem?.model,
        brand: currentItem?.brand,
        market: currentItem?.market,
        productLine: currentItem?.productLine,
        productSegment: currentItem?.productSegment,
        productGroup: currentItem?.productGroup,
        // customer: currentItem?.customer,
        // warrantyType: "",

        owner: currentItem?.owner,
        geocode: currentItem?.geocode,
        sensorId: currentItem?.sensorId,
        usageType: currentItem?.usageType,
        usageDescription: currentItem?.usageDescription,
        serviceLetter: currentItem?.serviceLetter,
        serviceLetterId: currentItem?.serviceLetterId,
        installationDate: currentItem?.installationDate,
        endOfLife: currentItem?.endOfLife,
        endOfLifeUnit: currentItem?.endOfLifeUnit,
        plannedUsage: currentItem?.plannedUsage,
        unit: currentItem?.unit,
        operator: currentItem?.operator,
        contact: currentItem?.contact,
        warrantyAvailability: currentItem?.warrantyAvailability,
        yearOfManufacture: currentItem?.yearOfManufacture,
        lastOwner: currentItem?.lastOwner,
        fleetOwner: currentItem?.fleetOwner,
        movedInOrOutFlag: currentItem?.movedInOrOutFlag,
        previousLocation: currentItem?.previousLocation,
        newLocation: currentItem?.newLocation,
        movedInDate: currentItem?.movedInDate,
      });
      setSearchModelResults([]);
    } else if (type === "makerSerialNumber") {
      setRecordObj({
        ...recordObj,
        equipmentNumber: currentItem?.equipmentNumber,
        replacedEquipmentNumber: currentItem?.replacedEquipmentNumber,
        description: currentItem?.description,
        status: currentItem?.status,
        maker: currentItem.maker,
        makerSerialNumber: currentItem?.makerSerialNumber,
        technicalIdendificationNumber: currentItem?.technicalIdendificationNumber,
        motorBrand: currentItem?.motorBrand,
        engineModel: currentItem?.engineModel,
        motorSerialNumber: currentItem?.motorSerialNumber,
        typeOfApplication: currentItem?.typeOfApplication,
        unitOfMeasurement: currentItem?.unitOfMeasurement,
        mainWork: currentItem?.mainWork,
        modelPrefix: currentItem?.modelPrefix,
        model: currentItem?.model,
        brand: currentItem?.brand,
        market: currentItem?.market,
        productLine: currentItem?.productLine,
        productSegment: currentItem?.productSegment,
        productGroup: currentItem?.productGroup,
        // customer: currentItem?.customer,
        // warrantyType: "",

        owner: currentItem?.owner,
        geocode: currentItem?.geocode,
        sensorId: currentItem?.sensorId,
        usageType: currentItem?.usageType,
        usageDescription: currentItem?.usageDescription,
        serviceLetter: currentItem?.serviceLetter,
        serviceLetterId: currentItem?.serviceLetterId,
        installationDate: currentItem?.installationDate,
        endOfLife: currentItem?.endOfLife,
        endOfLifeUnit: currentItem?.endOfLifeUnit,
        plannedUsage: currentItem?.plannedUsage,
        unit: currentItem?.unit,
        operator: currentItem?.operator,
        contact: currentItem?.contact,
        warrantyAvailability: currentItem?.warrantyAvailability,
        yearOfManufacture: currentItem?.yearOfManufacture,
        lastOwner: currentItem?.lastOwner,
        fleetOwner: currentItem?.fleetOwner,
        movedInOrOutFlag: currentItem?.movedInOrOutFlag,
        previousLocation: currentItem?.previousLocation,
        newLocation: currentItem?.newLocation,
        movedInDate: currentItem?.movedInDate,
      });
      setMachineData({
        ...machineData,
        maker: currentItem.maker,
        model: currentItem.model,
        serialNo: currentItem.makerSerialNumber,
        smu: currentItem.sensorId,
        fleetNo: currentItem.stockNumber,
        // warrantyId: currentItem.warrantyId,
        equipmentNumber: currentItem.equipmentNumber,
      });

      //   if (currentItem?.replacedEquipmentNumber) {
      //     getSelectEquReplacementCode(currentItem?.replacedEquipmentNumber);
      //   }

      setCustomerData({ ...customerData, customerId: currentItem.currentClient, customerName: currentItem.customer });
      setSearchSerialResults([]);
    }
  };

  // change the input text field value
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setRecordObj({ ...recordObj, [name]: value });
  };

  // customer fields text change
  const handleCustomerTextChange = (e) => {
    const { name, value } = e.target;
    setCustomerData({ ...customerData, [name]: value });
  };

  // change the input text field value
  const handleSelectValueChange = (e, keyName) => {
    setRecordObj({ ...recordObj, [keyName]: e });
  };

  // Search Customer with customer ID
  const handleCustSearch = async (searchCustfieldName, searchText) => {
    setSearchCustResults([]);
    customerData.customerId = searchText;
    customerData.id = "";
    customerData.firstName = "";
    customerData.lastName = "";
    customerData.fullName = "";
    customerData.email = "";
    customerData.primaryContact = "";

    setAddressRecord({ ...addressDTOObj });

    // setAddressRecord({ ...addressDTOObj });

    if (searchText) {
      await customerSearch(searchCustfieldName + "~" + searchText)
        .then((result) => {
          if (result && result.length > 0) {
            setSearchCustResults(result);
            setNoOptionsCust(false);
          } else {
            setNoOptionsCust(true);
          }
        })
        .catch((e) => {
          handleSnack("error", "Error occurred while searching the customer!");
        });
    }
  };

  // select customer
  const handleCustSelect = (type, currentItem) => {
    setCustomerData({
      ...customerData,
      customerId: currentItem.customerId,
      contactEmail: currentItem.email,
      contactName: currentItem.contactName,
      customerGroup: currentItem.customerGroup,
      customerName: currentItem.fullName,
      customerSegment: currentItem.customerSegment,
      country: currentItem.addressDTO?.country,
      regionOrState: currentItem.addressDTO?.regionOrState,
    });

    setAddressRecord({ ...currentItem?.addressDTO });
    setSearchCustResults([]);
  };

  // click on submit button
  const handleSubmit = () => {
    // validate the form

    // const rObj = { ...recordObj, ...customerData, addressDTO: { ...addressRecord } };
    const rObj = {
      ...recordObj,
      ...customerData,
      addressDTO: {
        id: 3,
        addressId: 3,
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        fullAddress: "L 12, Kings Park Rd, West Perth, WESTERN AUSTRALIA, 6005 Australia ",
        district: "Kings Park Rd",
        regionOrState: "Perth",
        country: "AU",
        createdAt: "2022-07-12T01:46:16.129711",
        updatedAt: "2022-07-12T01:46:16.129711",
        createdBy: "Admin",
        updatedBy: "Admin",
      },
    };

    const rUrl = DATA_SVC_EQUIPMENT();

    if (recordObj?.id) {
      callPutApi(null, `${rUrl}/${recordObj?.id}`, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          handleSnack("success", "Equipment Updated Successfully!");
          hideModal();
        } else {
          handleSnack("error", "Something went wrong!");
        }
      });
    } else {
      callPostApi(
        null,
        rUrl,
        rObj,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            setRecordObj({ ...recordObj, id: responseData.id });
            handleSnack("success", "Equipment Created Successfully!");
            hideModal();
          } else {
            handleSnack("error", "Something went wrong!");
          }
        },
        (error) => {}
      );
    }

    // hideModal();
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        <Modal.Body>
          <h4>Add Equipment</h4>
          <div className="card border px-3">
            <div className="row input-fields mt-3">
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">MANUFACTURER</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="maker"
                    placeholder="Manufacturer"
                    value={machineData?.maker}
                    disabled
                    // onChange={handleInputTextChange}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">MODEL</label>
                  <SearchBox
                    value={machineData.model}
                    onChange={(e) => handleMachineSearch("model", e.target.value)}
                    type="model"
                    result={searchModelResults}
                    onSelect={handleModelSelect}
                    noOptions={noOptionsModel}
                    placeholder="Model"
                  />
                  <div className="css-w8dmq8">*Mandatory</div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">ENGINE MODEL</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="engineModal"
                    placeholder="Engine Model"
                    value={recordObj?.engineModal}
                    onChange={handleInputTextChange}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">OPERATUNG WEIGHT</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="operatingWeight"
                    placeholder="Operating Weight"
                    value={recordObj?.operatingWeight}
                    onChange={handleInputTextChange}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">NET FLYWHEEL POWER</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="netFlyWheelPower"
                    placeholder="Net Flywheel Power"
                    value={recordObj?.netFlyWheelPower}
                    onChange={handleInputTextChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <h5>Customer Details</h5>
          <div className="card border px-3">
            <div className="row input-fields mt-3">
              <div className="col-md-4 col-sm-4">
                <div class="form-group w-100">
                  <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER ID</label>
                  <SearchBox
                    value={customerData.customerId}
                    onChange={(e) => handleCustSearch("customerId", e.target.value)}
                    type="customerId"
                    result={searchCustResults}
                    onSelect={handleCustSelect}
                    noOptions={noOptionsCust}
                    placeholder="Customer Id"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER NAME</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="customerName"
                    value={customerData?.customerName}
                    placeholder="Customer Name"
                    onChange={handleCustomerTextChange}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CONTACT PERSON</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="contactName"
                    value={customerData?.contactName}
                    placeholder="Contact Person"
                    onChange={handleCustomerTextChange}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER GROUP</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="customerGroup"
                    value={customerData?.customerGroup}
                    placeholder="Customer Group"
                    onChange={handleCustomerTextChange}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER SEGMENT</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="customerSegment"
                    value={customerData?.customerSegment}
                    placeholder="Customer Segment"
                    onChange={handleCustomerTextChange}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">LAST OWNER</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="lastOwner"
                    value={recordObj?.lastOwner}
                    onChange={handleInputTextChange}
                    placeholder="Last Owner"
                  />
                </div>
              </div>
            </div>
          </div>
          <h5>Site Details</h5>
          <div className="card border px-3">
            <div className="row input-fields mt-3">
              <div className="col-md-4 col-sm-4">
                <div class="form-group w-100">
                  <label className="text-light-dark font-size-12 font-weight-500">FLEET NUMBER</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="fleetNumber"
                    placeholder="Fleet Number"
                    value={recordObj?.fleetNumber}
                    onChange={handleInputTextChange}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CONTACT ADDRESS</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="contactAddress"
                    placeholder="Contact Address"
                    value={recordObj?.contactAddress}
                    onChange={handleInputTextChange}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">GEO CODES</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="geocode"
                    placeholder="GEO Codes"
                    value={recordObj?.geocode}
                    onChange={handleInputTextChange}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">PRIMARY CONTACT</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="contact"
                    placeholder="Primary Contact"
                    value={recordObj?.contact}
                    onChange={handleInputTextChange}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">
                    <span className=" mr-2">MOVED IN DATE</span>
                  </label>
                  <div className="align-items-center date-box">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        className="form-controldate border-radius-10"
                        // minDate={generalData.claimRequestDate}
                        // maxDate={new Date()}
                        closeOnSelect
                        value={recordObj.movedInDate}
                        onChange={(e) => handleSelectValueChange(e, "movedInDate")}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">PREVIOUS LOCATION</label>
                  <FormGroup>
                    <FormControlLabel
                      style={{ alignItems: "start", marginLeft: 0 }}
                      control={
                        <Switch
                          checked={recordObj.previousLocation ? true : false}
                          onChange={(e) => setRecordObj({ ...recordObj, previousLocation: e.target.checked })}
                        />
                      }
                      labelPlacement="top"
                      // label={<span className="text-light-dark font-size-12 font-weight-500">REPLACEMENT</span>}
                    />
                  </FormGroup>
                  {/* <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="previousLocation"
                    placeholder="Previous Location"
                    value={recordObj?.previousLocation}
                    onChange={handleInputTextChange}
                  /> */}
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">NEW LOCATION</label>
                  <FormGroup>
                    <FormControlLabel
                      style={{ alignItems: "start", marginLeft: 0 }}
                      control={
                        <Switch
                          checked={recordObj.newLocation ? true : false}
                          onChange={(e) => setRecordObj({ ...recordObj, newLocation: e.target.checked })}
                        />
                      }
                      labelPlacement="top"
                      // label={<span className="text-light-dark font-size-12 font-weight-500">REPLACEMENT</span>}
                    />
                  </FormGroup>
                  {/* <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="newLocation"
                    placeholder="New Location"
                    value={recordObj?.newLocation}
                    onChange={handleInputTextChange}
                  /> */}
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">
                    <span className=" mr-2">MOVED IN/OUT</span>
                  </label>
                  <FormGroup>
                    <FormControlLabel
                      style={{ alignItems: "start", marginLeft: 0 }}
                      control={
                        <Switch
                          checked={recordObj.movedInOrOutFlag ? true : false}
                          onChange={(e) => setRecordObj({ ...recordObj, movedInOrOutFlag: e.target.checked })}
                        />
                      }
                      labelPlacement="top"
                      // label={<span className="text-light-dark font-size-12 font-weight-500">REPLACEMENT</span>}
                    />
                  </FormGroup>
                  {/* <div className="equipment-switch">
                    <Switch
                      // {...Switch_label_Object}
                      checked={recordObj.movedInOrOutFlag ? true : false}
                      onChange={(e) => setRecordObj({ ...recordObj, movedInOrOutFlag: e.target.checked })}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-md-6 col-sm-6">
              <button className="btn border-primary text-primary w-100" onClick={hideModal}>
                Cancel
              </button>
            </div>
            <div className="col-md-6 col-sm-6">
              <button className="btn bg-primary text-white w-100" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EquipmentMasterAddUpdateModal;
