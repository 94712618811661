import React, { useState } from "react";

import penIcon from "../../../assets/images/pen.png";
import deleteIcon from "../../../assets/icons/svg/delete.svg";

import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";

import $ from "jquery";
import Select from "react-select";
import { Modal } from "react-bootstrap";

import { ReadOnlyField } from "../components/ReadOnlyField";
import { CONSUMABLE_SEARCH_Q_OPTIONS, EXTWORK_SEARCH_Q_OPTIONS, FONT_STYLE_SELECT, GRID_STYLE } from "../CONSTANTS";
import SearchComponent from "../components/SearchComponent";
import { getConsumables, getExtWork, sparePartSearch } from "services/searchServices";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Tooltip } from "@mui/material";
import { RemoveConsumableItem, RemoveExtWorkItem } from "services/repairBuilderServices";

const initialConsQuery = [
  {
    id: 0,
    selectCategory: "",
    selectOperator: "",
    inputSearch: "",
    selectOptions: [],
    selectedOption: "",
  },
];
const initialExtWorkQuery = [
  {
    id: 0,
    selectCategory: "",
    selectOperator: "",
    inputSearch: "",
    selectOptions: [],
    selectedOption: "",
  },
];

const initialPartsQuery = [
  {
    id: 0,
    selectCategory: "",
    selectOperator: "",
    inputSearch: "",
    selectOptions: [],
    selectedOption: "",
  },
];

const WithSpareOtherCostAddUpdate = ({
  show,
  hideModal,
  handleSnack,
  operationData,
  miscTypeList = [],
  consumableTypeList = [],
  activityIdList = [],
  dimensionList = [],
  unitOfMeasureOptions = [],
}) => {
  const title = operationData?.componentCode + "-" + operationData?.componentCodeDescription;

  const initialConsumableItemData = {
    consumableType: "",
    consumableCode: "",
    description: "",
    quantity: "",
    unitOfMeasure: "",
    supplyingVendorCode: "",
    supplyingVendorName: "",
    vendor: "",
    unitPrice: 0.0,
    extendedPrice: 0.0,
    totalPrice: 0.0,
    usagePercentage: 100,
    // currency: serviceEstimateData.currency,
  };

  const initialExtWorkItemData = {
    activityId: "",
    activityName: "",
    description: "",
    supplyingVendorCode: "",
    supplyingVendorName: "",
    unitOfMeasure: "",
    unitPrice: 0.0,
    extendedPrice: 0.0,
    totalPrice: 0.0,
    estimatedHours: "",
    adjustedPrice: 0.0,
    dimensions: "",
  };

  const [tabValue, setTabValue] = useState("consumables");

  const [consumableViewOnly, setConsumableViewOnly] = useState(false);
  const [extWorkViewOnly, setExtWorkViewOnly] = useState(false);
  const [miscViewOnly, setMiscViewOnly] = useState(false);

  const [queryConsSearchSelector, setQueryConsSearchSelector] = useState(initialConsQuery);
  const [queryExtSearchSelector, setQueryExtSearchSelector] = useState(initialExtWorkQuery);
  const [queryPartsSearchSelector, setQueryPartsSearchSelector] = useState(initialPartsQuery);

  const [consumableItemData, setConsumableItemData] = useState(initialConsumableItemData);
  const [extWorkItemData, setExtWorkItemData] = useState(initialExtWorkItemData);

  // Consumable Header
  const [consumableData, setConsumableData] = useState({
    jobCode: "",
    jobCodeDescription: "",
    pricingMethod: "",
    totalPrice: 0.0,
    payer: "",
    flatRateIndicator: false,
    adjustedPrice: 0.0,
    basePrice: 0.0,
    percentageOfBase: 0,
  });

  // Ext Work Header
  const [extWorkData, setExtWorkData] = useState({
    jobCode: "",
    jobCodeDescription: "",
    pricingMethod: "",
    totalPrice: 0.0,
    payer: "",
    flatRateIndicator: false,
    adjustedPrice: 0.0,
    basePrice: 0.0,
    percentageOfBase: 0,
  });
  // Misc Header
  const [miscData, setMiscData] = useState({
    jobCode: "",
    jobCodeDescription: "",
    pricingMethod: "",
    totalPrice: 0.0,
    payer: "",
    flatRateIndicator: false,
    adjustedPrice: 0.0,
    type: "",
    basePrice: 0.0,
    percentageOfBase: 0,
  });
  const [masterData, setMasterData] = useState([]);

  let [consumableItems, setConsumableItems] = useState([]);
  let [extWorkItems, setExtWorkItems] = useState([]);

  const [consumableItemOpen, setConsumableItemOpen] = React.useState(false);
  const [extWorkItemOpen, setExtWorkItemOpen] = React.useState(false);

  const [searchResultConsOpen, setSearchResultConsOpen] = useState(false);
  const [searchResultPartsOpen, setSearchResultPartsOpen] = useState(false);
  const [searchResultExtWorkOpen, setSearchResultExtWorkOpen] = useState(false);

  // Once parts are selected to add clear the search results
  const clearFilteredData = () => {
    setMasterData([]);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Consumable Search
  const handleQuerySearchClick = async (type) => {
    $(".scrollbar").css("display", "none");
    var searchStr = "";
    var querySearchSelector = type === "consumables" ? queryConsSearchSelector : type === "parts" ? queryPartsSearchSelector : queryExtSearchSelector;
    querySearchSelector.map(function (item, i) {
      if (i === 0 && item.selectCategory?.value && item.inputSearch) {
        searchStr = item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      } else if (item.selectCategory?.value && item.inputSearch && item.selectOperator?.value) {
        searchStr = searchStr + " " + item.selectOperator.value + " " + item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      } else {
        searchStr = "";
      }
      return searchStr;
    });

    try {
      if (searchStr) {
        if (type === "consumables") {
          const res = await getConsumables(searchStr);
          setMasterData(res);
          setSearchResultConsOpen(true);
        } else if (type === "extwork") {
          const res = await getExtWork(searchStr);
          setMasterData(res);
          setSearchResultExtWorkOpen(true);
        } else if (type === "parts") {
          const res = await sparePartSearch(searchStr);
          setMasterData(res);
          setSearchResultPartsOpen(true);
        }
      } else {
        handleSnack("info", "Please fill the search criteria!");
      }
    } catch (err) {
      handleSnack("error", "Error occurred while fetching results!");
    }
  };

  // Open consumable item to view or edit
  const openConsumableRow = (row) => {
    setConsumableItemData({
      ...row,
      consumableType: consumableTypeList.find((element) => element.value === row.consumableType),
      unitPrice: row.unitPrice ? row.unitPrice : 0,
      extendedPrice: row.extendedPrice ? row.extendedPrice : 0,
      totalPrice: row.totalPrice ? row.totalPrice : 0,
    });
    // setAddPartModalTitle(row?.groupNumber + " | " + row?.partNumber);
    setConsumableItemOpen(true);
  };

  //Remove Consumable Item
  const handleDeleteConsumableItem = (consItemId) => {
    RemoveConsumableItem(consumableData.id, consItemId)
      .then((res) => {
        handleSnack("success", res);
        // // populateConsItems(consumableData);
        // populateServiceEstimation("consumables");
      })
      .catch((e) => {
        console.log(e);
        handleSnack("error", "Error occurred while removing the consumable item");
      });
  };

  // Open ext work item to view or edit
  const openExtWorkRow = (row) => {
    // console.log(row.activityId);
    setExtWorkItemData({
      ...row,
      activityId: activityIdList.find((element) => element.value === row.activityId),
      dimensions: dimensionList.find((element) => element.value === row.dimensions),
      unitOfMeasure: unitOfMeasureOptions.find((element) => element.value === row.unitOfMeasure),
      unitPrice: row.unitPrice ? row.unitPrice : 0,
      extendedPrice: row.extendedPrice ? row.extendedPrice : 0,
      totalPrice: row.totalPrice ? row.totalPrice : 0,
    });
    setExtWorkItemOpen(true);
  };

  //Remove Ext work Item
  const handleDeleteExtWorkItem = (extWorkItemId) => {
    RemoveExtWorkItem(extWorkData.id, extWorkItemId)
      .then((res) => {
        handleSnack("success", res);
        // // populateExtWorkItems(extWorkData);
        // populateServiceEstimation("extwork");
      })
      .catch((e) => {
        console.log(e);
        handleSnack("error", "Error occurred while removing the ext work item!");
      });
  };

  const columnsConsumables = [
    {
      field: "consumableCode",
      headerName: "Consumable ID",
      flex: 1,
      width: 70,
    },
    {
      field: "consumableType",
      headerName: "Type",
      flex: 1,
      width: 70,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      width: 130,
    },
    { field: "quantity", headerName: " Quantity", flex: 1, width: 130 },
    {
      field: "unitOfMeasure",
      headerName: "Unit of measure",
      flex: 1,
      width: 130,
    },
    { field: "supplyingVendorCode", headerName: "Vendor", flex: 1, width: 130 },
    { field: "unitPrice", headerName: "Unit Price", flex: 1, width: 130 },
    {
      field: "extendedPrice",
      headerName: "Extended price",
      flex: 1,
      width: 130,
    },
    { field: "currency", headerName: "Currency", flex: 1, width: 130 },
    { field: "usagePercentage", headerName: "% Usage", flex: 1, width: 130 },
    { field: "totalPrice", headerName: "Total price", flex: 1, width: 130 },
    {
      field: "Actions",
      headerName: "Actions",
      type: "actions",
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="Edit">
                  <img className="m-1" src={penIcon} alt="Edit" />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            onClick={() => openConsumableRow(params.row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="Remove">
                  <img className="m-1" src={deleteIcon} alt="Delete" />
                </Tooltip>
              </div>
            }
            label="Delete"
            onClick={() => handleDeleteConsumableItem(params.row.id)}
            color="inherit"
          />,
        ];
      },
      flex: 1,
      width: 130,
    },
  ];

  const columnsExternal = [
    { field: "activityId", headerName: "Activity ID", flex: 1, width: 70 },
    { field: "activityName", headerName: "Activity Name", flex: 1, width: 70 },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      width: 70,
    },
    {
      field: "estimatedHours",
      headerName: "Estimated Effort",
      flex: 1,
      width: 130,
    },
    { field: "unitPrice", headerName: "Unit Price", flex: 1, width: 130 },
    {
      field: "extendedPrice",
      headerName: "Extended price",
      flex: 1,
      width: 130,
    },
    { field: "totalPrice", headerName: "Total price", flex: 1, width: 130 },
    { field: "dimensions", headerName: "Dimension", flex: 1, width: 130 },
    {
      field: "supplyingVendorName",
      headerName: "Supplying Vendor",
      flex: 1,
      width: 130,
    },
    {
      field: "Actions",
      headerName: "Actions",
      type: "actions",
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="Edit">
                  <img className="m-1" src={penIcon} alt="Edit" />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            onClick={() => openExtWorkRow(params.row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="Remove">
                  <img className="m-1" src={deleteIcon} alt="Delete" />
                </Tooltip>
              </div>
            }
            label="Delete"
            onClick={() => handleDeleteExtWorkItem(params.row.id)}
            color="inherit"
          />,
        ];
      },
      flex: 1,
      width: 130,
    },
  ];

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        <Modal.Header className="modal-header-border">
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 bg-white">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList className="custom-tabs-div" onChange={handleTabChange}>
                  <Tab label="Consumables" value="consumables" />
                  <Tab label="External Work" value="extwork" />
                  <Tab label="Other misc." value="othrMisc" />
                </TabList>
              </Box>
              <TabPanel value="consumables">
                {!consumableViewOnly ? (
                  <div className="row mt-2 input-fields">
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">JOB CODE</label>
                        <input type="text" disabled class="form-control border-radius-10 text-primary" value={consumableData.jobCode} />
                        <div className="css-w8dmq8">*Mandatory</div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">JOB CODE DESCRIPTION</label>
                        <input type="text" disabled class="form-control border-radius-10 text-primary" value={consumableData.jobCodeDescription} />
                        <div className="css-w8dmq8">*Mandatory</div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4"></div>
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">PAYER</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={consumableData.payer}
                          onChange={(e) => setConsumableData({ ...consumableData, payer: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">NET PRICE</label>
                        <input type="text" disabled class="form-control border-radius-10 text-primary" value={consumableData.totalPrice} />
                        <div className="css-w8dmq8">*Mandatory</div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div class="form-group mt-3 mb-0 text-right">
                        <button
                          type="button"
                          className="btn btn-light bg-primary text-white"
                          //   onClick={updateConsumableHeader}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row mt-4">
                    <ReadOnlyField label="JOB CODE" value={consumableData.jobCode} className="col-md-4 col-sm-4" />
                    <ReadOnlyField label="JOB CODE DESCRIPTION" value={consumableData.jobCodeDescription} className="col-md-4 col-sm-4" />
                    <ReadOnlyField label="PAYER" value={consumableData.payer} className="col-md-4 col-sm-4" />

                    <ReadOnlyField label="PRICE METHOD" value={consumableData.pricingMethod?.label} className="col-md-4 col-sm-4" />

                    <ReadOnlyField label="NET PRICE" value={consumableData.totalPrice} className="col-md-4 col-sm-4" />
                  </div>
                )}
                <hr />
                <div className="">
                  <div className="bg-primary px-3 mb-3 border-radius-6">
                    <div className="row align-items-center">
                      <div className="col-10 mr-5">
                        <div className="d-flex align-items-center bg-primary w-100">
                          <div className="d-flex mr-3" style={{ whiteSpace: "pre" }}>
                            <h5 className="mr-2 mb-0 text-white">
                              <span>Consumables</span>
                            </h5>
                          </div>
                          <SearchComponent
                            querySearchSelector={queryConsSearchSelector}
                            setQuerySearchSelector={setQueryConsSearchSelector}
                            clearFilteredData={clearFilteredData}
                            handleSnack={handleSnack}
                            searchAPI={getConsumables}
                            type={"consumables"}
                            searchClick={handleQuerySearchClick}
                            options={CONSUMABLE_SEARCH_Q_OPTIONS}
                            color={"white"}
                            buttonText={"ADD ITEM"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <DataGrid
                    sx={GRID_STYLE}
                    rows={consumableItems}
                    columns={columnsConsumables}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    autoHeight
                    // checkboxSelection
                    // onCellClick={(e) => handleRowClick(e)}
                  />
                </div>
              </TabPanel>
              <TabPanel value="extwork">
                {!extWorkViewOnly ? (
                  <div className="row mt-2 input-fields">
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">JOB CODE</label>
                        <input type="text" disabled class="form-control border-radius-10 text-primary" value={extWorkData.jobCode} />
                        <div className="css-w8dmq8">*Mandatory</div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">JOB CODE DESCRIPTION</label>
                        <input type="text" disabled class="form-control border-radius-10 text-primary" value={extWorkData.jobCodeDescription} />
                        <div className="css-w8dmq8">*Mandatory</div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4"></div>
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">PAYER</label>
                        <input
                          type="text"
                          class="form-control border-radius-10 text-primary"
                          value={extWorkData.payer}
                          onChange={(e) => setExtWorkData({ ...extWorkData, payer: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">NET PRICE</label>
                        <input type="text" disabled class="form-control border-radius-10 text-primary" value={extWorkData.totalPrice} />
                        <div className="css-w8dmq8">*Mandatory</div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div class="form-group mt-3 mb-0 text-right">
                        <button
                          type="button"
                          className="btn btn-light bg-primary text-white"
                          //   onClick={updateExtWorkHeader}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row mt-4">
                    <ReadOnlyField label="JOB CODE" value={extWorkData.jobCode} className="col-md-4 col-sm-4" />
                    <ReadOnlyField label="JOB CODE DESCRIPTION" value={extWorkData.jobCodeDescription} className="col-md-4 col-sm-4" />
                    <ReadOnlyField label="PAYER" value={extWorkData.payer} className="col-md-4 col-sm-4" />
                    <ReadOnlyField label="PRICE METHOD" value={extWorkData.pricingMethod?.label} className="col-md-4 col-sm-4" />

                    <ReadOnlyField label="NET PRICE" value={extWorkData.totalPrice} className="col-md-4 col-sm-4" />
                  </div>
                )}
                <hr />

                <div className="">
                  <div className="bg-primary px-3 mb-3 border-radius-6">
                    <div className="row align-items-center">
                      <div className="col-10 mr-5">
                        <div className="d-flex align-items-center bg-primary w-100">
                          <div className="d-flex mr-3" style={{ whiteSpace: "pre" }}>
                            <h5 className="mr-2 mb-0 text-white">
                              <span>External Work</span>
                            </h5>
                          </div>
                          <SearchComponent
                            querySearchSelector={queryExtSearchSelector}
                            setQuerySearchSelector={setQueryExtSearchSelector}
                            clearFilteredData={clearFilteredData}
                            handleSnack={handleSnack}
                            searchAPI={getExtWork}
                            type={"extwork"}
                            searchClick={handleQuerySearchClick}
                            options={EXTWORK_SEARCH_Q_OPTIONS}
                            color={"white"}
                            buttonText="ADD ITEM"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <DataGrid sx={GRID_STYLE} rows={extWorkItems} columns={columnsExternal} pageSize={5} autoHeight rowsPerPageOptions={[5]} />
                </div>
              </TabPanel>
              <TabPanel value="othrMisc">
                {!miscViewOnly ? (
                  <div className="row mt-2 input-fields">
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">JOB CODE</label>
                        <input type="text" disabled class="form-control border-radius-10 text-primary" value={miscData.jobCode} />
                        <div className="css-w8dmq8">*Mandatory</div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">JOB CODE DESCRIPTION</label>
                        <input type="text" disabled class="form-control border-radius-10 text-primary" value={miscData.jobCodeDescription} />
                        <div className="css-w8dmq8">*Mandatory</div>
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">TYPE OF MISC.</label>
                        <Select
                          onChange={(e) => setMiscData({ ...miscData, type: e })}
                          // closeMenuOnSelect={false}
                          options={miscTypeList}
                          value={miscData.type}
                          isMulti
                          styles={FONT_STYLE_SELECT}
                        />
                        <div className="css-w8dmq8">*Mandatory</div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">NET PRICE</label>
                        <input type="text" disabled class="form-control border-radius-10 text-primary" value={miscData.totalPrice} />
                        <div className="css-w8dmq8">*Mandatory</div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">PAYER</label>
                        <input
                          type="text"
                          class="form-control border-radius-10 text-primary"
                          value={miscData.payer}
                          onChange={(e) => setMiscData({ ...miscData, payer: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div class="form-group mt-3 mb-0 text-right">
                        <button
                          type="button"
                          className="btn btn-light bg-primary text-white"
                          //   onClick={updateMiscHeader}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row mt-4">
                    <ReadOnlyField label="JOB CODE" value={miscData.jobCode} className="col-md-4 col-sm-4" />
                    <ReadOnlyField label="JOB CODE DESCRIPTION" value={miscData.jobCodeDescription} className="col-md-4 col-sm-4" />
                    <ReadOnlyField label="PAYER" value={miscData.payer} className="col-md-4 col-sm-4" />
                    <ReadOnlyField
                      label="TYPE OF MISC."
                      value={
                        <>
                          {miscData.type?.map((element) => (
                            <div>{element.label}</div>
                          ))}
                        </>
                      }
                      className="col-md-4 col-sm-4"
                    />
                    <ReadOnlyField label="PRICE METHOD" value={miscData.pricingMethod?.label} className="col-md-4 col-sm-4" />

                    <ReadOnlyField label="NET PRICE" value={miscData.totalPrice} className="col-md-4 col-sm-4" />
                  </div>
                )}
              </TabPanel>
            </TabContext>
          </Box>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WithSpareOtherCostAddUpdate;
