import React from "react";

import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import { Tooltip } from "@mui/material";

const ConstructionOrderShippingDtlsCard = ({
  orderRecord,
  handleShowHideShippingDetailsModal,
  showIcon = false,
  className = "px-4 py-2",
  style = null,
}) => {
  return (
    <div className="card border" style={{ overflow: "hidden", ...style }}>
      <div className={`d-flex justify-content-between align-items-center bg-green ${className} text-white`}>
        <h6 className="bg-green text-white mb-0">Shipping Details</h6>
        {showIcon && (
          // <Tooltip title="Add New Address">
          <Tooltip title="View Shipping Details">
            <AddLocationAltIcon className="cursor" onClick={handleShowHideShippingDetailsModal} />
          </Tooltip>
        )}
      </div>
      <div className={className}>
        <div className="d-flex justify-content-between py-2">
          <p className="mb-0">Delivery Type</p>
          <h6 className="mb-0">
            <b>{orderRecord?.deliveryType?.label || "Standard"}</b>
          </h6>
        </div>
        <div className="hr my-0"></div>
        <div className="d-flex justify-content-between py-2">
          <p className="mb-0">Delivery Priority</p>
          <h6 className="mb-0">
            <b>{orderRecord?.deliveryPriority?.label || "-"}</b>
          </h6>
        </div>
        <div className="hr my-0"></div>
        <div className="d-flex justify-content-between py-2">
          <p className="mb-0">Lead Time</p>
          <h6 className="mb-0">
            <b>{"$" + (orderRecord?.leadTime || 0) + " Days" || "-"}</b>
          </h6>
        </div>
        <div className="hr my-0"></div>
        <div className="d-block py-2">
          <p className="mb-0">Address</p>
          <h6 className="mb-0">
            <b>{orderRecord?.shippingAddress || orderRecord?.pickUpInst || "-"}</b>
          </h6>
        </div>
      </div>
    </div>
  );
};

export default ConstructionOrderShippingDtlsCard;
