import React, { useEffect, useState } from "react";

import { faAngleDown, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ReplayIcon from "@mui/icons-material/Replay";
import EditIcon from "@mui/icons-material/EditOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import ReviewAddIcon from "@mui/icons-material/CreateNewFolderOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import copyIcon from "../../../assets/icons/svg/Copy.svg";
import deleteIcon from "../../../assets/icons/svg/delete.svg";
import folderaddIcon from "../../../assets/icons/svg/folder-add.svg";
import shareIcon from "../../../assets/icons/svg/share.svg";
import uploadIcon from "../../../assets/icons/svg/upload.svg";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Dropdown, DropdownButton } from "react-bootstrap";

import { ERROR_MAX_VERSIONS, FONT_STYLE, FONT_STYLE_SELECT, STATUS_OPTIONS } from "../CONSTANTS";

import { useHistory } from "react-router-dom";

import Select from "react-select";

import CustomizedSnackbar from "pages/Common/CustomSnackBar";
import {
  createBuilderVersion,
  fetchBuilderDetails,
  fetchBuilderSegmentOperationItems,
  fetchBuilderVersionDet,
  updateBuilderCustomer,
  updateBuilderEstimation,
  updateBuilderGeneralDet,
  updateBuilderMachine,
  updateBuilderPrice,
  updateBuilderStatus,
} from "services/repairBuilderServices";
import ModalCreateVersion from "../components/ModalCreateVersion";
import { REPAIR_WITHOUT_SPARE_PARTS_NEW } from "navigation/CONSTANTS";
import { Step, StepLabel, Stepper, TextField, Tooltip } from "@mui/material";
import LoadingProgress from "../components/Loader";
import SearchBox from "../components/SearchBox";
import { customerSearch, machineSearch } from "services/searchServices";
import { ReadOnlyField } from "../components/ReadOnlyField";
import Moment from "react-moment";
import PriceMethodTable from "../components/PriceMethodTable";
import PriceSummaryTable from "../components/PriceSummaryTable";
import Validator from "utils/validator";
import SegmentsMaster from "./SegmentsMaster";
import ServiceSegmentMaster from "./ServiceSegmentMaster";
import SegmentAccordion from "./SegmentAccordion";
import ServiceEstimationApproval from "./ServiceEstimationApproval";
import ServiceEstimationOrder from "./ServiceEstimationOrder";
import ServiceEstimateInvoice from "./ServiceEstimateInvoice";
import SparePartsStepper from "./Stepper/SparePartsStepper";

const validityOptions = [
  { value: 15, label: "15 days" },
  { value: 30, label: "1 month" },
  { value: 45, label: "45 days" },
  { value: 60, label: "2 months" },
];

const salesOfficeOptions = [
  { value: "Location1", label: "Location1" },
  { value: "Location2", label: "Location2" },
  { value: "Location3", label: "Location3" },
  { value: "Location4", label: "Location4" },
];

const cartOrderSteps = ["Estimation", "Approval", "Order", "Invoice"];

const WithoutSparePartsDetails = (props) => {
  const history = useHistory();
  const { state } = props.location;
  const [searchCustResults, setSearchCustResults] = useState([]);
  const [searchModelResults, setSearchModelResults] = useState([]);
  const [searchSerialResults, setSearchSerialResults] = useState([]);

  const [activeStep, setActiveStep] = useState(0);

  const [bId, setBId] = useState("");
  const [builderId, setBuilderId] = useState("");
  const [builderType, setBuilderType] = useState("");

  const [value, setValue] = useState("customer");

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const [templateOpen, setTemplateOpen] = useState(false);

  const [openQuotePopup, setOpenQuotePopup] = useState(false);

  const [versionOpen, setVersionOpen] = useState(false);
  const [versionDescription, setVersionDescription] = useState("");
  const [selectedVersion, setSelectedVersion] = useState({ label: "Version 1", value: 1 });

  const [activeElement, setActiveElement] = useState({
    name: "header",
    bId: "",
    sId: "",
    oId: "",
    builderType: "",
  });

  const [headerLoading, setHeaderLoading] = useState(false);

  const [selBuilderStatus, setSelBuilderStatus] = useState({ label: "Draft", value: "DRAFT" });
  const [builderVersionOptions, setBuilderVersionOptions] = useState([{ label: "Version 1", value: 1 }]);

  const [noOptionsCust, setNoOptionsCust] = useState(false);
  const [noOptionsModel, setNoOptionsModel] = useState(false);
  const [noOptionsSerial, setNoOptionsSerial] = useState(false);

  const [savedHeaderDetails, setSavedBuilderDetails] = useState([]);

  const currencyOptions = [{ value: "USD", label: "USD" }];

  const [viewOnlyTab, setViewOnlyTab] = useState({
    custViewOnly: false,
    machineViewOnly: false,
    generalViewOnly: false,
    estViewOnly: false,
    priceViewOnly: false,
  });

  const [segments, setSegments] = useState([]);
  const [segmentItems, setSegmentItems] = useState(null);

  const [customerData, setCustomerData] = useState({
    source: "User Generated",
    customerID: "",
    customerName: "",
    contactEmail: "",
    contactName: "",
    contactPhone: "",
    customerGroup: "",
    customerSegment: "",
    regionOrState: "",
    country: "",
  });
  const [machineData, setMachineData] = useState({
    make: "",
    family: "",
    model: "",
    serialNo: "",
    smu: "",
    fleetNo: "",
    registrationNo: "",
    chasisNo: "",
    productSegment: "",
    productGroup: "",
  });
  const [generalData, setGeneralData] = useState({
    estimationDate: new Date(),
    estimationNo: "",
    description: "",
    reference: "",
    validity: null,
    version: "",
  });
  const [estimationData, setEstimationData] = useState({
    preparedBy: "user1",
    approvedBy: "user1",
    preparedOn: new Date(),
    revisedBy: "user1",
    revisedOn: new Date(),
    salesOffice: null,
  });

  const [pricingData, setPricingData] = useState({
    netPrice: 0.0,
    priceDate: new Date(),
    adjustedPrice: 0.0,
    currency: "",
    priceDetailDTO: [],
    priceEstimateDTO: [],
  });

  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");

  //  close the snack bas toast message box
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  // snack bar info maassge toast bar
  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  useEffect(() => {
    if (state && state.type === "new") {
      setBuilderId(state.builderId);
      setBId(state.bId);
      setBuilderType(state.builderType);
      setGeneralData({ ...generalData, estimationNo: state.builderId });
    } else if (state) {
      setBuilderId(state.builderId);
      setBId(state.bId);
      setBuilderType(state.builderType);
      fetchAllDetails(state.bId);
    }
    setActiveElement({ ...activeElement, builderType: state.builderType });
  }, []);

  // fetch spare parts(Servuce Estimates Details)
  const fetchAllDetails = async (builderId) => {
    var versionHistoryData = {
      builderId: "",
      exitingType: "repair",
      editable: false,
    };
    localStorage.setItem("exitingType", JSON.stringify(versionHistoryData));
    if (builderId) {
      setHeaderLoading(true);
      await fetchBuilderDetails(builderId)
        .then((result) => {
          setBuilderId(result.builderId);
          populateHeader(result);
        })
        .catch((err) => {
          console.log(err);
          handleSnack("error", "Error occured while fetching header details");
        });

      await fetchBuilderSegmentOperationItems(`builder_id=${builderId}&segment_id=0&operation_id=0`).then((result) => {
        // await fetchBuilderSegmentOperationItems(`builder_id=${198}&segment_id=0&operation_id=0`).then((result) => {
        console.log("fetchBuilderSegmentOperationItems :: ", result);

        setSegmentItems(result);
      });
      setHeaderLoading(false);
    }
  };

  // create a new version
  const createVersion = async (versionDesc) => {
    await createBuilderVersion(bId, versionDesc)
      .then((result) => {
        setVersionOpen(false);
        setBId(result.id);
        setSelectedVersion({
          label: "Version " + result.versionNumber,
          value: result.versionNumber,
        });
        populateHeader(result);
        setVersionDescription("");
        handleSnack("success", `Version ${result.versionNumber} has been created`);
      })
      .catch((err) => {
        setVersionOpen(false);

        if (err.message === "Not Allowed") handleSnack("warning", ERROR_MAX_VERSIONS);
        else handleSnack("error", "Error occurred while creating builder version");
        setVersionDescription("");
      });
  };

  // populate the header details
  const populateHeader = (result) => {
    setSavedBuilderDetails(result);
    setViewOnlyTab({
      custViewOnly: result.customerId ? true : false,
      machineViewOnly: result.serialNo ? true : false,
      generalViewOnly: result.estimationDate ? true : false,
      estViewOnly: result.preparedBy ? true : false,
      priceViewOnly: result.priceMethod !== "EMPTY" && result.priceMethod !== null && result.priceMethod !== "" ? true : false,
    });
    setBId(result.id);
    setSelBuilderStatus(STATUS_OPTIONS.filter((x) => x.value === result.status)[0]);
    let versions = result.versionList?.map((versionNo) => ({
      value: versionNo,
      label: "Version " + versionNo,
    }));
    setBuilderVersionOptions(versions);
    setSelectedVersion({
      label: "Version " + result.versionNumber,
      value: result.versionNumber,
    });

    populateCustomerData(result);
    populateMachineData(result);
    populateGeneralData(result);
    populateEstData(result);
    populatePricingData(result);
    setSegments(result.segmentDTOs);
  };

  // populate the header Customer tab details
  const populateCustomerData = (result) => {
    setCustomerData({
      customerID: result.customerId ? result.customerId : "",
      contactEmail: result.contactEmail ? result.contactEmail : "",
      contactName: result.contactName ? result.contactName : "",
      contactPhone: result.contactPhone ? result.contactPhone : "",
      customerGroup: result.customerGroup ? result.customerGroup : "",
      customerName: result.customerName ? result.customerName : "",
      source: result.source ? result.source : "User Generated",
      customerSegment: result.customerSegment ? result.customerSegment : "",
      country: result.country ? result.country : "",
      regionOrState: result.regionOrState ? result.regionOrState : "",
    });
    setSearchCustResults([]);
  };

  // populate the header machine tab details
  const populateMachineData = (result) => {
    setMachineData({
      make: result.make ? result.make : "",
      family: result.family ? result.family : "",
      model: result.model ? result.model : "",
      serialNo: result.serialNo ? result.serialNo : "",
      fleetNo: result.fleetNo ? result.fleetNo : "",
      smu: result.smu ? result.smu : "",
      registrationNo: result.registrationNo ? result.registrationNo : "",
      chasisNo: result.chasisNo ? result.chasisNo : "",
      productSegment: result.productSegment ? result.productSegment : "",
      productGroup: result.productGroup ? result.productGroup : "",
    });
    setSearchModelResults([]);
    setSearchSerialResults([]);
  };

  // populate the header genral tab details
  const populateGeneralData = (result) => {
    setGeneralData({
      description: result.description ? result.description : "",
      estimationDate: result.estimationDate ? result.estimationDate : new Date(),
      estimationNo: result.builderId ? result.builderId : state.builderId,
      reference: result.reference ? result.reference : "",
      validity:
        result.validityDays && result.validityDays !== "EMPTY"
          ? validityOptions.find((element) => element.value === result.validityDays)
          : { label: "", value: "" },
      version: result.versionNumber ? result.versionNumber : "",
    });
  };

  // populate the header estimation details
  const populateEstData = (result) => {
    setEstimationData({
      approvedBy: result.approver ? result.approver : "",
      preparedBy: result.preparedBy ? result.preparedBy : "",
      preparedOn: result.preparedOn ? result.preparedOn : new Date(),
      revisedBy: result.revisedBy ? result.revisedBy : "",
      revisedOn: result.revisedOn ? result.revisedOn : new Date(),
      salesOffice: result.salesOffice ? salesOfficeOptions.find((element) => element.value === result.salesOffice) : { label: "", value: "" },
    });
  };

  // populate the header price tab details
  const populatePricingData = (result) => {
    setPricingData({
      priceDate: result.priceDate ? result.priceDate : new Date(),
      // priceMethod:
      //   result.priceMethod && result.priceMethod !== "EMPTY"
      //     ? priceMethodOptions.find(
      //         (element) => element.value === result.priceMethod
      //       )
      //     : { label: "", value: "" },
      netPrice: result.netPrice ? result.netPrice : 0.0,
      adjustedPrice: result.adjustedPrice ? result.adjustedPrice : 0.0,
      currency: result.currency ? currencyOptions.find((element) => element.value === result.currency) : { label: "", value: "" },
      priceDetailDTO: result.priceDetailDTO,
      priceEstimateDTO: result.priceEstimateDTO,
    });
  };

  // handle versiom
  const handleVersion = (e) => {
    setSelectedVersion(e);
    fetchBuilderVersionDet(builderId, e.value).then((result) => {
      populateHeader(result);
    });
    setActiveElement({ name: "header", bId, sId: "", oId: "" });
  };

  // Update the status of the builder : Active, Revised etc.
  const handleBuilderStatus = async (e) => {
    await updateBuilderStatus(bId, e.value)
      .then((result) => {
        setSelBuilderStatus(e);
        setActiveElement({ ...activeElement, builderStatus: e.value });
        handleSnack("success", "Status has been updated!");
      })
      .catch((err) => {
        handleSnack("error", `Failed to update the status!`);
      });
  };

  // Search Customer with customer ID
  const handleCustSearch = async (searchCustfieldName, searchText) => {
    setSearchCustResults([]);
    customerData.customerID = searchText;
    if (searchText) {
      await customerSearch(searchCustfieldName + "~" + searchText)
        .then((result) => {
          if (result && result.length > 0) {
            setSearchCustResults(result);
            setNoOptionsCust(false);
          } else {
            setNoOptionsCust(true);
          }
        })
        .catch((e) => {
          handleSnack("error", "Error occurred while searching the customer!");
        });
    }
  };

  // Select the customer from search result
  const handleCustSelect = (type, currentItem) => {
    setCustomerData({
      ...customerData,
      customerID: currentItem.customerId,
      contactEmail: currentItem.email,
      contactName: currentItem.contactName,
      customerGroup: currentItem.customerGroup,
      customerName: currentItem.fullName,
      customerSegment: currentItem.customerSegment,
      country: currentItem.addressDTO?.country,
      regionOrState: currentItem.addressDTO?.regionOrState,
    });
    console.log(currentItem);
    setSearchCustResults([]);
  };

  //Individual customer field value change
  const handleCustomerDataChange = (e) => {
    var value = e.target.value;
    var name = e.target.name;
    setCustomerData({
      ...customerData,
      [name]: value,
    });
  };

  // Machine search based on model and serial number
  const handleMachineSearch = async (searchMachinefieldName, searchText) => {
    let searchQueryMachine = "";
    setSearchModelResults([]);
    setSearchSerialResults([]);

    if (searchMachinefieldName === "model") {
      machineData.model = searchText;
      searchQueryMachine = searchText ? searchMachinefieldName + "~" + searchText : "";
    } else if (searchMachinefieldName === "serialNo") {
      machineData.serialNo = searchText;
      searchQueryMachine = searchText
        ? machineData.model
          ? `model:${machineData.model} AND equipmentNumber~` + searchText
          : "equipmentNumber~" + searchText
        : "";
    }
    if (searchQueryMachine) {
      await machineSearch(searchQueryMachine)
        .then((result) => {
          if (result) {
            if (searchMachinefieldName === "model") {
              if (result && result.length > 0) {
                setSearchModelResults(result);
                setNoOptionsModel(false);
              } else {
                setNoOptionsModel(true);
              }
            } else if (searchMachinefieldName === "serialNo") {
              if (result && result.length > 0) {
                setSearchSerialResults(result);
                setNoOptionsSerial(false);
              } else {
                setNoOptionsSerial(true);
              }
            }
          }
        })
        .catch((e) => {
          handleSnack("error", "Error occurred while searching the machine!");
        });
    } else {
      searchMachinefieldName === "model" ? setSearchModelResults([]) : setSearchSerialResults([]);
    }
  };

  // Select machine from the search result
  const handleModelSelect = (type, currentItem) => {
    if (type === "model") {
      setMachineData({
        ...machineData,
        model: currentItem.model,
      });
      setSearchModelResults([]);
    } else if (type === "equipmentNumber") {
      setMachineData({
        ...machineData,
        model: currentItem.model,
        fleetNo: currentItem.stockNumber,
        serialNo: currentItem.equipmentNumber,
        smu: currentItem.sensorId,
        make: currentItem.maker,
        family: currentItem.market,
        productGroup: currentItem.productGroup,
        productSegment: currentItem.productSegment,
      });
      setSearchSerialResults([]);
    }
  };

  //Individual machine field value change
  const handleMachineDataChange = (e) => {
    var value = e.target.value;
    var name = e.target.name;
    setMachineData({
      ...machineData,
      [name]: value,
    });
  };

  //Individual estimation details field value change
  const handleEstimationDataChange = (e) => {
    var value = e.target.value;
    var name = e.target.name;
    setEstimationData({
      ...estimationData,
      [name]: value,
    });
  };

  // update the customer data
  const updateCustomerData = () => {
    let data = {
      builderId,
      source: customerData.source,
      customerId: customerData.customerID,
      customerName: customerData.customerName,
      contactName: customerData.contactName,
      contactEmail: customerData.contactEmail,
      customerGroup: customerData.customerGroup,
      contactPhone: customerData.contactPhone,
      customerSegment: customerData.customerSegment,
      regionOrState: customerData.regionOrState,
      country: customerData.country,
    };
    console.log(data);
    const validator = new Validator();
    if (!validator.emailValidation(customerData.contactEmail)) {
      handleSnack("error", "Please enter the email address in correct format");
    } else {
      updateBuilderCustomer(bId, data)
        .then((result) => {
          setSavedBuilderDetails(result);
          setViewOnlyTab({ ...viewOnlyTab, custViewOnly: true });
          setValue("machine");
          handleSnack("success", "Customer details updated!");
        })
        .catch((err) => {
          handleSnack("error", "Error occurred while updating the customer data!");
        });
    }
  };

  // header tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // update the machine data
  const updateMachineData = () => {
    let data = {
      builderId,
      make: machineData.make,
      family: machineData.family,
      model: machineData.model,
      fleetNo: machineData.fleetNo,
      smu: machineData.smu,
      registrationNo: machineData.registrationNo,
      chasisNo: machineData.chasisNo,
      serialNo: machineData.serialNo,
      productGroup: machineData.productGroup,
      productSegment: machineData.productSegment,
    };
    updateBuilderMachine(bId, data)
      .then((result) => {
        setSavedBuilderDetails(result);
        setValue("estimation");
        setViewOnlyTab({ ...viewOnlyTab, machineViewOnly: true });
        handleSnack("success", "Machine details updated!");
      })
      .catch((err) => {
        handleSnack("error", "Error occurred while updating the machine data!");
      });
  };

  // update general data
  const updateGeneralData = () => {
    let data = {
      builderId,
      estimationDate: generalData.estimationDate,
      description: generalData.description,
      reference: generalData.reference,
      validityDays: generalData.validity?.value,
      estimationNumber: generalData.estimationNo,
    };
    updateBuilderGeneralDet(bId, data)
      .then((result) => {
        setSavedBuilderDetails(result);
        setValue("price");
        setViewOnlyTab({ ...viewOnlyTab, generalViewOnly: true });
        handleSnack("success", "General details updated!");
      })
      .catch((err) => {
        handleSnack("error", "Error occurred while updating the general details!");
      });
  };

  // update estimaion data
  const updateEstData = () => {
    let data = {
      builderId,
      preparedBy: estimationData.preparedBy,
      preparedOn: estimationData.preparedOn,
      revisedBy: estimationData.revisedBy,
      revisedOn: estimationData.revisedOn,
      approver: estimationData.approvedBy,
      salesOffice: estimationData.salesOffice?.value,
    };
    updateBuilderEstimation(bId, data)
      .then((result) => {
        setSavedBuilderDetails(result);
        setValue("general");
        setViewOnlyTab({ ...viewOnlyTab, estViewOnly: true });
        handleSnack("success", "Estimation details updated!");
      })
      .catch((err) => {
        handleSnack("error", "Error occurred while updating the estimation details!");
      });
  };

  // update price data
  const updatePriceData = () => {
    let data = {
      builderId,
      priceDetailDTO: pricingData.priceDetailDTO,
      priceEstimateDTO: pricingData.priceEstimateDTO,
      currency: pricingData.currency?.value,
      priceDate: pricingData.priceDate,
    };
    updateBuilderPrice(bId, data)
      .then((result) => {
        setSavedBuilderDetails(result);
        if (result) {
          setPricingData({
            ...pricingData,
            adjustedPrice: result.adjustedPrice,
            priceDetailDTO: result.priceDetailDTO,
            priceEstimateDTO: result.priceEstimateDTO,
            netPrice: result.netPrice,
          });
        }
        setViewOnlyTab({ ...viewOnlyTab, priceViewOnly: true });
        handleSnack("success", "Pricing details updated!");
      })
      .catch((err) => {
        setPricingData({
          ...pricingData,
          adjustedPrice: savedHeaderDetails.adjustedPrice,
          priceDetailDTO: savedHeaderDetails.priceDetailDTO,
          priceEstimateDTO: savedHeaderDetails.priceEstimateDTO,
          netPrice: savedHeaderDetails.netPrice,
        });
        handleSnack("error", "Error occurred while updating the pricing details!");
      });
  };

  //Logic to handle status changes
  const disableStatusOptions = (option) => {
    const selectedValue = selBuilderStatus.value;
    const changeToValue = option.value;
    return !(
      (["DRAFT", "REVISED"].indexOf(selectedValue) > -1 && changeToValue === "ACTIVE") ||
      (["ARCHIVED", "REVISED"].indexOf(changeToValue) > -1 && selectedValue === "ACTIVE")
    );
  };

  // click and open menu options
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (selBuilderStatus?.value !== "ACTIVE") handleSnack("info", "Set active status to do “convert to”");
    else setOpen(true);
  };

  // close menu options
  const handleClose = () => {
    setOpen(false);
  };

  const recentList = () => {
    history.push({
      pathname: REPAIR_WITHOUT_SPARE_PARTS_NEW,
    });
  };

  // make the header editable
  const makeHeaderEditable = () => {
    if (value === "customer" && viewOnlyTab.custViewOnly) setViewOnlyTab({ ...viewOnlyTab, custViewOnly: false });
    else if (value === "machine" && viewOnlyTab.machineViewOnly) setViewOnlyTab({ ...viewOnlyTab, machineViewOnly: false });
    else if (value === "estimation" && viewOnlyTab.estViewOnly) setViewOnlyTab({ ...viewOnlyTab, estViewOnly: false });
    else if (value === "general" && viewOnlyTab.generalViewOnly) setViewOnlyTab({ ...viewOnlyTab, generalViewOnly: false });
    else if (value === "price" && viewOnlyTab.priceViewOnly) setViewOnlyTab({ ...viewOnlyTab, priceViewOnly: false });
  };

  // reset the data
  const handleResetData = (action) => {
    if (action === "RESET") {
      value === "customer" && populateCustomerData(savedHeaderDetails);
      value === "machine" && populateMachineData(savedHeaderDetails);
      value === "general" && populateGeneralData(savedHeaderDetails);
      value === "estimation" && populateEstData(savedHeaderDetails);
      value === "price" && populatePricingData(savedHeaderDetails);
    } else if (action === "CANCEL") {
      populateHeader(savedHeaderDetails);
    }
  };

  console.log("segmentItems ::: ", segmentItems);

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />

      <ModalCreateVersion
        versionOpen={versionOpen}
        handleCloseVersion={() => setVersionOpen(false)}
        handleCreateVersion={createVersion}
        message="Another version of this builder will be created."
        description={versionDescription}
        setDescription={setVersionDescription}
      />
      <div className="content-body">
        <div className="container-fluid ">
          <SparePartsStepper />
          <Box sx={{ width: "100%", marginY: 3 }}>
            <Stepper activeStep={activeStep}>
              {cartOrderSteps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Divider sx={{ marginY: 2 }} />
          <div className="card border px-3 py-2">
            {activeStep === 0 && (
              <>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <div className="d-flex justify-content-center align-items-center">
                    <h5 className="font-weight-600 mb-0">Repair option (without spare parts)</h5>
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="ml-3">
                        <Select
                          className="customselectbtn"
                          onChange={(e) => handleVersion(e)}
                          options={builderVersionOptions}
                          value={selectedVersion}
                        />
                      </div>

                      <div className="ml-3">
                        <Select
                          className="customselectbtn"
                          onChange={(e) => handleBuilderStatus(e)}
                          isOptionDisabled={(e) => disableStatusOptions(e)}
                          options={STATUS_OPTIONS}
                          value={selBuilderStatus}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div>
                      <>
                        <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                          <IconButton
                            className="btn bg-primary text-white font-size-14 pr-0 ml-2"
                            style={{ borderRadius: "5px" }}
                            onClick={handleClick}
                            size="small"
                            aria-controls={open ? "account-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                          >
                            <span className="convert mx-2">
                              Convert to
                              <span>
                                <KeyboardArrowDownIcon />
                              </span>
                            </span>
                          </IconButton>
                        </Box>
                        <Menu
                          anchorEl={anchorEl}
                          id="account-menu"
                          open={open}
                          onClose={handleClose}
                          onClick={handleClose}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              overflow: "visible",
                              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                              mt: 1.5,
                              "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                              "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                              },
                            },
                          }}
                          transformOrigin={{ horizontal: "right", vertical: "top" }}
                          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                        >
                          <MenuItem className="custommenu ml-2 mr-4" onClick={() => setTemplateOpen(true)}>
                            Standard Job
                          </MenuItem>
                          <Divider />
                          <MenuItem className="custommenu ml-2 mr-4" onClick={() => setOpenQuotePopup(true)}>
                            Quote
                          </MenuItem>
                        </Menu>
                      </>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <a href="#" className="ml-3 font-size-14" title="Share">
                        <img src={shareIcon}></img>
                      </a>
                      <a href="#" className="ml-3 font-size-14" title="Items to Review">
                        <img src={folderaddIcon}></img>
                      </a>
                      <a href="#" className="ml-3 font-size-14" title="Upload">
                        <img src={uploadIcon}></img>
                      </a>
                      {/* <a href="#" className="ml-3 font-size-14"><img src={cpqIcon}></img></a> */}
                      <a href="#" className="ml-3 font-size-14" title="Delete">
                        <img src={deleteIcon}></img>
                      </a>
                      <a href="#" className="ml-3 font-size-14" title="Copy">
                        <img src={copyIcon}></img>
                      </a>
                      <DropdownButton className="customDropdown ml-2" id="dropdown-item-button">
                        <Dropdown.Item as="button" onClick={() => setVersionOpen(true)}>
                          New Versions
                        </Dropdown.Item>
                        <Dropdown.Item as="button">Show Errors</Dropdown.Item>
                        <Dropdown.Item as="button">Review</Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                </div>
                <>
                  <div className="card p-4 mt-5">
                    <div className="row px-3 pb-1" style={{ justifyContent: "right" }}>
                      <button type="button" className="btn btn-light bg-primary text-white" onClick={() => recentList()}>
                        Back
                      </button>
                    </div>
                    <h5 className="d-flex align-items-center mb-0 bg-primary p-2 border-radius-10">
                      <div className="" style={{ display: "contents" }}>
                        <span className="mr-3 ml-2 text-white" style={{ fontSize: 18 }}>
                          Without Spare Parts Header
                        </span>
                        <div className="btn-sm cursor text-white">
                          <Tooltip title="Edit">
                            <EditIcon
                              onClick={() =>
                                ["DRAFT", "REVISED"].indexOf(selBuilderStatus?.value) > -1
                                  ? makeHeaderEditable()
                                  : handleSnack("info", "Set revised status to modify active builders")
                              }
                            />
                          </Tooltip>
                        </div>
                        <div className="btn-sm cursor text-white">
                          <Tooltip title="Reset">
                            <ReplayIcon onClick={() => handleResetData("RESET")} />
                          </Tooltip>
                        </div>
                        <div className="btn-sm cursor text-white">
                          <Tooltip title="Share">
                            <ShareOutlinedIcon />
                          </Tooltip>
                        </div>

                        <div className="btn-sm cursor text-white">
                          <Tooltip title="Add to Review">
                            <ReviewAddIcon />
                          </Tooltip>
                        </div>
                      </div>
                    </h5>
                    <Box className="mt-4" sx={{ width: "100%", typography: "body1" }}>
                      {headerLoading ? (
                        <LoadingProgress />
                      ) : (
                        <TabContext value={value}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList className="custom-tabs-div" onChange={handleChange}>
                              <Tab label="Customer" value="customer" />
                              <Tab label="Machine " value="machine" />
                              <Tab label="Estimation Details" value="estimation" />
                              <Tab label="General Details" value="general" />
                              <Tab label="Price" value="price" />
                            </TabList>
                          </Box>
                          <TabPanel value="customer">
                            {!viewOnlyTab.custViewOnly ? (
                              <>
                                <div className="row input-fields">
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">SOURCE</label>
                                      <input
                                        type="text"
                                        disabled
                                        className="form-control border-radius-10 text-primary"
                                        id="customer-src"
                                        value={customerData.source}
                                      />
                                      <div className="css-w8dmq8">*Mandatory</div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER ID</label>
                                      <SearchBox
                                        value={customerData.customerID}
                                        onChange={(e) => handleCustSearch("customerId", e.target.value)}
                                        type="customerId"
                                        result={searchCustResults}
                                        onSelect={handleCustSelect}
                                        noOptions={noOptionsCust}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER NAME</label>
                                      <input
                                        type="text"
                                        value={customerData.customerName}
                                        name="customerName"
                                        onChange={handleCustomerDataChange}
                                        className="form-control border-radius-10 text-primary"
                                        id="customerNameid"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group w-100">
                                      <label className="text-light-dark font-size-12 font-weight-500">CONTACT NAME</label>
                                      <input
                                        type="text"
                                        value={customerData.contactName}
                                        name="contactName"
                                        onChange={handleCustomerDataChange}
                                        className="form-control border-radius-10 text-primary"
                                        id="contactNameid"
                                      />
                                      <div className="css-w8dmq8">*Mandatory</div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">CONTACT EMAIL</label>
                                      <input
                                        type="email"
                                        value={customerData.contactEmail}
                                        name="contactEmail"
                                        onChange={handleCustomerDataChange}
                                        className="form-control border-radius-10 text-primary"
                                        id="contatEmail"
                                        aria-describedby="emailHelp"
                                      />
                                      <div className="css-w8dmq8">*Mandatory</div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">CONTACT PHONE</label>
                                      <input
                                        type="tel"
                                        className="form-control border-radius-10 text-primary"
                                        onChange={handleCustomerDataChange}
                                        value={customerData.contactPhone}
                                        name="contactPhone"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER GROUP</label>
                                      <input
                                        type="text"
                                        value={customerData.customerGroup}
                                        name="customerGroup"
                                        onChange={handleCustomerDataChange}
                                        className="form-control border-radius-10 text-primary"
                                        id="custGroup"
                                      />
                                      <div className="css-w8dmq8">*Mandatory</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row" style={{ justifyContent: "right" }}>
                                  <button
                                    type="button"
                                    className="btn btn-light bg-primary text-white mr-1"
                                    onClick={() => handleResetData("CANCEL")}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-light bg-primary text-white"
                                    disabled={
                                      !(customerData.source && customerData.contactEmail && customerData.customerGroup && customerData.contactName) ||
                                      noOptionsCust
                                    }
                                    onClick={updateCustomerData}
                                  >
                                    Save & Next
                                  </button>
                                </div>
                              </>
                            ) : (
                              <div className="row mt-3">
                                <ReadOnlyField label="SOURCE" value={customerData.source} className="col-md-4 col-sm-4" />
                                <ReadOnlyField label="CUSTOMER ID" value={customerData.customerID} className="col-md-4 col-sm-4" />
                                <ReadOnlyField label="CUSTOMER NAME" value={customerData.customerName} className="col-md-4 col-sm-4" />
                                <ReadOnlyField label="CUSTOMER EMAIL" value={customerData.contactEmail} className="col-md-4 col-sm-4" />
                                <ReadOnlyField label="CONTACT NAME" value={customerData.contactName} className="col-md-4 col-sm-4" />
                                <ReadOnlyField label="CONTACT PHONE" value={customerData.contactPhone} className="col-md-4 col-sm-4" />
                                <ReadOnlyField label="CUSTOMER GROUP" value={customerData.customerGroup} className="col-md-4 col-sm-4" />
                              </div>
                            )}
                          </TabPanel>
                          <TabPanel value="machine">
                            {!viewOnlyTab.machineViewOnly ? (
                              <>
                                <div className="row input-fields">
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">Make</label>
                                      <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        id="make-id"
                                        name="make"
                                        value={machineData.make}
                                        onChange={handleMachineDataChange}
                                        placeholder="Auto Filled"
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">Family</label>
                                      <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        id="family-id"
                                        name="family"
                                        value={machineData.family}
                                        onChange={handleMachineDataChange}
                                        placeholder="Auto Filled"
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">MODEL</label>
                                      <SearchBox
                                        value={machineData.model}
                                        onChange={(e) => handleMachineSearch("model", e.target.value)}
                                        type="model"
                                        result={searchModelResults}
                                        onSelect={handleModelSelect}
                                        noOptions={noOptionsModel}
                                      />
                                      <div className="css-w8dmq8">*Mandatory</div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">SERIAL #</label>
                                      <SearchBox
                                        value={machineData.serialNo}
                                        onChange={(e) => handleMachineSearch("serialNo", e.target.value)}
                                        type="equipmentNumber"
                                        result={searchSerialResults}
                                        onSelect={handleModelSelect}
                                        noOptions={noOptionsSerial}
                                      />
                                      <div className="css-w8dmq8">*Mandatory</div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">SMU (Service Meter Unit)</label>
                                      <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        id="smu-id"
                                        name="smu"
                                        value={machineData.smu}
                                        onChange={handleMachineDataChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">UNIT NO / FLEET NO</label>
                                      <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        onChange={handleMachineDataChange}
                                        value={machineData.fleetNo}
                                        name="fleetNo"
                                        id="fleet-id"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">REGISTRATION NO</label>
                                      <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        onChange={handleMachineDataChange}
                                        value={machineData.registrationNo}
                                        name="registrationNo"
                                        id="registration-id"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">CHASIS NO</label>
                                      <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        id="chasis-id"
                                        onChange={handleMachineDataChange}
                                        value={machineData.chasisNo}
                                        name="chasisNo"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row" style={{ justifyContent: "right" }}>
                                  <button
                                    type="button"
                                    className="btn btn-light bg-primary text-white mr-1"
                                    onClick={() => handleResetData("CANCEL")}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-light bg-primary text-white"
                                    disabled={!(machineData.model && machineData.serialNo) || noOptionsModel || noOptionsSerial}
                                    onClick={updateMachineData}
                                  >
                                    Save & Next
                                  </button>
                                </div>
                              </>
                            ) : (
                              <div className="row mt-3">
                                <ReadOnlyField label="MAKE" value={machineData.make} className="col-md-4 col-sm-4" />
                                <ReadOnlyField label="FAMILY" value={machineData.family} className="col-md-4 col-sm-4" />
                                <ReadOnlyField label="MODEL" value={machineData.model} className="col-md-4 col-sm-4" />
                                <ReadOnlyField label="SERIAL NO" value={machineData.serialNo} className="col-md-4 col-sm-4" />
                                <ReadOnlyField label="SMU (Service Meter Unit)" value={machineData.smu} className="col-md-4 col-sm-4" />
                                <ReadOnlyField label="UNIT NO / FLEET NO" value={machineData.fleetNo} className="col-md-4 col-sm-4" />
                                <ReadOnlyField label="REGISTRATION NO" value={machineData.registrationNo} className="col-md-4 col-sm-4" />
                                <ReadOnlyField label="CHASSIS NO" value={machineData.chasisNo} className="col-md-4 col-sm-4" />
                              </div>
                            )}
                          </TabPanel>
                          <TabPanel value="estimation">
                            {!viewOnlyTab.estViewOnly ? (
                              <>
                                <div className="row input-fields">
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">PREPARED BY</label>
                                      <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        value={estimationData.preparedBy}
                                        name="preparedBy"
                                        onChange={handleEstimationDataChange}
                                      />
                                      <div className="css-w8dmq8">*Mandatory</div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">APPROVED BY</label>
                                      <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        value={estimationData.approvedBy}
                                        name="approvedBy"
                                        onChange={handleEstimationDataChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">PREPARED ON</label>
                                      <div className="align-items-center date-box">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                          <MobileDatePicker
                                            inputFormat="dd/MM/yyyy"
                                            className="form-controldate border-radius-10"
                                            minDate={estimationData.preparedOn}
                                            maxDate={new Date()}
                                            closeOnSelect
                                            value={estimationData.preparedOn}
                                            onChange={(e) => setEstimationData({ ...estimationData, preparedOn: e })}
                                            renderInput={(params) => (
                                              <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                                            )}
                                          />
                                        </LocalizationProvider>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">REVISED BY</label>
                                      <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        value={estimationData.revisedBy}
                                        name="revisedBy"
                                        onChange={handleEstimationDataChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">REVISED ON</label>
                                      <div className="align-items-center date-box">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                          <MobileDatePicker
                                            inputFormat="dd/MM/yyyy"
                                            className="form-controldate border-radius-10"
                                            minDate={estimationData.revisedOn}
                                            maxDate={new Date()}
                                            closeOnSelect
                                            value={estimationData.revisedOn}
                                            onChange={(e) => setEstimationData({ ...estimationData, revisedOn: e })}
                                            renderInput={(params) => (
                                              <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                                            )}
                                          />
                                        </LocalizationProvider>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">SALES OFFICE / BRANCH</label>
                                      <Select
                                        onChange={(e) => setEstimationData({ ...estimationData, salesOffice: e })}
                                        options={salesOfficeOptions}
                                        value={estimationData.salesOffice}
                                        styles={FONT_STYLE_SELECT}
                                      />
                                      <div className="css-w8dmq8">*Mandatory</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row" style={{ justifyContent: "right" }}>
                                  <button
                                    type="button"
                                    className="btn btn-light bg-primary text-white mr-1"
                                    onClick={() => handleResetData("CANCEL")}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-light bg-primary text-white"
                                    onClick={updateEstData}
                                    disabled={!estimationData.preparedBy || !estimationData.preparedOn || !estimationData.salesOffice}
                                  >
                                    Save & Next
                                  </button>
                                </div>
                              </>
                            ) : (
                              <div className="row mt-3">
                                <ReadOnlyField label="PREPARED BY" value={estimationData.preparedBy} className="col-md-4 col-sm-4" />
                                <ReadOnlyField label="APPROVED BY" value={estimationData.approvedBy} className="col-md-4 col-sm-4" />

                                <ReadOnlyField
                                  label="PREPARED ON"
                                  value={estimationData.preparedOn ? <Moment format="DD/MM/YYYY">{estimationData.preparedOn}</Moment> : "NA"}
                                  className="col-md-4 col-sm-4"
                                />
                                <ReadOnlyField label="REVISED BY" value={estimationData.revisedBy} className="col-md-4 col-sm-4" />
                                <ReadOnlyField
                                  label="REVISED ON"
                                  value={estimationData.revisedOn ? <Moment format="DD/MM/YYYY">{estimationData.revisedOn}</Moment> : "NA"}
                                  className="col-md-4 col-sm-4"
                                />
                                <ReadOnlyField
                                  label="SALES OFFICE / BRANCH"
                                  value={estimationData.salesOffice?.label}
                                  className="col-md-4 col-sm-4"
                                />
                              </div>
                            )}
                          </TabPanel>
                          <TabPanel value="general">
                            {!viewOnlyTab.generalViewOnly ? (
                              <>
                                <div className="row input-fields">
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">
                                        <span className=" mr-2">ESTIMATION DATE</span>
                                      </label>
                                      <div className="align-items-center date-box">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                          <MobileDatePicker
                                            inputFormat="dd/MM/yyyy"
                                            className="form-controldate border-radius-10"
                                            minDate={generalData.estimationDate}
                                            maxDate={new Date()}
                                            closeOnSelect
                                            value={generalData.estimationDate}
                                            onChange={(e) => setGeneralData({ ...generalData, estimationDate: e })}
                                            renderInput={(params) => (
                                              <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                                            )}
                                          />
                                        </LocalizationProvider>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">ESTIMATION #</label>
                                      <input
                                        type="text"
                                        disabled
                                        className="form-control border-radius-10 text-primary"
                                        id="estNoId"
                                        value={generalData.estimationNo}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">DESCRIPTION</label>
                                      <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        id="desc-id"
                                        maxLength={140}
                                        value={generalData.description}
                                        onChange={(e) => setGeneralData({ ...generalData, description: e.target.value })}
                                      />
                                      <div className="css-w8dmq8">*Mandatory</div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">REFERENCE</label>
                                      <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        id="desc-id"
                                        maxLength={140}
                                        value={generalData.reference}
                                        onChange={(e) => setGeneralData({ ...generalData, reference: e.target.value })}
                                      />
                                      <div className="css-w8dmq8">*Mandatory</div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">VALIDITY</label>
                                      <Select
                                        onChange={(e) => setGeneralData({ ...generalData, validity: e })}
                                        options={validityOptions}
                                        value={generalData.validity}
                                        styles={FONT_STYLE_SELECT}
                                      />
                                      <div className="css-w8dmq8">*Mandatory</div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">VERSION</label>
                                      <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        disabled
                                        value={parseFloat(selectedVersion.value).toFixed(1)}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row" style={{ justifyContent: "right" }}>
                                  <button
                                    type="button"
                                    className="btn btn-light bg-primary text-white mr-1"
                                    onClick={() => handleResetData("CANCEL")}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-light bg-primary text-white"
                                    onClick={updateGeneralData}
                                    disabled={
                                      !generalData.estimationDate ||
                                      !generalData.description ||
                                      !generalData.estimationNo ||
                                      !generalData.reference ||
                                      !generalData.validity
                                    }
                                  >
                                    Save & Next
                                  </button>
                                </div>
                              </>
                            ) : (
                              <div className="row mt-3">
                                <ReadOnlyField
                                  label="ESTIMATION DATE"
                                  value={generalData.estimationDate ? <Moment format="DD/MM/YYYY">{generalData.estimationDate}</Moment> : "NA"}
                                  className="col-md-4 col-sm-4"
                                />
                                <ReadOnlyField label="ESTIMATION #" value={generalData.estimationNo} className="col-md-4 col-sm-4" />
                                <ReadOnlyField label="DESCRIPTION" value={generalData.description} className="col-md-4 col-sm-4" />
                                <ReadOnlyField label="REFERENCE" value={generalData.reference} className="col-md-4 col-sm-4" />
                                <ReadOnlyField label="VALIDTITY (DAYs)" value={generalData.validity?.label} className="col-md-4 col-sm-4" />
                                <ReadOnlyField label="VERSION" value={parseFloat(selectedVersion.value).toFixed(1)} className="col-md-4 col-sm-4" />
                              </div>
                            )}
                          </TabPanel>
                          <TabPanel value="price">
                            {!viewOnlyTab.priceViewOnly ? (
                              <>
                                <div className="row input-fields">
                                  <div className="col-md-4 col-sm-4">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">NET PRICE</label>
                                      <input
                                        type="text"
                                        disabled
                                        className="form-control border-radius-10 text-primary"
                                        value={pricingData.netPrice}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-4">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">PRICE DATE</label>
                                      <div className="align-items-center date-box">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                          <MobileDatePicker
                                            inputFormat="dd/MM/yyyy"
                                            className="form-controldate border-radius-10"
                                            minDate={pricingData.priceDate}
                                            maxDate={new Date()}
                                            closeOnSelect
                                            value={pricingData.priceDate}
                                            onChange={(e) => setPricingData({ ...pricingData, priceDate: e })}
                                            renderInput={(params) => (
                                              <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                                            )}
                                          />
                                        </LocalizationProvider>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-4">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">ADJUSTED PRICE</label>
                                      <input
                                        type="text"
                                        disabled={!(pricingData.priceMethod?.value === "FLAT_RATE")}
                                        className="form-control border-radius-10 text-primary"
                                        value={pricingData.priceMethod?.value === "FLAT_RATE" ? pricingData.adjustedPrice : 0.0}
                                        onChange={(e) => setPricingData({ ...pricingData, adjustedPrice: e.target.value })}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-4">
                                    <div className="form-group">
                                      <label className="text-light-dark font-size-12 font-weight-500">CURRENCY</label>
                                      <Select
                                        onChange={(e) => setPricingData({ ...pricingData, currency: e })}
                                        options={currencyOptions}
                                        value={pricingData.currency}
                                        styles={FONT_STYLE_SELECT}
                                      />
                                      <div className="css-w8dmq8">*Mandatory</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row" style={{ justifyContent: "right" }}>
                                  <button
                                    type="button"
                                    className="btn btn-light bg-primary text-white mr-1"
                                    onClick={() => handleResetData("CANCEL")}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-light bg-primary text-white"
                                    onClick={updatePriceData}
                                    disabled={
                                      !(pricingData.priceDate &&
                                      pricingData.priceMethod &&
                                      pricingData.currency &&
                                      pricingData.priceMethod?.value === "FLAT_RATE"
                                        ? pricingData.adjustedPrice > 0
                                        : true)
                                    }
                                  >
                                    Save
                                  </button>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="row mt-3">
                                  <ReadOnlyField label="NET PRICE" value={pricingData.netPrice} className="col-md-4 col-sm-4" />
                                  <ReadOnlyField
                                    label="PRICE DATE"
                                    value={pricingData.priceDate ? <Moment format="DD/MM/YYYY">{pricingData.priceDate}</Moment> : "NA"}
                                    className="col-md-4 col-sm-4"
                                  />
                                  <ReadOnlyField label="ADJUSTED PRICE" value={pricingData.adjustedPrice} className="col-md-4 col-sm-4" />
                                  <ReadOnlyField label="CURRENCY" value={pricingData.currency?.label} className="col-md-4 col-sm-4" />
                                </div>
                                <hr />
                                <div className="mb-5">
                                  <PriceMethodTable
                                    rows={pricingData.priceDetailDTO}
                                    setRows={(rows) => setPricingData({ ...pricingData, priceDetailDTO: rows })}
                                  />
                                  <div className="row my-3 mr-2" style={{ justifyContent: "right" }}>
                                    <button
                                      type="button"
                                      className="btn btn-light bg-primary text-white"
                                      onClick={updatePriceData}
                                      disabled={!(pricingData.priceDate && pricingData.currency)}
                                    >
                                      Save Price Methods
                                    </button>
                                  </div>
                                  <PriceSummaryTable
                                    rows={pricingData.priceEstimateDTO}
                                    setRows={(rows) => setPricingData({ ...pricingData, priceEstimateDTO: rows })}
                                  />
                                  <div className="row my-3 mr-2" style={{ justifyContent: "right" }}>
                                    <button
                                      type="button"
                                      className="btn btn-light bg-primary text-white"
                                      onClick={updatePriceData}
                                      disabled={!(pricingData.priceDate && pricingData.currency)}
                                    >
                                      Save Price Summary
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                          </TabPanel>
                        </TabContext>
                      )}

                      {/* <ServiceSegmentMaster /> */}
                      {/* <SegmentsMaster segments={segments} /> */}
                    </Box>
                  </div>
                  {/* <Divider sx={{ marginBottom: 3 }} /> */}
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4>Estimate Details</h4>
                    <button className="btn border-primary text-primary" onClick={() => setActiveStep((prev) => prev + 1)}>
                      Send for Approval
                    </button>
                  </div>
                  <div className="card border p-3">
                    <SegmentAccordion
                      segments={segments}
                      segmentItems={segmentItems}
                      setSegmentItems={setSegmentItems}
                      handleSnack={handleSnack}
                      activeElement={activeElement}
                      bId={bId}
                    />
                  </div>
                </>
              </>
            )}

            {activeStep === 1 && <ServiceEstimationApproval setActiveStep={setActiveStep} />}
            {activeStep === 2 && <ServiceEstimationOrder setActiveStep={setActiveStep} />}
            {activeStep === 3 && <ServiceEstimateInvoice setActiveStep={setActiveStep} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default WithoutSparePartsDetails;
